import {
  deleteSigmasV2,
  getPipelineConfig,
  getSigmaV2,
  postListSigmas,
  postSigmasFillupData,
  putPipelineConfig,
  putSigmasV2,
} from "api/backend/fileSystemEndpoints";
import { postSigmaRerunExtractionJobs } from "api/backend/sigmaEndpoints";
import AddSigmaRecordModalNew from "components/AddSigmaRecordModalNew";
import ExcelArea from "components/ExcelArea";
import FieldReferenceModal from "components/FieldReferenceModal";
import GenerateFieldValueModal from "components/GenerateFieldValueModal";
import GridDraggable from "components/GridDraggable";
import {
  BinIcon,
  EyeWithDocIcon,
  MoveIcon,
  PencilIconDetailed,
  ResetIcon,
} from "components/IconsNew";
import JobsTooltipTrigger from "components/JobsTooltipTrigger";
import LayoutApp from "components/LayoutApp";
import LayoutAppConfigurable from "components/LayoutAppConfigurable";
import RecordCodeModal from "components/RecordCodeModal";
import RecordFieldInput from "components/RecordFieldInput";
import RecordFieldInputTable from "components/RecordFieldInputTable";
import RecordFilesModal from "components/RecordFilesModal";
import ReferenceDebugModal from "components/ReferenceDebugModal";
import SlideDocArea from "components/SlideDocArea";
import ButtonWord from "components/ui/ButtonWord";
import { CrossIcon, TickIcon } from "components/ui/Icons";
import Tooltip from "components/ui/Tooltip";
import WordDocAreaNew from "components/WordDocAreaNew";
import useFlowUrl from "hooks/useFlowUrl";
import { cloneDeep, set, uniq } from "lodash";
import { doPostEmptyRecord } from "pages/apps/records";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getColorFromIndex, isTruthy, sleep } from "utils/common";

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  grid-template-columns: 270px 1fr;
  height: 100%;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const SideNav = styled.div`
  z-index: 1;
  overflow: auto;
  display: grid;
  grid-template-rows: 54px 1fr auto;
  position: relative;
`;

const GroupCard = styled.div`
  background-color: #ffffff;
  padding: 0px;
  border-radius: 10px;
  height: 100%;
  align-content: start;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  border: 1px solid #f1f1f1;
`;

const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  background-color: ${props => props.color || "#888888"}DE;
  padding: 0px 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  white-space: nowrap;
  grid-column: span 2;
  color: white;
  height: 42px;
  display: grid;
  align-items: center;
`;

const GroupFields = styled.div`
  padding: 10px;
  align-content: start;
  align-items: start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  grid-column: span 2;
`;

const StyledFieldReferenceModal = styled(FieldReferenceModal)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const StyledResetIcon = styled(ResetIcon)`
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
  fill: #929292;
`;

const StyledGenerateFieldValueModal = styled(GenerateFieldValueModal)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const StyledRecordFilesModal = styled(RecordFilesModal)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const NameFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  :hover {
    ${StyledGenerateFieldValueModal} {
      opacity: 1;
    }
    ${StyledFieldReferenceModal} {
      opacity: 1;
    }
    ${StyledRecordFilesModal} {
      opacity: 1;
    }
    ${StyledResetIcon} {
      opacity: 1;
    }
  }
`;

const Field = styled.div`
  display: grid;
  width: 100%;
  gap: 5px;
  :hover {
    ${StyledFieldReferenceModal} {
      opacity: 1;
    }
    ${StyledRecordFilesModal} {
      opacity: 1;
    }
    ${StyledGenerateFieldValueModal} {
      opacity: 1;
    }
    ${StyledResetIcon} {
      opacity: 1;
    }
  }
`;

const FieldLabel = styled.div`
  font-weight: 600;
  color: ${props => props.color || "#000000"};
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    height: 10px;
  }
`;

const SideNavItem = styled.div`
  padding: 12px;
  margin: 5px 10px;
  border-radius: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  cursor: pointer;
  ${props => props.isSelected && "background-color: #E8ECEFBF;"}
  :hover {
    background-color: #e8ecefbf;
  }
`;

const SimpleButton = styled.button`
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #b9b9b9;
  background-color: transparent;
  outline: none;
  font-weight: 500;

  color: #b9b9b9;
  svg {
    height: 14px;
    fill: #b9b9b9;
  }

  :hover {
    background-color: #b9b9b9;
    color: white;
    svg {
      fill: white;
    }
  }

  ${props =>
    props.isPrimary &&
    `
    background-color: #0191FF;
    color: white;
    border: 1px solid #0191FF;
    :hover {
      background-color: #0191FF;
      color: white;
    }
    `}
`;

const TopBar = styled.div`
  position: relative;
  height: 54px;
  width: 100%;
  z-index: 10;
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto;
  align-items: center;
  padding: 0 20px;
  gap: 20px;
  box-shadow: 0px 12px 40px -12px #0000000f;

  border-left: 1px solid #e8ecef;
  border-bottom: 1px solid #e8ecef;
`;

const ZoomBtn = styled(ButtonWord)`
  padding: 4px;
  line-height: 8px;
  svg {
    height: 8px;
    fill: white;
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 10px;
  border-left: 1px solid #e8ecef;
`;

const Tab = styled.div`
  padding: 4px 16px;
  border-bottom: 2px solid ${props => (props.isSelected ? "#0191ff" : "white")};
  cursor: pointer;
`;

const GridContainer = styled.div`
  background: linear-gradient(180deg, #f3f5f7 0%, #fdfdfd 100%);
  box-shadow: 0px 24px 60px 0px #0000001a;
  border-left: 1px solid #e8ecef;
  overflow: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

const AppNameText = styled.div`
  font-weight: 600;
  color: black;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
`;

const FilterContainer = styled.div`
  padding: 10px;
  background-color: white;
  position: sticky;
  top: 0;

  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4px;
`;

const FilterInput = styled.input`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;

  :disabled {
    opacity: 0.6;
  }
`;

const ToggleDiv = styled.div`
  border: 1px solid #424242;
  color: #424242;
  padding: 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: white;
  svg {
    fill: #424242;
  }
  :hover {
    color: ${props => props.theme.color.primary};
    border-color: ${props => props.theme.color.primary};
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }

  ${props =>
    props.isSelected &&
    `
    background-color: #424242;
    color: white;
    svg {
      fill: white;
    }
    `}
`;

const HorizontalLine = styled.div`
  border-bottom: 1px solid #dddddd;
  height: 0px;
  margin-bottom: 0px;
  width: 100%;
  grid-column: span 2;
`;

const HasMoreBtn = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 8px 0;
  padding-right: 0px;
  gap: 8px;
  align-items: start;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;
  cursor: pointer;
  opacity: 0.6;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #8d8d8d;
  font-weight: 500;
  text-decoration: none;
  margin-left: 20px;
  margin-bottom: 10px;

  :hover {
    opacity: 1;
  }
  svg {
    color: #0191ff;
  }
`;

const Slideout = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: ${props => (props?.isOpen ? "10px" : 0)};
  height: calc(100vh - 40px - 54px);
  width: ${props => (props.isOpen ? "300px" : "0px")};
  transition: width 0.2s;
  box-shadow: 0px 4px 12.6px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-bottom-right-radius: 24px;
  z-index: 10;
`;

const FixedButton = styled.div`
  position: absolute;
  transform: translateX(-100%);
  background-color: white;
  top: 20px;
  height: 40px;
  width: 40px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
  border: 1px solid #e0e0e0;
  border-right: none;
  z-index: 1;
`;

const FixedButtonLower = styled.div`
  position: absolute;
  transform: translate(-100%, 100%);
  background-color: white;
  top: 20px;
  height: 40px;
  width: 40px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  cursor: pointer;
  :hover {
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
  border: 1px solid #e0e0e0;
  border-right: none;
`;

const INITIAL_COLS = 2;
export const INITIAL_W = 24;
export const INITIAL_H = 14;

const getKeyHeight = (groupName, columns) => {
  const groupColumns = columns.filter(col => col?.name?.startsWith(groupName));
  const nonTableCount = groupColumns?.filter(
    col => col?.type !== "TABLE"
  )?.length;
  const tableCount = groupColumns?.filter(col => col?.type === "TABLE")?.length;

  const h = (nonTableCount * 3) / 2 + tableCount * 9;
  return Math.ceil(h) + 3;
};

export const getInitialLayoutFromColumns = ({ keys = [], columns = [] }) => {
  let layout = {};

  let x = 1;
  let y = 1;
  let w = INITIAL_W;
  let h = INITIAL_H;

  keys?.filter(isTruthy).forEach(key => {
    layout[key] = { x, y, w, h: getKeyHeight(key, columns) };
    x += w + 1;
    if (x >= INITIAL_COLS * w) {
      x = 1;
      y += h + 1;
    }
  });

  return layout;
};

const doesKeyOccupySpace = (keyPosition, x, y, w, h) => {
  const { x: keyX, y: keyY, w: keyW, h: keyH } = keyPosition;
  return keyX < x + w && keyX + keyW > x && keyY < y + h && keyY + keyH > y;
};

const isSpaceTaken = (layout, x, y, w, h) => {
  const keys = Object.keys(layout);
  return keys?.some(key => doesKeyOccupySpace(layout[key], x, y, w, h));
};

export const getAvailableSpace = layout => {
  let x = 1;
  let y = 1;
  let w = INITIAL_W;
  let h = INITIAL_H;

  while (isSpaceTaken(layout, x, y, w, h)) {
    x += 1;
    y += 1;

    if (x >= 1000 && y >= 1000) {
      return { x: 1, y: 1, w, h };
    }
  }

  return { x, y, w, h };
};

const AppRecordPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pipelineConfigId, selectedRecordIdStr } = useParams();
  const [pipelineConfig, setPipelineConfig] = useState(null);
  const [sigmaRecords, setSigmaRecords] = useState([]);
  const [fields, setFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingLayout, setIsEditingLayout] = useState(false);
  const [isFillingUp, setIsFillingUp] = useState(false);
  const [filterStr, setFilterStr] = useState("");
  const [isEditingValues, setIsEditingValues] = useState(false);
  const [gridSF, setGridSF] = useState(1);
  const flowUrl = useFlowUrl();
  const [pageSize, setPageSize] = useState(20);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
  const [filterStrToUse, setFilterStrToUse] = useState("");
  const [appTableName, setAppTableName] = useState("Table");

  const tableId = searchParams?.get("tableId") || "source0";
  const selectedRecordId = parseInt(selectedRecordIdStr);
  const selectedTable = pipelineConfig?.sourceTables?.find(
    table => table.id === tableId
  );

  const columns = selectedTable?.columns || [];
  const groupNames = uniq(columns?.map(column => column.displayGroup));
  const recordNameColumn = columns?.find(
    column => !column?.displayGroup || column?.isNameField
  );

  // const selectedRecord = sigmaRecords?.find(
  //   record => record.id === selectedRecordId
  // );

  const [selectedTab, setSelectedTab] = useState("fields");

  // useEffect(() => {
  //   const count = searchParams?.get("count");
  //   if (count) {
  //     setPageSize(parseInt(count));
  //   }
  // }, [searchParams?.get("count")]);

  useEffect(() => {
    setGridSF(pipelineConfig?.meta?.gridSF || 1);
  }, [JSON.stringify(pipelineConfig?.meta)]);

  useEffect(() => {
    doPopulateSelectedRecord();
  }, [selectedRecordId, pipelineConfigId, tableId]);

  // get table name using table id
  useEffect(() => {
    const newSelectedTable =
      pipelineConfig?.sourceTables?.find(element => element.id === tableId)
        ?.tableDisplayName || "Table";
    const newAppTableName = `${pipelineConfig?.name} / ${newSelectedTable}`;
    setAppTableName(newAppTableName);
  }, [pipelineConfig, tableId]);

  const scrollSelectedIntoView = async () => {
    await sleep(100);
    if (hasScrolledIntoView) {
      return;
    }
    const selectedElement = document.getElementById(selectedRecordIdStr);
    if (selectedElement) {
      selectedElement.scrollIntoView({ behavior: "instant" });
    }
    setHasScrolledIntoView(true);
  };

  const doPopulateSelectedRecord = async () => {
    const { data } = await getSigmaV2(selectedRecordId, {
      pipelineId: pipelineConfigId,
      tableName: tableId,
    });
    setSelectedRecord(data);
    scrollSelectedIntoView();
  };

  const doPutGridSF = async newGridSf => {
    const { data: configWithNewGridSF } = await putPipelineConfig(
      pipelineConfigId,
      {},
      {
        ...pipelineConfig,
        meta: {
          ...(pipelineConfig?.meta || {}),
          gridSF: newGridSf,
        },
      }
    );
    setPipelineConfig(configWithNewGridSF);
  };

  useEffect(() => {
    if (searchParams?.get("isEditMode") === "true") {
      setIsEditingValues(true);
      navigate(
        `/apps/${pipelineConfigId}/records/${selectedRecordIdStr}?tableId=${tableId}`
      );
    }
  }, [searchParams?.get("isEditMode")]);

  // FETCHING
  useEffect(() => {
    doPopulatePipelineConfig();
  }, [pipelineConfigId]);

  useEffect(() => {
    if (!pipelineConfigId || !tableId) {
      return;
    }
    doPopulateSigmaRecords(pipelineConfigId, tableId, pageSize);
    const intervalId = setInterval(() => {
      doPopulateSigmaRecords(pipelineConfigId, tableId, pageSize);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [
    pipelineConfigId,
    tableId,
    selectedRecordIdStr,
    pageSize,
    JSON.stringify(recordNameColumn),
    filterStrToUse,
  ]);

  const doFillUp = async () => {
    setIsFillingUp(true);
    await postSigmasFillupData(selectedRecordId, {
      pipelineId: pipelineConfigId,
      tableName: tableId,
      gpt_assistance: true,
    });
    setIsFillingUp(false);
  };

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
    if (!searchParams?.get("tableId")) {
      const tableId = data?.sourceTables?.[0]?.id;
      navigate(
        `/apps/${pipelineConfigId}/records/${selectedRecordIdStr}?tableId=${tableId}`
      );
    }
  };

  const doPopulateSigmaRecords = async () => {
    let filters = [];
    if (filterStrToUse) {
      filters = [
        {
          dbName: recordNameColumn?.dbName,
          likeValues: [filterStrToUse],
        },
      ];
    }

    const { data } = await postListSigmas(
      {
        pipelineId: pipelineConfigId,
        tableName: tableId,
      },
      {
        sort: {
          sortColumn: "",
          direction: "DESC",
          limit: pageSize,
        },
        filters,
      }
    );
    setSigmaRecords(data?.items || []);
    if (!selectedRecordIdStr) {
      navigate(`/apps/${pipelineConfigId}/records/${data?.[0]?.id}`);
    }
  };

  const doPutSelectedSigmaRecord = async payloadFields => {
    setIsLoading(true);
    const { data } = await putSigmasV2(
      selectedRecordId,
      {},
      {
        ...selectedRecord,
        fields: payloadFields,
      }
    );
    setIsLoading(false);
    setFields(data?.fields || {});
    doPopulateSigmaRecords(pipelineConfigId, tableId);
    setSelectedRecord(data);
  };

  const doPutPipelineConfigLayout = async newLayout => {
    const { data: configWithNewLayout } = await putPipelineConfig(
      pipelineConfigId,
      {},
      {
        ...pipelineConfig,
        meta: {
          ...pipelineConfig.meta,
          layout: newLayout,
        },
      }
    );
    setPipelineConfig(configWithNewLayout);
  };

  const doDeleteSelectedSigmaRecord = async () => {
    await deleteSigmasV2(selectedRecordId, {
      tableName: tableId,
      pipelineID: pipelineConfigId,
    });
    const nextId = sigmaRecords?.find(r => r.id !== selectedRecordId)?.id;
    await doPopulateSigmaRecords(pipelineConfigId, tableId);
    navigate(`/apps/${pipelineConfigId}/records/${nextId}?tableId=${tableId}`);
  };

  const onClickTick = async () => {
    doPutSelectedSigmaRecord(fields);
    await putPipelineConfig(pipelineConfigId, {}, pipelineConfig);
    setIsEditingValues(false);
  };

  const onClickCross = () => {
    setFields(selectedRecord?.fields || {});
    setIsEditingValues(false);
  };

  // USER UPDATES
  useEffect(() => {
    setFields(selectedRecord?.fields || {});
  }, [JSON.stringify(selectedRecord)]);

  // sort by createdAt
  const sigmaRecordsMostRecentFirst = sigmaRecords;

  const wordDocColumnNames = columns
    ?.filter(column => column?.displayGroup === "File Templates")
    ?.map(column => column?.dbName);

  const onChangeRecordInput = (newValue, fieldName) => {
    const updatedColumn = columns?.find(column => column?.dbName === fieldName);
    if (updatedColumn?.type === "NUMBER") {
      newValue = parseFloat(newValue);
    }

    const newFields = cloneDeep(fields);
    set(newFields, `${fieldName}.value`, newValue);
    setFields(newFields);

    return newFields;
  };

  const onChangePlotState = (fieldName, fieldPlotState) => {
    const newPlotStateMap = {
      ...(pipelineConfig?.meta?.plotStateMap || {}),
      [fieldName]: fieldPlotState,
    };
    const newPipelinConfig = cloneDeep(pipelineConfig);
    set(newPipelinConfig, "meta.plotStateMap", newPlotStateMap);
    setPipelineConfig(newPipelinConfig);
  };

  const doRerunRecord = async () => {
    setIsLoading(true);
    await postSigmaRerunExtractionJobs(
      {},
      {
        ids: [selectedRecord?.extractionJobId],
      }
    );
    await doPopulateSigmaRecords(pipelineConfigId, tableId);
    setIsLoading(false);
  };

  const onClickApplySideFilter = async () => {
    setFilterStrToUse(filterStr);
  };

  const doRerunRecordField = async column => {
    console.log("rerunning:", column);
    console.log("in table:", tableId);
    console.log("in pipeline:", pipelineConfig);
  };

  let fieldCount = 0;
  const containsFileColumns = columns?.some(
    col => col?.displayGroup === "File Templates"
  );

  let linkToDocForName = (
    <StyledFieldReferenceModal
      field={fields?.[recordNameColumn?.name]}
      column={recordNameColumn}
      isDisabled={!fields?.[recordNameColumn?.name]?.reference}
      reference={fields?.[recordNameColumn?.name]?.reference}
      extraParams={`&pipelineConfigId=${pipelineConfigId}`}
    >
      <EyeWithDocIcon
        style={{
          fill: fields?.[recordNameColumn?.name]?.reference
            ? "#0191ff"
            : "#ccc",
          marginTop: 2,
        }}
      />
    </StyledFieldReferenceModal>
  );
  if (!fields?.[recordNameColumn?.name]?.reference) {
    linkToDocForName = (
      <StyledRecordFilesModal record={selectedRecord} columns={columns} extraParams={`&pipelineConfigId=${pipelineConfigId}`}>
        <EyeWithDocIcon style={{ fill: "#ccc", cursor: "pointer" }} />
      </StyledRecordFilesModal>
    );
  }

  const analyticsColumns = columns?.filter(
    column => column?.actionType === "analytics"
  );

  const minimumEditBtns = (
    <>
      {isEditingValues ? (
        <>
          <SimpleButton onClick={onClickTick}>
            <TickIcon />
          </SimpleButton>
          <SimpleButton onClick={onClickCross}>
            <CrossIcon />
          </SimpleButton>
        </>
      ) : (
        <SimpleButton onClick={() => setIsEditingValues(true)}>
          <PencilIconDetailed />
        </SimpleButton>
      )}
      {!isEditingValues && (
        <SimpleButton onClick={doDeleteSelectedSigmaRecord}>
          <BinIcon />
        </SimpleButton>
      )}
    </>
  );

  const editBtns = (
    <div style={{ paddingTop: 10, display: "flex", gap: 10 }}>
      <AddSigmaRecordModalNew
        selectedTableId={tableId}
        recordId={selectedRecordId.id}
        style={{ width: "max-content" }}
        trigger={<SimpleButton isPrimary>Ingest</SimpleButton>}
        pipelineConfig={pipelineConfig}
      />
      <SimpleButton onClick={doRerunRecord}>Re-run</SimpleButton>
      {minimumEditBtns}
    </div>
  );

  let LayoutComponent = LayoutApp;
  if (window?.location?.pathname?.includes("/apps-new/")) {
    LayoutComponent = LayoutAppConfigurable;
  }

  return (
    <LayoutComponent>
      <Container isDisabled={isLoading}>
        <SideNav>
          <div style={{ padding: 20, borderBottom: "1px solid #E8ECEF" }}>
            <Tooltip title={appTableName}>
              <AppNameText>{appTableName}</AppNameText>
            </Tooltip>
          </div>
          <div style={{ overflow: "auto" }}>
            <FilterContainer>
              <FilterInput
                placeholder="Filter records"
                value={filterStr}
                onChange={e => setFilterStr(e.target.value)}
              />
              <ButtonWord isPrimary onClick={onClickApplySideFilter}>
                Apply
              </ButtonWord>
            </FilterContainer>
            {sigmaRecordsMostRecentFirst.map(record => (
              <SideNavItem
                id={record?.id}
                isSelected={record.id === selectedRecordId}
                onClick={() =>
                  navigate(
                    `/apps/${pipelineConfigId}/records/${record?.id}?tableId=${tableId}`
                  )
                }
              >
                <div>
                  {record?.fields?.[recordNameColumn?.dbName]?.value ||
                    record.id}
                </div>
                {record.id === selectedRecordId && editBtns}
              </SideNavItem>
            ))}
            {pageSize <= sigmaRecordsMostRecentFirst?.length && (
              <HasMoreBtn
                onClick={() => {
                  const newPageSize = pageSize + 10;
                  setPageSize(newPageSize);
                }}
              >
                Load more
              </HasMoreBtn>
            )}

            {/* <StyledPlusIcon
              onClick={doPostEmptyRecord}
              style={{ margin: "8px 20px" }}
            /> */}
          </div>

          <div style={{ borderTop: "1px solid #E8ECEF", padding: "10px 20px" }}>
            <AddSigmaRecordModalNew
              selectedTableId={tableId}
              doPostEmptyRecord={async () => {
                setIsLoading(true);
                const { data } = await doPostEmptyRecord(
                  pipelineConfig,
                  tableId
                );
                setIsLoading(false);
                navigate(
                  `/apps/${pipelineConfigId}/records/${data?.id}?isEditMode=true&tableId=${tableId}`
                );
              }}
              trigger={
                <ButtonWord style={{ width: "100%", height: "38px" }} isPrimary>
                  New record
                </ButtonWord>
              }
              pipelineConfig={pipelineConfig}
            />
          </div>
        </SideNav>
        <div style={{ width: "100%" }}>
          <TopBar>
            <NameFieldContainer>
              <RecordFieldInput
                style={{ fontSize: 18, fontWeight: 600 }}
                value={fields?.[recordNameColumn?.dbName]?.value}
                isEditMode={isEditingValues}
                onChange={e =>
                  onChangeRecordInput(e.target.value, recordNameColumn?.dbName)
                }
                rawValue={fields?.[recordNameColumn?.dbName]?.value}
              />

              {linkToDocForName}
              <ReferenceDebugModal
                reference={fields?.[recordNameColumn?.dbName]?.reference}
              />
            </NameFieldContainer>
            <div style={{ display: "flex", justifyContent: "end", gap: 8 }}>
              {minimumEditBtns}
              <RecordCodeModal record={selectedRecord} />
            </div>
            {/* <ButtonWord
              onClick={() => window?.open(flowUrl, "_blank")}
              isPrimary
            >
              Model
            </ButtonWord> */}
            <RecordFilesModal record={selectedRecord} columns={columns} extraParams={`&pipelineConfigId=${pipelineConfigId}`}>
              <EyeWithDocIcon style={{ fill: "#0191ff", cursor: "pointer" }} />
            </RecordFilesModal>
            {/* <TooltipClick
              position="bottom"
              tipContent={
                <div
                  style={{
                    display: "grid",
                    gap: 10,
                    border: "1px solid #ccc",
                    padding: 10,
                    width: 150,
                  }}
                >
                  <ButtonWord
                    disabled={isLoading || !selectedRecordId}
                    onClick={doDeleteSelectedSigmaRecord}
                  >
                    Delete
                  </ButtonWord>
                  <ButtonWord disabled={isFillingUp} onClick={doFillUp}>
                    Fill up
                  </ButtonWord>
                </div>
              }
            >
              <EllipsisIcon style={{ cursor: "pointer" }} />
            </TooltipClick> */}
            <JobsTooltipTrigger />
          </TopBar>
          {containsFileColumns && (
            <Tabs>
              <Tab
                isSelected={selectedTab === "fields"}
                onClick={() => setSelectedTab("fields")}
              >
                Fields
              </Tab>
              {wordDocColumnNames?.map(wordDocColumnName => (
                <Tab
                  isSelected={selectedTab === wordDocColumnName}
                  onClick={() => setSelectedTab(wordDocColumnName)}
                >
                  {wordDocColumnName?.replace("File Templates_", "")}
                </Tab>
              ))}
              {/* <Tab
                isSelected={selectedTab === "excel"}
                onClick={() => setSelectedTab("excel")}
              >
                excel
              </Tab>
              <Tab
                isSelected={selectedTab === "ppt"}
                onClick={() => setSelectedTab("ppt")}
              >
                ppt
              </Tab> */}
            </Tabs>
          )}
          {!["fields", "excel", "ppt"]?.includes(selectedTab) && (
            <div
              style={{
                height: "calc(100vh - 40px - 60px - 24px)",
                borderTop: "1px solid #ccc",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <WordDocAreaNew
                wordDocId={selectedRecord?.fields?.[selectedTab]?.value}
                isFillingUp={isFillingUp}
              />
            </div>
          )}
          <div
            style={{
              display: selectedTab === "excel" ? "block" : "none",
              height: "calc(100vh - 40px - 60px - 18px)",
              borderTop: "1px solid #ccc",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {selectedTab === "excel" && (
              <ExcelArea excelModelId="66c87045ca6bb039caf9cb9f" />
            )}
          </div>
          <div
            style={{
              display: selectedTab === "ppt" ? "block" : "none",
              height: "calc(100vh - 40px - 60px - 18px)",
              borderTop: "1px solid #ccc",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {selectedTab === "ppt" && (
              <SlideDocArea slideDocId="65e8c619f751327fd7a210ee" />
            )}
          </div>
          <GridContainer
            style={{
              display: selectedTab === "fields" ? "block" : "none",
              overflow: "auto",
              width: "calc(100vw - 90px - 20px - 280px)",
              height: containsFileColumns
                ? "calc(100vh - 40px - 54px - 22px)"
                : "calc(100vh - 40px - 60px)",
            }}
          >
            <GridDraggable
              gridSF={gridSF}
              style={{
                overflow: "hidden",
                transformOrigin: "top left",
                transform: `scale(${gridSF})`,
                width: `${2000 / gridSF}px`,
                height: `${1300 / gridSF}px`,
              }}
              initialLayout={pipelineConfig?.meta?.layout}
              onDragEnd={newLayout => doPutPipelineConfigLayout(newLayout)}
              isEditingDisabled={!isEditingLayout}
            >
              {groupNames
                ?.filter(
                  groupName => !!groupName && groupName !== "File Templates"
                )
                ?.map((groupName, groupIndex) => {
                  fieldCount = 0;
                  return (
                    <GroupCard key={groupName}>
                      <GroupTitle color={getColorFromIndex(groupIndex)}>
                        {groupName}
                      </GroupTitle>
                      <GroupFields>
                        {columns
                          ?.filter(column => column.displayGroup === groupName)
                          // ?.filter(column => column?.actionType !== "analytics")
                          ?.map((column, colIndex) => {
                            const plotStateMap =
                              pipelineConfig?.meta?.plotStateMap || {};

                            const doesSpan2Cols =
                              column?.type === "TABLE" ||
                              column?.formatType === "Long Text";

                            fieldCount += 1;
                            if (doesSpan2Cols) {
                              fieldCount += 1;
                            }

                            let linkToDoc = (
                              <StyledRecordFilesModal
                                record={selectedRecord}
                                columns={columns}
                                extraParams={`&pipelineConfigId=${pipelineConfigId}`}
                              >
                                <EyeWithDocIcon
                                  style={{ fill: "#ccc", cursor: "pointer" }}
                                />
                              </StyledRecordFilesModal>
                            );
                            if (fields?.[column.dbName]?.reference) {
                              linkToDoc = (
                                <StyledFieldReferenceModal
                                  isDisabled={
                                    !fields?.[column.dbName]?.reference
                                  }
                                  reference={fields?.[column.dbName]?.reference}
                                  column={column}
                                  field={fields?.[column.dbName]}
                                  extraParams={`&pipelineConfigId=${pipelineConfigId}`}
                                >
                                  <EyeWithDocIcon
                                    style={{
                                      fill: fields?.[column.dbName]?.reference
                                        ? getColorFromIndex(groupIndex)
                                        : "#ccc",
                                      marginTop: 2,
                                    }}
                                  />
                                </StyledFieldReferenceModal>
                              );
                            }
                            let reRunAction = (
                              <StyledResetIcon
                                onClick={() => doRerunRecordField(column)}
                              />
                            );
                            if (column?.actionType === "manual") {
                              reRunAction = null;
                            }

                            return (
                              <>
                                <Field
                                  style={{
                                    gridColumn: doesSpan2Cols
                                      ? "span 2"
                                      : "auto",
                                  }}
                                >
                                  <FieldLabel
                                    color={getColorFromIndex(groupIndex)}
                                  >
                                    {column.displayName}
                                    {linkToDoc}
                                    {(column?.actionType === "data_fetching" ||
                                      column?.actionType === "reasoning") && (
                                      <StyledGenerateFieldValueModal
                                        targetColumnSpec={column}
                                        record={selectedRecord}
                                        onClickApply={newField => {
                                          const newFields = onChangeRecordInput(
                                            newField?.value,
                                            column.dbName
                                          );
                                          doPutSelectedSigmaRecord(newFields);
                                        }}
                                      />
                                    )}
                                    <ReferenceDebugModal
                                      reference={
                                        fields?.[column.dbName]?.reference
                                      }
                                    />
                                    {reRunAction}
                                  </FieldLabel>
                                  {column?.type === "TABLE" && (
                                    <RecordFieldInputTable
                                      column={column}
                                      groupColor={getColorFromIndex(groupIndex)}
                                      initialPlotState={
                                        plotStateMap?.[column?.name]
                                      }
                                      tableColumns={column?.tableColumns}
                                      value={fields?.[column.dbName]?.value}
                                      isEditMode={isEditingValues}
                                      onChangeValue={newValue =>
                                        onChangeRecordInput(
                                          newValue,
                                          column.dbName
                                        )
                                      }
                                      onChangePlotState={fieldPlotState =>
                                        onChangePlotState(
                                          column?.name,
                                          fieldPlotState
                                        )
                                      }
                                      cellStyle={{maxWidth: "600px"}}
                                      extraParams={`&pipelineConfigId=${pipelineConfigId}`}
                                    />
                                  )}
                                  {column?.type !== "TABLE" && (
                                    <RecordFieldInput
                                      record={selectedRecord}
                                      recordNameColumn={recordNameColumn}
                                      pipelineConfig={pipelineConfig}
                                      onSaveComponent={newComponent => {
                                        const newValue =
                                          JSON.stringify(newComponent);
                                        const newFields = onChangeRecordInput(
                                          newValue,
                                          column.dbName
                                        );
                                        doPutSelectedSigmaRecord(newFields);
                                      }}
                                      isEditMode={isEditingValues}
                                      column={column}
                                      value={
                                        fields?.[column?.dbName]?.value || ""
                                      }
                                      onChange={e =>
                                        onChangeRecordInput(
                                          e.target.value,
                                          column.dbName
                                        )
                                      }
                                      rawValue={
                                        fields?.[column.dbName]?.rawValue || ""
                                      }
                                    />
                                  )}
                                </Field>
                                {fieldCount % 2 === 0 && <HorizontalLine />}
                              </>
                            );
                          })}
                      </GroupFields>
                    </GroupCard>
                  );
                })}
              {/* {analyticsColumns?.map((column, colIndex) => {
                const value = fields?.[column.dbName]?.value;

                let aggComponent = jsonParse(value);
                if (isEmpty(aggComponent)) {
                  aggComponent = {
                    id: column.dbName,
                    label: column.displayName,
                    nlc: column.description,
                  };
                }

                return (
                  <AggregationComponent
                    key={column?.dbName}
                    pipelineConfig={pipelineConfig}
                    aggComponent={aggComponent}
                    onSaveComponent={newComponent => {
                      const newValue = JSON.stringify(newComponent);
                      const newFields = onChangeRecordInput(
                        newValue,
                        column.dbName
                      );
                      doPutSelectedSigmaRecord(newFields);
                    }}
                  />
                );
              })} */}
            </GridDraggable>
          </GridContainer>
        </div>

        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
            display: selectedTab === "fields" ? "flex" : "none",
            gap: 10,
            zIndex: 1,
          }}
        >
          {/* <SendEmailModal
            recordNameColumn={recordNameColumn}
            record={selectedRecord}
          /> */}
          <ToggleDiv
            isSelected={isEditingLayout}
            onClick={() => {
              setGridSF(1);
              doPutGridSF(1);
            }}
            style={{
              width: "24px",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            <ResetIcon style={{ fill: "#010101", height: "10px" }} />
          </ToggleDiv>
          <ToggleDiv
            isSelected={isEditingLayout}
            onClick={() => {
              const newGridSf = gridSF - 0.1;
              setGridSF(newGridSf);
              doPutGridSF(newGridSf);
            }}
            style={{
              width: "24px",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            -
          </ToggleDiv>
          <ToggleDiv
            isSelected={isEditingLayout}
            onClick={() => {
              const newGridSf = gridSF + 0.1;
              setGridSF(newGridSf);
              doPutGridSF(newGridSf);
            }}
            style={{
              width: "24px",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            +
          </ToggleDiv>
          <ToggleDiv
            isSelected={isEditingLayout}
            onClick={() => setIsEditingLayout(!isEditingLayout)}
          >
            <MoveIcon />
            Rearrange
          </ToggleDiv>
          {/* <StyledButtonWord
            disabled={isLoading || !selectedRecordId}
            onClick={() => setIsEditingLayout(!isEditingLayout)}
          >
            {isEditingLayout ? <CrossIcon /> : <PencilIcon />}
          </StyledButtonWord> */}
        </div>
        {/* <Slideout isOpen={isChatOpen}>  // Jinli: Chat/Email slide out in apps record
          <FixedButton onClick={() => setIsChatOpen(prev => !prev)}>
            <ChatIcon />
          </FixedButton>
          <FixedButtonLower>
            <SendEmailModal
              recordNameColumn={recordNameColumn}
              record={selectedRecord}
            />
          </FixedButtonLower>
          {isChatOpen && (
            <ChatViewNew
              isSmallInput
              genContext="record_page"
              sigmaRecord={selectedRecord}
            />
            // <ChatView
            //   genContext="record_page"
            //   sigmaRecord={selectedRecord}
            //   abortController={abortController}
            //   setAbortController={setAbortController}
            //   isGenerating={isGenerating}
            //   setIsGenerating={setIsGenerating}
            // />
          )}
        </Slideout> */}
      </Container>
    </LayoutComponent>
  );
};

export default AppRecordPage;
