import { Gap } from "components/Layout";
import LayoutNew from "components/LayoutNew";
import ButtonWord from "components/ui/ButtonWord";
import { GearIcon } from "components/ui/Icons";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  align-content: start;
  gap: 24px;
  padding: 80px 120px;
  min-height: 100%;
  /* background: linear-gradient(180deg, #f3f5f7 0%, #f5f5f5 100%); */

  border-radius: 20px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
  justify-content: start;
`;

const Card = styled.div`
  padding: 20px;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 2fr auto;
  row-gap: 20px;
  column-gap: 12px;
  width: 236px;
  cursor: pointer;
  :hover {
    background-color: #eeeeee;
  }
`;

const CardTitle = styled.div`
  font-weight: 600;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  svg {
    :hover {
      fill: #0191ff;
    }
  }
`;

const CardLogo = styled.img`
  height: 40px;
`;

const CardButton = styled(ButtonWord)`
  padding: 0 4px;
`;

const CARDS = [
  {
    title: "2D Classification",
    logoSrc: "/images/boltzbit_logo.png",
    description: "Classify a datapoint (x1, x2) into a binary label.",
    envId: "2d-classification",
  },
  {
    title: "Bond Counterparty Reco",
    logoSrc: "/images/boltzbit_logo.png",
    description: "Recommend bond trading counter parties.",
    envId: "fiml-bond-counterparty-reco",
  },
  {
    title: "Device Signal Recovery",
    logoSrc: "/images/advan_research_corporation_logo.jpeg",
    description: "Impute C01 data device data from other sources.",
    envId: "advanr-signal-recovery",
  },
];

const EnterpriseModelsPage = () => {
  const navigate = useNavigate();
  return (
    <LayoutNew>
      <Container>
        <Title>Available models</Title>
        <Cards>
          {CARDS.map((card, index) => (
            <Card
              key={index}
              onClick={() => navigate(`/enterprise-models/${card.envId}`)}
            >
              <CardLogo src={card.logoSrc} />
              <CardTitle>
                {card.title}
              </CardTitle>
              <GearIcon />
              <Gap />
            </Card>
          ))}
        </Cards>
        <Gap height="20px" />
      </Container>
    </LayoutNew>
  );
};

export default EnterpriseModelsPage;
