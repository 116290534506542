import { KeyboardArrowDown } from "@material-ui/icons";
import { TickIcon } from "components/ui/Icons";
import TooltipClick from "components/ui/TooltipClick";
import TooltipNew from "components/ui/TooltipNew";
import { useState } from "react";
import styled from "styled-components";

const TickedIcon = styled(TickIcon)`
  width: 12px;
  height: 12px;
  fill: ${props => props.theme.color.primary};
`;

const UntickedCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const Option = styled.div`
  line-height: 1.2;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  align-items: center;
  padding: 12px 18px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const OptionsContainer = styled.div`
  box-shadow: ${props => props.theme.shadow};
  border-radius: 10px;
  // first of type
  ${Option}:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  ${Option}:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const SelectCustom = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px;
  width: 130px;
  font-weight: 600;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const GreyText = styled.div`
  color: ${props => props.theme.color.closer2};
  font-size: 12px;
  margin-left: 40px;
  font-weight: 400;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const OptionLabel = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const MODEL_SELECT_OPTIONS = [
  {
    label: "Boltzbit LLM",
    // value: "llama3",
    value: "",
    description: "Our default Boltzbit LLM",
  },
  {
    label: "Gemini",
    value: "gemini",
    description: "Model developed by Google DeepMind",
  },
  {
    label: "GPT-4o",
    value: "gpt-4o",
    description: "Open AI's most advanced model",
  },
  {
    label: "Finetuned LLM",
    value: "bz",
    description: "Your custom, trainable LLM",
  },
];

const ModelSelectDropdown = () => {
  const [selectedModelValue, setSelectedModelValue] = useState(
    localStorage?.getItem("model") || MODEL_SELECT_OPTIONS?.[0].value
  );

  const selectedModel = MODEL_SELECT_OPTIONS?.find(
    option => option?.value === selectedModelValue
  );

  return (
    <TooltipClick
      position="bottom"
      tipContent={
        <OptionsContainer>
          {MODEL_SELECT_OPTIONS?.map(option => {
            if (option?.value === "bz") {
              return (
                <Option
                  key={option?.value}
                  style={{ opacity: 0.5, pointerEvents: "none" }}
                >
                  <OptionLabel>
                    {option?.label}
                    <GreyText>(coming soon)</GreyText>
                  </OptionLabel>
                  <div
                    style={{
                      justifySelf: "end",
                    }}
                  >
                    <UntickedCircle />
                  </div>
                  <div>{option?.description}</div>
                </Option>
              );
            }

            return (
              <Option
                key={option?.value}
                onClick={() => {
                  setSelectedModelValue(option?.value);
                  localStorage?.setItem("model", option?.value);
                }}
              >
                <OptionLabel>{option?.label}</OptionLabel>
                <div
                  style={{
                    justifySelf: "end",
                  }}
                >
                  {selectedModelValue === option?.value ? (
                    <TickedIcon style={{ gridRow: "span 2" }} />
                  ) : (
                    <UntickedCircle />
                  )}
                </div>
                <div>{option?.description}</div>
              </Option>
            );
          })}
        </OptionsContainer>
      }
    >
      <TooltipNew
        tipContent={
          <div
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "2px",
            }}
          >
            Choose the model
          </div>
        }
      >
        <SelectCustom>
          {selectedModel?.label} <KeyboardArrowDown />
        </SelectCustom>
      </TooltipNew>
    </TooltipClick>
  );
};

export default ModelSelectDropdown;
