import {
  decodeStoredJwt,
  getLoggedInUserName,
} from "api/services/authenticationService";
import { postExcelModelDraft } from "api/services/excelModelsService";
import { postSlideDoc } from "api/services/slideDocsService";
import CreateWordModalTrigger from "components/CreateWordModalTrigger";
import DirectoryItemsList from "components/DirectoryItemsList";
import DirectoryItemsListPipelinesApps from "components/DirectoryItemsListPipelinesApps";
import {
  DocSearchIcon,
  DocsIcon,
  ExitIcon,
  GearIcon,
  PersonBlankIcon,
  SourcesIcon,
} from "components/IconsNew";
import { Gap } from "components/Layout";
import MessageModal from "components/MessageModal";
import NavHeader from "components/NavHeader";
import ButtonIcon from "components/ui/ButtonIcon";
import { BoltzhubLogoInner, PlusIcon } from "components/ui/Icons";
import TooltipClick from "components/ui/TooltipClick";
import useResource from "hooks/useResource";
import useSearchParamsState from "hooks/useSearchParamsState";
import { useAppLayoutConfig } from "pages/apps-new";
import { ICON_MAP } from "pages/apps-new/config";
import ProfileSidebar from "pages/profile-new/ProfileSidebar";
import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

const OuterContainer = styled.div`
  position: relative;
  height: 100vh;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 80px auto 1fr auto;
  background-color: #141718;
  height: ${props => props.minHeight};

  @media (max-width: 1240px) {
    grid-template-columns: 0px auto 1fr auto;
  }
`;

const StyledNavHeader = styled(NavHeader)`
  top: 20px;
  right: 24px;
  background-color: transparent;
  box-shadow: none;
`;

const SideBar = styled.div`
  @media (max-width: 1240px) {
    display: none;
  }

  position: fixed;
  color: white;
  width: 80px;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 10px;
  padding: 0 10px;
  box-shadow: 0px 24px 60px 0px #00000099;
  z-index: 102;
  height: ${props => props?.height};
  align-content: start;
  padding-bottom: 20px;
`;

const SideBarItems = styled.div`
  display: grid;
  gap: 10px;
  align-content: start;
`;

const ContentCard = styled.div`
  position: relative;
  background: ${props => props.bgColor};
  margin: 20px;
  margin-left: 0;
  border-radius: 20px;
  grid-column: 3;
  overflow: auto;
`;

const SideItem = styled.div`
  display: grid;
  gap: 6px;
  justify-items: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 52px;
  color: #848484;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  ${props =>
    props.isDisabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `};
  white-space: nowrap;

  ${props =>
    props?.isActive &&
    `
      background-color: #717171;
      border-radius: 10px;

      background: linear-gradient(
        270deg,
        #323337 50%,
        rgba(70, 79, 111, 0.5) 100%
      );
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
        inset 0px 1px 0px rgba(255, 255, 255, 0.05);
  `}

  svg {
    fill: ${props => (props.isActive ? "#497FF3" : "#ffffff")};
    width: 22px;
  }

  :hover {
    svg {
      fill: #497ff3;
    }
  }
`;

const SidbarNavItem = styled(Link)`
  text-decoration: none;
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-items: start;
  justify-content: start;
  padding: 4px 10px;
  align-items: center;
  align-content: center;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;

  svg {
    fill: ${props => (props.isActive ? "#497FF3" : "#ffffff")};
    width: 22px;
  }

  :hover {
    color: #497ff3;
    svg {
      fill: #497ff3;
    }
  }

  ${props =>
    props?.isActive &&
    `
    color: #497FF3;
    svg {
      fill: #497FF3;
    }
  `}
`;

const SideItemActiveBg = styled(SideItem)`
  ${props =>
    props.isActive &&
    `
    background-color: #717171;
    border-radius: 10px;

    background: linear-gradient(270deg, rgba(73, 127, 243, 0.5) 50%, rgba(56, 208, 218, 0.4) 100%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
      inset 0px 1px 0px rgba(255, 255, 255, 0.05);
  `}
`;

const DirectoryHeaderContainer = styled.div`
  z-index: 102;
  padding: 10px 20px;
  padding-top: 20px;
  background-color: #232627;
  border-top-right-radius: 20px;
  position: sticky;
  top: 0;
`;

const SideActionBar = styled.div`
  grid-column: 2;
  transition: width 0.2s;
  overflow: auto;
  height: calc(100vh - 40px);
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #232627;
  margin-top: 20px;
  margin-right: 20px;
  z-index: 102;

  position: sticky;
  top: 20px;
`;

const SideActionBarRight = styled.div`
  grid-column: 4;
  transition: width 0.2s;
  overflow: hidden;
  height: calc(100vh - 40px);
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  background-color: white;
  margin-top: 20px;
  z-index: 102;

  position: sticky;
  top: 20px;
`;

const SideTitle = styled.div`
  color: white;
  font-size: 22px;
  font-weight: 500;
  padding: 0px 0;
  padding-bottom: 16px;
`;

const BottomBorder = styled.div`
  height: 1px;
  width: 100%;
  position: absolute;
  background-color: #343839;
  left: 0;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  height: auto;
  padding: 6px 14px;
  font-weight: 500;
  font-size: 14px;
`;

const StyledBoltzbitLogo = styled(BoltzhubLogoInner)`
  fill: white;
  height: 36px;
  margin-top: 32px;
  margin-bottom: 18px;
  fill: ${props => props.theme.color.primary};
  align-self: start;
`;

const StyledLink = styled(Link)`
  justify-self: center;
`;

const BtnText = styled.span``;

// hides fixed overflow elements
const BlackBar = styled.div`
  position: absolute;
  width: 100%;
  height: 20px;
  background-color: #141718;
  z-index: 1;
  left: 0;
`;

const WhiteBar = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 1;
`;

const doCreateExcelModel = async () => {
  const { data: newModel, error } = await postExcelModelDraft({
    directoryPath: "/working-files",
  });
  if (!error) {
    window.open(`/excel-views/${newModel?.id}`, "_blank");
  }
};

const doCreateSlideDoc = async () => {
  const { data, error } = await postSlideDoc(
    { directoryPath: "/working-files" },
    {
      fileName: `Untitled Presentation`,
      content: {
        slides: [
          {
            boxes: [
              {
                id: uuidv4(),
                x: 100,
                y: 100,
                w: 200,
                h: 150,
                text: "Add text here",
                styles: [],
                boxStyle: {
                  bgColor: "#ffffff",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#000000",
                },
                isSelected: true,
              },
            ],
          },
        ],
      },
    }
  );
  if (!error) {
    window.open(`/slide-docs/${data?.id}`, "_blank");
  }
};

const DOCS_SIDEBAR = (
  <DirectoryItemsList
    renderHeaderComponent={({ tipContent }) => (
      <DirectoryHeaderContainer>
        <SideTitle>Chats</SideTitle>
        <Gap />
        <TooltipClick
          tipStyle={{ marginLeft: "-200px", width: "max-content" }}
          tipContent={tipContent}
        >
          <StyledButtonIcon isActive>
            <PlusIcon height="10px" />
            <BtnText>New</BtnText>
          </StyledButtonIcon>
        </TooltipClick>
        <Gap />
      </DirectoryHeaderContainer>
    )}
    isInitiallyOpen
    isDeleteActionHidden
    directoryPath="/working-files"
    folderActions={["new_chat"]}
  />
);

const WORD_SIDEBAR = (
  <DirectoryItemsList
    renderHeaderComponent={({ tipContent }) => (
      <DirectoryHeaderContainer>
        <SideTitle>Word Documents</SideTitle>
        <Gap />
        <CreateWordModalTrigger
          trigger={
            <StyledButtonIcon isActive>
              <PlusIcon height="10px" />
              <BtnText>New</BtnText>
            </StyledButtonIcon>
          }
        />
        <Gap />
      </DirectoryHeaderContainer>
    )}
    isInitiallyOpen
    isDeleteActionHidden
    directoryPath="/working-files"
    folderActions={["new_chat", "new_word_doc", "upload_word_doc"]}
  />
);

const EXCEL_SIDEBAR = (
  <DirectoryItemsList
    renderHeaderComponent={({ tipContent }) => (
      <DirectoryHeaderContainer>
        <SideTitle>Excel Files</SideTitle>
        <Gap />
        <StyledButtonIcon isActive>
          <PlusIcon height="10px" />
          <BtnText onClick={doCreateExcelModel}>New</BtnText>
        </StyledButtonIcon>
        <Gap />
      </DirectoryHeaderContainer>
    )}
    isInitiallyOpen
    isDeleteActionHidden
    directoryPath="/working-files"
    folderActions={["new_chat", "new_word_doc", "upload_word_doc"]}
  />
);

const PPT_SIDEBAR = (
  <DirectoryItemsList
    renderHeaderComponent={({ tipContent }) => (
      <DirectoryHeaderContainer>
        <SideTitle>Presentations</SideTitle>
        <Gap />
        <StyledButtonIcon isActive>
          <PlusIcon height="10px" />
          <BtnText onClick={doCreateSlideDoc}>New</BtnText>
        </StyledButtonIcon>
        <Gap />
      </DirectoryHeaderContainer>
    )}
    isInitiallyOpen
    isDeleteActionHidden
    directoryPath="/working-files"
    folderActions={["new_chat", "new_word_doc", "upload_word_doc"]}
  />
);

const TYPE_TO_CONTENT = {
  word: WORD_SIDEBAR,
  docs: DOCS_SIDEBAR,
  excel: EXCEL_SIDEBAR,
  ppt: PPT_SIDEBAR,
  sources: (
    <DirectoryItemsList
      renderHeaderComponent={({ tipContent }) => (
        <DirectoryHeaderContainer>
          <SideTitle>Sources</SideTitle>
          <Gap />
          <TooltipClick
            tipStyle={{ marginLeft: "-200px", width: "max-content" }}
            tipContent={tipContent}
          >
            <StyledButtonIcon isActive>
              <PlusIcon height="10px" />
              <BtnText>New</BtnText>
            </StyledButtonIcon>
          </TooltipClick>
          <Gap />
        </DirectoryHeaderContainer>
      )}
      isInitiallyOpen
      isDeleteActionHidden
      directoryPath="/source-files"
      folderActions={["upload_files", "upload_audio"]}
    />
  ),
  pipelines: (
    <DirectoryItemsListPipelinesApps
      renderHeaderComponent={({ tipContent }) => (
        <DirectoryHeaderContainer>
          <SideTitle>Apps</SideTitle>
          <Gap />
          <TooltipClick
            tipStyle={{ marginLeft: "-200px", width: "max-content" }}
            tipContent={tipContent}
          >
            <StyledButtonIcon isActive>
              <PlusIcon height="10px" />
              <BtnText>New</BtnText>
            </StyledButtonIcon>
          </TooltipClick>
          <Gap />
        </DirectoryHeaderContainer>
      )}
      isInitiallyOpen
      isDeleteActionHidden
      directoryPath="/template-files"
      isIconDisabled
    />
  ),
  profile: (
    <DirectoryHeaderContainer>
      <SideTitle>Settings</SideTitle>
      <BottomBorder />
      <ProfileSidebar />
    </DirectoryHeaderContainer>
  ),
};

const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
`;

export const getAppBasePathSource = pipelineConfigId =>
  `/source-files-app-${pipelineConfigId}`;
export const getAppBasePathWorking = pipelineConfigId =>
  `/working-files-app-${pipelineConfigId}`;

const LayoutAppConfigurable = ({
  className = "",
  style = {},
  children,
  isRightSideOpen = false,
  rightContent = null,
  bgColor = "white",
  isFullScreen = false,
  topBarBgColor = "transparent",
}) => {
  const navigate = useNavigate();
  const { pipelineConfigId } = useParams();
  const [searchParams] = useSearchParams();
  const [userProfile] = useResource({
    url: `/solutions/ocr/authentication-service/api/v1/user-profiles/${getLoggedInUserName()}`,
  });

  const location = useLocation();
  const [sidebar, setSidebar] = useSearchParamsState({
    paramName: "sidebar",
    initialValue: {},
  });

  const [messageModalContent, setMessageModalContent] = useState(null);
  const [appLayoutConfig] = useAppLayoutConfig(pipelineConfigId);

  const openSidebarType = sidebar?.type;

  const tokenObj = decodeStoredJwt();
  const isRestrictedMode = tokenObj?.authorities?.includes("ROLE_TRIAL_USER");

  if (window?.screen?.width < 1240) {
    return <div>{children}</div>;
  }

  if (isFullScreen) {
    return (
      <Container style={{ gridTemplateColumns: "1fr" }}>
        <ContentCard
          bgColor={bgColor}
          style={{ gridColumn: 1, marginLeft: 20 }}
        >
          <BlackBar style={{ top: 0 }} />
          <StyledNavHeader isModelDropdownVisible />
          {children}
        </ContentCard>
      </Container>
    );
  }

  let sidebarPages = null;
  if (sidebar?.type === "sub-navigation") {
    const subNavItem = appLayoutConfig?.navItems?.find(
      navItem => navItem?.id === sidebar?.id
    );
    sidebarPages = (
      <>
        <SideTitle style={{ padding: 20 }}>{subNavItem?.title}</SideTitle>
        {subNavItem?.navItems?.map(navItem => {
          return (
            <SidbarNavItem
              isActive={
                window?.location?.pathname ===
                `/apps-new/${pipelineConfigId}/${navItem?.slug}`
              }
              to={`/apps-new/${pipelineConfigId}/${navItem?.slug}`}
            >
              {ICON_MAP?.[navItem?.icon] || <DocSearchIcon />}
              {navItem?.title}
            </SidbarNavItem>
          );
        })}
      </>
    );
  }

  return (
    <OuterContainer>
      <Container className={className} style={style} minHeight="100%">
        <SideBar height="100vh">
          <SideBarItems>
            <StyledLink to="/">
              <StyledBoltzbitLogo />
            </StyledLink>

            {appLayoutConfig?.navItems?.map(navItem => {
              if (navItem?.type === "sub-navigation") {
                let isActive = navItem?.navItems?.find(
                  subItem =>
                    window?.location?.pathname ===
                    `/apps-new/${pipelineConfigId}/${subItem?.slug}`
                );
                return (
                  <SideItem
                    isActive={isActive}
                    onClick={() => {
                      let newSidebar = {
                        type: "sub-navigation",
                        id: navItem?.id,
                      };
                      if (openSidebarType === "sub-navigation") {
                        newSidebar = {};
                      }
                      setSidebar(newSidebar);
                    }}
                  >
                    {ICON_MAP?.[navItem?.icon] || <DocSearchIcon />}
                    {navItem?.title}
                  </SideItem>
                );
              }

              return (
                <SideItem
                  isActive={
                    window?.location?.pathname ===
                    `/apps-new/${pipelineConfigId}/${navItem?.slug}`
                  }
                  onClick={() =>
                    navigate(`/apps-new/${pipelineConfigId}/${navItem?.slug}`)
                  }
                >
                  {ICON_MAP?.[navItem?.icon] || <DocSearchIcon />}
                  {navItem?.title}
                </SideItem>
              );
            })}

            <SideItem
              isActive={sidebar?.type === "sources"}
              onClick={() => {
                if (sidebar?.type === "sources") {
                  searchParams.delete("sidebar");
                  navigate(location.pathname, {
                    search: searchParams.toString(),
                  });
                  return;
                }

                setSidebar({ type: "sources" });
              }}
            >
              <SourcesIcon />
              Sources
            </SideItem>

            <SideItem
              isActive={sidebar?.type === "docs"}
              onClick={() => {
                if (sidebar?.type === "docs") {
                  searchParams.delete("docs");
                  navigate(location.pathname, {
                    search: searchParams.toString(),
                  });
                  return;
                }

                setSidebar({ type: "docs" });
              }}
            >
              <DocsIcon />
              Docs
            </SideItem>

            <SideItem
              isActive={
                window?.location?.pathname ===
                `/apps-new/${pipelineConfigId}/settings`
              }
              onClick={() => navigate(`/apps-new/${pipelineConfigId}/settings`)}
            >
              <GearIcon />
              Settings
            </SideItem>
          </SideBarItems>

          <SideBarItems style={{ alignSelf: "end" }}>
            <SideItem
              onClick={() =>
                navigate(`/flow-home?sidebar=%7B"type"%3A"pipelines"%7D`)
              }
            >
              <ExitIcon />
              Exit
            </SideItem>

            <SideItemActiveBg
              isDisabled={isRestrictedMode}
              style={{ alignSelf: "end" }}
              isActive={sidebar?.type === "profile" && !sidebar?.filterType}
              onClick={() => {
                let newSidebar = { type: "profile" };
                if (sidebar?.type === "profile" && !sidebar?.filterType) {
                  newSidebar = {};
                }

                setSidebar(newSidebar);
              }}
            >
              {userProfile?.image ? (
                <ProfileImg src={userProfile?.image} />
              ) : (
                <PersonBlankIcon />
              )}
            </SideItemActiveBg>
          </SideBarItems>
        </SideBar>
        <SideActionBar style={{ width: openSidebarType ? 240 : 0 }}>
          {openSidebarType === "profile" && TYPE_TO_CONTENT["profile"]}
          {openSidebarType === "sources" && (
            <DirectoryItemsList
              renderHeaderComponent={({ tipContent }) => (
                <DirectoryHeaderContainer>
                  <SideTitle>Sources</SideTitle>
                  <Gap />
                  <TooltipClick
                    tipStyle={{ marginLeft: "-200px", width: "max-content" }}
                    tipContent={tipContent}
                  >
                    <StyledButtonIcon isActive>
                      <PlusIcon height="10px" />
                      <BtnText>New</BtnText>
                    </StyledButtonIcon>
                  </TooltipClick>
                  <Gap />
                </DirectoryHeaderContainer>
              )}
              isInitiallyOpen
              isDeleteActionHidden
              directoryPath={getAppBasePathSource(pipelineConfigId)}
              folderActions={[
                "upload_files",
                // "ingest_webpage",
                // "send_email",
                "upload_audio",
              ]}
            />
          )}
          {openSidebarType === "docs" && (
            <DirectoryItemsList
              renderHeaderComponent={({ tipContent }) => (
                <DirectoryHeaderContainer>
                  <SideTitle>Docs</SideTitle>
                  <Gap />
                  <TooltipClick
                    tipStyle={{ marginLeft: "-200px", width: "max-content" }}
                    tipContent={tipContent}
                  >
                    <StyledButtonIcon isActive>
                      <PlusIcon height="10px" />
                      <BtnText>New</BtnText>
                    </StyledButtonIcon>
                  </TooltipClick>
                  <Gap />
                </DirectoryHeaderContainer>
              )}
              isInitiallyOpen
              isDeleteActionHidden
              directoryPath={getAppBasePathWorking(pipelineConfigId)}
              folderActions={["new_word_doc", "upload_word_doc"]}
            />
          )}
          {openSidebarType === "sub-navigation" && sidebarPages}
        </SideActionBar>
        <BlackBar style={{ top: 0 }} />
        <ContentCard bgColor={bgColor}>
          <WhiteBar style={{ backgroundColor: topBarBgColor }} />
          {children}
        </ContentCard>
        <SideActionBarRight style={{ width: isRightSideOpen ? 300 : 0 }}>
          {rightContent}
        </SideActionBarRight>
      </Container>
      <MessageModal
        content={messageModalContent}
        setContent={setMessageModalContent}
      />
    </OuterContainer>
  );
};

export default LayoutAppConfigurable;
