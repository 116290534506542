import { Slider } from "@material-ui/core";
import { apiPostRawResponse } from "api/api-http-methods";
import { getTableContent, renderWithReferences } from "components/ChatViewNew";
import { ChevronDownIcon, LogoInner } from "components/ui/Icons";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getColorFromString } from "utils/common";
import ButtonWord from "./ui/ButtonWord";
import TooltipNew from "./ui/TooltipNew";

const Container = styled.div`
  border: 1px solid #e0e0e0;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;

  display: grid;
  gap: 12px;
  ${props => props?.disabled && "opacity: 0.5; pointer-events: none;"}
`;

const SendButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 10px;
  background-color: #497ff3;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: #3f6cc7;
  }
  :disabled {
    background-color: #c2c2c2;
    cursor: default;
  }
`;

const StyledBubbleSendButton = styled(SendButton)`
  transition: opacity 0.2s;
  fill: #848484;
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  cursor: pointer;
  stroke: black;
  width: 16px;
  height: 16px;
  transform-origin: center;
  transform: ${props =>
    props?.isCollapsed ? "rotate(270deg)" : "rotate(0deg)"};
  z-index: 1;
  padding: 2px;
  margin-top: 0px;

  border-radius: 4px;
  :hover {
    background-color: #f1f1f1;
  }
`;

const TwoColumns = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr auto;
  gap: 10px;
`;

const FieldInput = styled.input`
  background-color: transparent;
  padding: 0px 4px;
  color: black;
  font-size: 14px;

  min-width: 48px;
  outline: none;
  border: 1px solid white;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const QueryText = styled.div`
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.queryTextSpan || "690px"};
`;

const QueryType = styled.div`
  grid-column: span 3;
  font-size: 12px;
  font-weight: 500;
  background-color: ${props => props.bgColor}44;
  width: max-content;
  border-radius: 4px;
  padding: 0 2px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

const QuestionBlockText = styled.textarea`
  border: none;
  resize: none;
  font-family: "Montserrat";
  position: relative;
  line-height: 1.4;
  white-space: pre-wrap;
  min-height: 4px;
  margin: 4px 0px;
  scroll-margin-top: 60px;
  border-left: 2px solid transparent;
  font-weight: 500;
  border-radius: 4px;
  background-color: ${props => props.bgColor || "transparent"};
  width: 100%;
  height: 200px;
  border: 1px solid ${props => props.theme.color.primary};

  ${props =>
    props?.isOneLine &&
    `overflow: hidden; text-overflow: ellipsis; white-space: nowrap; height: 1.6em; pointer-events: none; border: 1px solid transparent;`}

  ${props => props.isIngested && `background-color: #0191ff11;`}
  ${props => props.isHighlighted && `background-color: #f1f1f1;`}

  ${props => props.isDisabled && `pointer-events: none;`}

  animation: none;
  @keyframes pulse {
    0% {
      border-left: 2px solid transparent;
    }
    50% {
      border-left: 2px solid #0191ff;
    }
    100% {
      border-left: 2px solid transparent;
    }
  }
`;

const BubbleTitle = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BlockContent = styled.div`
  white-space: pre-wrap;
`;

const AnswerBlocks = styled.div``;

// jinli: the type that triggers free think rendering
const FREE_THINK_TYPE = "think";

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com";
}

const saveMemoryRecord = async (key, value) => {
  const url = `/bz-api/v1/memories`;
  const payload = { key, value };
  const response = await apiPostRawResponse(url, {}, payload);
  console.log(response);
};

const Step = ({
  initialStep,
  onPressSend = newQuery => {},
  blocks = [],
  queryTextSpan = null,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [step, setStep] = useState(initialStep);
  const [isExpanded, setIsExpanded] = useState(false);
  const [learningWeight, setLearningWeight] = useState(0.0);

  const bgColor = getColorFromString(`${step?.type}1`);

  useEffect(() => {
    setStep(initialStep);
  }, [JSON.stringify(initialStep)]);

  const highlightReferenceIdsStr = searchParams?.get("highlightReferenceIds");
  useEffect(() => {
    if (!highlightReferenceIdsStr) {
      return;
    }

    const hilightedReferenceIds = highlightReferenceIdsStr
      .split(",")
      ?.map(id => {
        const firstPart = id?.split("_")?.[0];
        return firstPart;
      });
    const ansBlockReferenceIds = step?.ansBlocks?.map(
      block => block?.referenceId
    );

    // if any highlighted reference id is present in the answer blocks
    if (hilightedReferenceIds.some(id => ansBlockReferenceIds.includes(id))) {
      setIsExpanded(true);
    }

    setSearchParams({});

    return () => setSearchParams({});
  }, [highlightReferenceIdsStr]);

  const FreeThink = (
    <div>
      {isExpanded ? (
        <QuestionBlockText
          value={step?.query}
          onChange={e => {
            setStep({ ...step, query: e.target.value });
          }}
        />
      ) : (
        <QueryText queryTextSpan={queryTextSpan}>{step?.query}</QueryText>
      )}
    </div>
  );

  const CoT = (
    <div>
      <QueryType bgColor={bgColor}>
        {step?.type} ({step?.ansBlocks?.length} p.)
      </QueryType>
      {isExpanded ? (
        <QuestionBlockText
          value={step?.query}
          onChange={e => setStep({ ...step, query: e.target.value })}
        />
      ) : (
        <QueryText queryTextSpan={queryTextSpan}>{step?.query}</QueryText>
      )}
    </div>
  );

  const LearningWeightSetter = (
    <TooltipNew
      tipText="0: not learn this thought, 1: learn this thought precisely"
      style={{ display: "inherit", marginLeft: "40%" }}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <p style={{ whiteSpace: "nowrap", height: "24px" }}>
          Set learning rate:
        </p>
        <Slider
          style={{ height: "4px", padding: "10px 0px", marginLeft: "10px" }}
          valueLabelDisplay="auto"
          value={learningWeight}
          onChange={(_, newValue) => setLearningWeight(newValue)}
          step={0.1}
          min={0.0}
          max={1.0}
        />
        <FieldInput
          type="number"
          step={0.1}
          min={0.0}
          max={1.0}
          value={learningWeight}
          onChange={e => {
            console.log(e.target.value);
            setLearningWeight(
              e.target.value === "" ? 0.01 : Number(e.target.value)
            );
          }}
        />
      </div>
    </TooltipNew>
  );

  const CoTAnswer = (
    <AnswerBlocks style={{ display: isExpanded ? "block" : "none" }}>
      {step?.ansBlocks?.map((block, index) => {
        const { isTableCell, rowIndex, columnIndex } = block;
        let dataRefId = "";
        if (!block?.isTableCell) {
          dataRefId = block?.referenceId;
        }

        if (isTableCell && (rowIndex !== 0 || columnIndex !== 0)) {
          return null;
        }

        let blockContent = renderWithReferences(block, setSearchParams, blocks);
        if (isTableCell) {
          blockContent = getTableContent(
            step?.ansBlocks,
            index,
            setSearchParams
          );
        }

        return (
          <BlockContent key={index} data-ref-id={dataRefId}>
            {blockContent}
          </BlockContent>
        );
      })}
    </AnswerBlocks>
  );

  return (
    <div>
      <TwoColumns>
        {step?.type === FREE_THINK_TYPE ? FreeThink : CoT}
        <Buttons>
          <StyledChevronDownIcon
            isCollapsed={!isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </Buttons>
      </TwoColumns>
      {isExpanded && (
        <TwoColumns>
          {LearningWeightSetter}
          <TooltipNew tipText={
            learningWeight === 0? "Increase learning rate to activate" : "Learn this thought and rerun"
            }>
              <ButtonWord
                style={{padding: "5px 10px"}}
                disabled={!isExpanded || learningWeight === 0}
                onClick={() => {
                  onPressSend(step?.query);
                }}
              >
                Learn and rerun
              </ButtonWord>
            </TooltipNew>

        </TwoColumns>
      )}
      {step?.type === FREE_THINK_TYPE ? null : CoTAnswer}
    </div>
  );
};

const ChainOfThoughtSteps = ({
  question,
  steps,
  onPressReRunStep = newSteps => {},
  blocks = [],
  queryTextSpan = null,
  style = {},
  disabled = false,
}) => {
  // if (!steps?.length || steps?.length === 1) {
  if (!steps?.length) {
    return null;
  }
  // Jinli: if it's a single extraction step, ignore
  if (steps?.length === 1) {
    if (steps[0].type !== FREE_THINK_TYPE) {
      return null;
    }
  }

  return (
    <Container style={style} disabled={disabled}>
      <BubbleTitle>
        <LogoInner />
        {steps[0].type === FREE_THINK_TYPE
          ? "Thought Process"
          : "Chain of Thought"}
      </BubbleTitle>
      {steps.map((step, index) => {
        return (
          <Step
            key={index}
            initialStep={step}
            onPressSend={
              step?.type === FREE_THINK_TYPE
                ? async newQuery => {
                    // Jinli: click to save memory record
                    const key = question;
                    const record = JSON.stringify({
                      question: question,
                      think: newQuery,
                    });
                    await saveMemoryRecord(key, record);

                    // and re-run?
                    const newSteps = cloneDeep(steps);
                    newSteps[index].query = newQuery;
                    onPressReRunStep(newSteps);
                  }
                : newQuery => {
                    const newSteps = cloneDeep(steps);
                    newSteps[index].query = newQuery;
                    onPressReRunStep(newSteps);
                  }
            }
            blocks={blocks}
            queryTextSpan={queryTextSpan}
          />
        );
      })}
    </Container>
  );
};

export default ChainOfThoughtSteps;
