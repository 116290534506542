import styled from "styled-components";
import LayoutNew from "components/LayoutNew";
import { Gap } from "components/Layout";

const Container = styled.div`
  padding: 20px;
`;


const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const CodeDescription = styled.div`
  white-space: pre-wrap;
  line-height: 1.5;
  code {
    height: 200px;
    background-color: white;
    border-radius: 4px;
    border-style: solid;
    border-width: thin;
    padding-left: 4px;
    padding-right: 4px;
    font-family: monospace;
  }
`;


const DeploymentDocPage = () => {
  return (
    <LayoutNew bgColor="linear-gradient(180deg, #F3F5F7 0%, #FDFDFD 100%);">
      <Container>
        <Title>Deployment Guide</Title>
        <Gap />
        <CodeDescription>
          Documentation of deploying <code>bz-serices</code> will be shown here. TBC.
        </CodeDescription>
      </Container>
    </LayoutNew>
  );
};

export default DeploymentDocPage;
