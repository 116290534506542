import { KeyboardArrowDown } from "@material-ui/icons";
import { TickIcon } from "components/ui/Icons";
import TooltipClick from "components/ui/TooltipClick";
import { useState } from "react";
import styled from "styled-components";

const OptionsContainer = styled.div`
  box-shadow: ${props => props.theme.shadow};
`;

const TickedIcon = styled(TickIcon)`
  width: 12px;
  height: 12px;
  fill: ${props => props.theme.color.primary};
`;

const UntickedCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const Option = styled.div`
  line-height: 1.2;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  /* gap: 10px; */
  column-gap: 10px;
  align-items: center;
  padding: 8px 12px;
  /* gap: 4px; */
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const SelectCustom = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px;
  font-weight: 600;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const StyledA = styled.a`
  display: flex;
  align-content: center;
  svg {
    width: 16px;
    height: 16px;
    fill: black;
  }

  :hover {
    background-color: ${props => props.theme.color.closer1};
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const GreyText = styled.a`
  color: ${props => props.theme.color.closer2};
  font-size: 12px;
  margin-left: 40px;
  font-weight: 400;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const HUB_BASE_URL = "https://hub.boltzbit.com";

export const LANGUAGE_SELECT_OPTIONS = [
  {
    label: "EN",
    value: "EN",
  },
  {
    label: "FR",
    value: "FR",
  },
  {
    label: "ES",
    value: "ES",
  },
  {
    label: "DE",
    value: "DE",
  },
];

const LanguageSelectDropdown = () => {
  const [selectedModelValue, setSelectedModelValue] = useState(
    localStorage.getItem("language") || LANGUAGE_SELECT_OPTIONS?.[0].value
  );

  const selectedModel = LANGUAGE_SELECT_OPTIONS?.find(
    option => option?.value === selectedModelValue
  );

  return (
    <TooltipClick
      position="bottom"
      tipContent={
        <OptionsContainer>
          {LANGUAGE_SELECT_OPTIONS?.map((option, i) => (
            <Option
              key={i}
              onClick={() => {
                setSelectedModelValue(option?.value);
                localStorage.setItem("language", option?.value);
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                  marginBottom: "4px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {option?.label}
              </div>
              <div
                style={{
                  justifySelf: "end",
                }}
              >
                {selectedModelValue === option?.value ? (
                  <TickedIcon style={{ gridRow: "span 2" }} />
                ) : (
                  <UntickedCircle />
                )}
              </div>
              <div>{option?.description}</div>
            </Option>
          ))}
        </OptionsContainer>
      }
    >
      <SelectCustom>
        {selectedModel?.label} <KeyboardArrowDown />
      </SelectCustom>
    </TooltipClick>
  );
};

export default LanguageSelectDropdown;
