import FieldReferenceModal from "components/FieldReferenceModal";
import { EyeWithDocIcon } from "components/IconsNew";
import RecordFieldInput from "components/RecordFieldInput";
import ReferenceDebugModal from "components/ReferenceDebugModal";
import ViewHtmlModal from "components/ViewHtmlModal";
import { PLOT_TYPES, getPlotComponent } from "components/plots";
import {
  PlusIcon,
  TrashIcon
} from "components/ui/Icons";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-content: start;
  align-items: start;
  gap: 10px;
  padding-right: 0px;
  min-width: 200px;
  width: 100%;
  max-height: 220px;
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

const Td = styled.td`
  padding: 6px;
  text-align: left;
  :nth-child(1) {
    /* border-top-left-radius: 24px;
    border-bottom-left-radius: 24px; */
    padding-left: 8px;
  }
  :last-child {
    /* border-top-right-radius: 24px;
    border-bottom-right-radius: 24px; */
    padding-right: 8px;
  }
`;

const Th = styled.th`
  font-weight: 500;
  padding: 4px 8px;
  padding-bottom: 8px;
  text-align: left;
  border-bottom: 1px solid grey;
`;

const Tr = styled.tr`
  ${props => props.disabled && "pointer-events: none; opacity: 0;"}
  :nth-child(odd) {
    background-color: ${props => props.groupColor}33;
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
  height: 12px;
  fill: #8d8d8d;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  height: 12px;
  fill: #8d8d8d;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledFieldReferenceModal = styled(FieldReferenceModal)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const InputAndRef = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    height: 10px;
  }
  :hover {
    ${StyledFieldReferenceModal} {
      opacity: 1;
    }
  }
`;

const StyledSelect = styled.select`
  font-family: "Montserrat", sans-serif;
  border: 1px solid #497ff3;
  border-radius: 2px;
  outline: none;
`;

const TableScroller = styled.div`
  overflow: auto;
`;

const RecordFieldInputTable = ({
  value = null,
  column = {},
  onChangeValue = () => {},
  onChangePlotState = () => {},
  isEditMode = false,
  initialPlotState = {},
  tableColumns = [],
  groupColor = "#0191ff",
  cellStyle = {},
  extraParams = "",
}) => {
  const { selectedRecordIdStr } = useParams();
  // const [editedValue, setEditedValue] = useState(value);

  const [plotState, setPlotState] = useState({
    plotType: "Table",
    xColumnName: tableColumns?.find(column => column?.type === "TEXT")
      ?.displayName,
    yColumnNames: tableColumns
      ?.filter(column => column?.type === "NUMBER")
      ?.map(col => col?.displayName),
  });

  useEffect(() => {
    if (isEmpty(initialPlotState)) {
      return;
    }
    setPlotState(initialPlotState);
  }, [JSON.stringify(initialPlotState)]);

  // useEffect(() => {
  //   onChangePlotState(plotState);
  // }, [JSON.stringify(plotState)]);

  const addNewRow = () => {
    const newRow = {};
    tableColumns.forEach(column => {
      newRow[column.dbName] = { value: "" };
    });
    onChangeValue([...(value || []), newRow]);
  };

  const editRow = (rowIndex, columnName, newValue) => {
    const newEditedValue = value.map((row, i) => {
      if (i === rowIndex) {
        return {
          ...row,
          [columnName]: { value: newValue },
        };
      }
      return row;
    });
    onChangeValue(newEditedValue);
  };

  const isCrm = column?.formatType === "CRM_TABLE";

  let plotContent = (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          {tableColumns.map((column, index) => (
            <Th key={index}>{column?.displayName}</Th>
          ))}
          {isEditMode && <Th />}
        </tr>
      </thead>
      <tbody>
        {value?.map((row, rowIndex) => (
          <Tr groupColor={groupColor} key={rowIndex}>
            {tableColumns.map((column, columnIndex) => {
              let value = row?.[column?.dbName]?.value || "";
              if (column?.type === "DATETIME") {
                value = value?.replace("Z", "");
              }

              if (isCrm && columnIndex === 3) {
                return (
                  <Td>
                    <ViewHtmlModal htmlString={value} />
                  </Td>
                );
              }

              return (
                <Td
                  key={`${selectedRecordIdStr}-${column?.displayName}-${rowIndex}-${columnIndex}`}
                >
                  <InputAndRef>
                    <RecordFieldInput
                      isInline
                      value={value}
                      onChange={e =>
                        editRow(rowIndex, column?.dbName, e.target.value)
                      }
                      column={column}
                      isEditMode={isEditMode}
                      style={cellStyle}
                    />
                    <StyledFieldReferenceModal
                      isDisabled={!row?.[column.dbName]?.reference}
                      column={column}
                      field={row?.[column?.dbName]}
                      reference={row?.[column.dbName]?.reference}
                      hideLeftContent={true}
                      extraParams={extraParams}
                    >
                      <EyeWithDocIcon
                        style={{
                          fill: row?.[column.dbName]?.reference
                            ? groupColor
                            : "#ccc",
                          marginTop: 2,
                        }}
                      />
                    </StyledFieldReferenceModal>
                    <ReferenceDebugModal
                      reference={row?.[column.dbName]?.reference}
                    />
                  </InputAndRef>
                </Td>
              );
            })}
            {isEditMode && (
              <Td>
                <StyledTrashIcon
                  onClick={() =>
                    onChangeValue(value.filter((row, i) => i !== rowIndex))
                  }
                />
              </Td>
            )}
          </Tr>
        ))}
        <Tr disabled={!isEditMode}>
          <StyledPlusIcon style={{ margin: 4 }} onClick={addNewRow} />
        </Tr>
      </tbody>
    </table>
  );

  const { plotType } = plotState;
  const plotProps = {
    params: plotState,
    isEditing: isEditMode,
    onChangeParams: newParams => onChangePlotState(newParams),
    tableColumns,
    records: value || [],
  };
  if (plotType !== "Table") {
    plotContent = getPlotComponent(plotType, plotProps);
  }

  let leftButtons = <div style={{ height: 14, gridColumn: "span 3" }} />;
  if (isEditMode) {
    leftButtons = (
      <div
        style={{
          gridColumn: "span 3",
          display: "grid",
          gridTemplateColumns: "1fr auto",
        }}
      >
        <StyledSelect
          style={{ justifySelf: "start", marginBottom: 4 }}
          value={plotType}
          onChange={e =>
            onChangePlotState({ ...plotState, plotType: e.target.value })
          }
        >
          {PLOT_TYPES.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </StyledSelect>
      </div>
    );
  }

  return (
    <TableContainer
      style={{
        gridTemplateColumns: isEditMode ? "1fr auto auto" : "1fr auto",
      }}
    >
      {leftButtons}
      <TableScroller>{plotContent}</TableScroller>
    </TableContainer>
  );
};

export default RecordFieldInputTable;
