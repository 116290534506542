import {
  deleteCustomApps,
  getCustomApps,
  getPipelineConfig,
  postCustomApps,
} from "api/backend/fileSystemEndpoints";
import ChatViewNew from "components/ChatViewNew";
import EditConfigModal from "components/EditConfigModal";
import GridDraggable from "components/GridDraggable";
import { ChatIcon, MoveIcon } from "components/IconsNew";
import LayoutAppConfigurable from "components/LayoutAppConfigurable";
import LayoutNew from "components/LayoutNew";
import RecordsTableComponent from "components/RecordsTableComponent";
import ButtonWord from "components/ui/ButtonWord";
import { cloneDeep, isEmpty, merge, set } from "lodash";
import {
  CUSTOM_APPS,
  EMPTY_PAGE,
  SHOULD_USE_CONFIG_FROM_CODE,
} from "pages/apps-new/config";
import { AggregationComponent } from "pages/apps/dashboard";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { parseJson, uuidv4 } from "utils/common";

const GradientSpan = styled.span`
  background: linear-gradient(88.57deg, ${COLOR1} 0%, ${COLOR2} 10%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  height: 100%;
  overflow: hidden;
  align-content: start;
`;

const StyledInput = styled.input`
  padding: 8px 16px;
  /* width: 800px; */
  width: 100%;
  font-weight: 400;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
`;

const ToggleDiv = styled.div`
  border: 1px solid #424242;
  color: #424242;
  padding: 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: white;
  svg {
    fill: #424242;
  }
  :hover {
    color: ${props => props.theme.color.primary};
    border-color: ${props => props.theme.color.primary};
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }

  ${props =>
    props.isSelected &&
    `
    background-color: #424242;
    color: white;
    svg {
      fill: white;
    }
    `}
`;

const TitleBar = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  white-space: nowrap;
  height: 100%;
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const TitleComponentContainer = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  // border: 1px solid #dedede;
  height: 100%;
`;

const GridContainer = styled.div`
  height: 100%;
  /* background: linear-gradient(180deg, #f3f5f7 0%, #fdfdfd 100%); */
  border-left: 1px solid #e8ecef;
  overflow: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

// Jinli: CSS for adjusting the side chat
const Slideout = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: ${props => (props?.isOpen ? "10px" : 0)};
  height: calc(100vh - 40px); // overall slide-out window height
  width: ${props => (props.isOpen ? "300px" : "0px")};
  transition: width 0.2s;
  box-shadow: 0px 4px 12.6px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-bottom-right-radius: 24px;
  z-index: 10;
`;

const FixedButton = styled.div`
  position: absolute;
  transform: translateX(-100%);
  background-color: white;
  top: 20px; // slide-out button's relative position
  height: 40px;
  width: 40px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
  border: 1px solid #e0e0e0;
  border-right: none;
`;

const getInitialAppLayoutConfig = (pipelineId, shouldUseCodeConfig) => {
  if (!shouldUseCodeConfig) {
    // get local storage version to prevent flicker when switching between app pages
    const appLayoutConfigStr = localStorage?.getItem(
      `appLayoutConfig-${pipelineId}`
    );

    let appLayoutConfig = {};
    try {
      appLayoutConfig = JSON.parse(appLayoutConfigStr);
    } catch (e) {}

    return appLayoutConfig || {};
  }

  const configFromCode =
    CUSTOM_APPS?.find(app => app?.pipelineId === pipelineId)?.config || {};
  return configFromCode || {};
};

export const useAppLayoutConfig = pipelineId => {
  const [config, setConfig] = useState(
    getInitialAppLayoutConfig(pipelineId, SHOULD_USE_CONFIG_FROM_CODE)
  );

  useEffect(() => {
    if (SHOULD_USE_CONFIG_FROM_CODE) {
      return;
    }
    doPopulateConfig();
  }, [pipelineId]);

  const doPopulateConfig = async () => {
    if (!pipelineId) {
      return;
    }
    // fetches config from BE
    const { data } = await getCustomApps({ pipelineId });

    // converts string to json
    let configJson = {};
    try {
      configJson = JSON.parse(data?.[0]?.config);
    } catch {}
    setConfig(configJson);
  };

  const updateConfig = (slug, fieldName, fieldValue) => {
    setConfig(prevConfig => {
      const newConfig = cloneDeep(prevConfig);
      set(newConfig, `slugToPage.${slug}.${fieldName}`, fieldValue);

      localStorage.setItem(
        `appLayoutConfig-${pipelineId}`,
        JSON.stringify(newConfig)
      );

      return newConfig;
    });
  };

  const updateComponent = (slug, componentId, newFields) => {
    setConfig(prevConfig => {
      const newConfig = cloneDeep(prevConfig);

      const newComponents = newConfig?.slugToPage?.[slug]?.components?.map(
        component => {
          if (component.id === componentId) {
            return merge(component, newFields);
          }
          return component;
        }
      );
      newConfig.slugToPage[slug].components = newComponents;

      localStorage.setItem(
        `appLayoutConfig-${pipelineId}`,
        JSON.stringify(newConfig)
      );

      return newConfig;
    });
  };

  const deleteComponent = (slug, componentId) => {
    setConfig(prevConfig => {
      const newConfig = cloneDeep(prevConfig);

      const newComponents = newConfig?.slugToPage?.[slug]?.components?.filter(
        component => component.id !== componentId
      );
      newConfig.slugToPage[slug].components = newComponents;

      localStorage.setItem(
        `appLayoutConfig-${pipelineId}`,
        JSON.stringify(newConfig)
      );

      return newConfig;
    });
  };

  return [config, updateConfig, updateComponent, deleteComponent];
};

const ComponentTypePicker = ({ onPressApply = newType => {} }) => {
  const [type, setType] = useState("table");

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        width: "100%",
        height: "100%",
        display: "grid",
        alignContent: "start",
        padding: 20,
        border: "1px solid #dedede",
        borderRadius: 8,
        gap: 20,
      }}
    >
      <select value={type} onChange={e => setType(e.target.value)}>
        <option value="table">Records Table</option>
        <option value="analytics">Analytics</option>
        <option value="title">Title</option>
        <option value="search-bar">Search bar</option>
      </select>
      <ButtonWord onClick={() => onPressApply(type)}>Apply</ButtonWord>
    </div>
  );
};

const AppPageConfigurable = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { pipelineConfigId, slug } = useParams();

  const [pipelineConfig, setPipelineConfig] = useState(null);
  const [query, setQuery] = useState("");
  const [isEditingLayout, setIsEditingLayout] = useState(false);
  const [isHighlightSave, setIsHighlightSave] = useState(false);

  const [appLayoutConfig, updateConfig, updateComponent, deleteComponent] =
    useAppLayoutConfig(pipelineConfigId);
  const pageConfig = appLayoutConfig?.slugToPage?.[slug] || EMPTY_PAGE;

  const { components, layout, bgColor, width, height } = pageConfig;

  const openSidebarType = parseJson(searchParams?.get("openSidebarType"));
  const isSideBarOpen = !!openSidebarType && !isEmpty(openSidebarType);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isSavingConfig, setIsSavingConfig] = useState(false);

  useEffect(() => {
    doPopulatePipelineConfig();
  }, [pipelineConfigId]);

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      navigate(`/apps/${pipelineConfigId}/search?query=${query}&type=RECORD`);
    }
  };

  const setAndPersistColor = newColor => {
    updateConfig(slug, "bgColor", newColor);
  };

  const setAndPersistLayout = newLayout => {
    updateConfig(slug, "layout", newLayout);
  };

  const onClickEmptyCell = ({ x, y }, e) => {
    const type = "picker";
    const id = uuidv4();

    updateConfig(slug, "components", [...components, { id, type }]);

    const newLayout = cloneDeep(layout);
    newLayout[id] = {
      x,
      y,
      w: 20,
      h: 20,
    };

    setAndPersistLayout(newLayout);
  };

  const onClickSaveConfig = async newConfig => {
    setIsSavingConfig(true);
    const payload = {
      pipelineId: pipelineConfigId,
      config: JSON.stringify(newConfig),
    };
    const { data: existingConfigs } = await getCustomApps({
      pipelineId: pipelineConfigId,
    });
    await deleteCustomApps(existingConfigs?.[0]?.id);
    await postCustomApps({}, payload);
    setIsSavingConfig(false);
    setIsHighlightSave(false);
  };

  const onChangeComponentType = (id, type) => {
    updateComponent(slug, id, { type });
  };

  const onDeleteKey = id => {
    const newLayout = cloneDeep(layout);
    delete newLayout[id];
    setAndPersistLayout(newLayout);

    deleteComponent(slug, id);
  };

  const isEditingConfig = searchParams?.get("edit") === "true";
  const bottomRightButtons = (
    <div
      style={{
        position: "absolute",
        right: 10,
        bottom: 10,
        gap: 10,
        zIndex: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      {isEditingConfig && (
        <EditConfigModal
          config={appLayoutConfig}
          isSaveDisabled={isSavingConfig || SHOULD_USE_CONFIG_FROM_CODE}
          onClickSave={async newConfigStr => {
            const newConfig = JSON.parse(newConfigStr);

            await onClickSaveConfig(newConfig);
            window.location.reload();
          }}
        />
      )}
      {isEditingConfig && (
        <ButtonWord
          disabled={isSavingConfig || SHOULD_USE_CONFIG_FROM_CODE}
          onClick={() => onClickSaveConfig(appLayoutConfig)}
          style={{ borderColor: isHighlightSave ? "red" : null }}
        >
          Save
        </ButtonWord>
      )}

      {isEditingLayout && (
        <input
          value={bgColor}
          onChange={e => setAndPersistColor(e.target.value)}
          type="color"
        />
      )}
      {isEditingConfig && (
        <ToggleDiv
          isSelected={isEditingLayout}
          onClick={() => setIsEditingLayout(!isEditingLayout)}
        >
          <MoveIcon />
          Rearrange
        </ToggleDiv>
      )}
    </div>
  );

  let LayoutComponent = LayoutAppConfigurable;
  // Jinli: To allow apps to use global sidebar. may not be needed.
  // let customApp = CUSTOM_APPS?.find(
  //   app => app?.pipelineId === pipelineConfigId
  // );
  // if (customApp?.shouldUseGlobalSidebar) {
  //   LayoutComponent = LayoutNew;
  // }

  const onClickDuplicate = component => {
    // console.log({ component, components, layout });
    // create new component and its layout
    const originalLayout = layout[component.id];
    const newId = uuidv4();
    const newComponent = cloneDeep(component);
    const newLayout = cloneDeep(layout);
    newComponent.id = newId;
    const newComponents = [...components, newComponent];
    newLayout[newId] = {
      x: originalLayout.x + 3,
      y: originalLayout.y + 3,
      w: originalLayout.w,
      h: originalLayout.h,
    };

    // console.log({ newComponent, newComponents, newLayout });
    // insert into data structure
    updateConfig(slug, "components", newComponents);
    setAndPersistLayout(newLayout);
  };

  return (
    <LayoutComponent>
      <Container style={{ backgroundColor: bgColor }}>
        <GridContainer>
          <GridDraggable
            // initialLayout={pipelineConfig?.meta?.dashboardLayout || {}}
            initialLayout={layout}
            isEditingDisabled={!isEditingLayout}
            onDragEnd={setAndPersistLayout}
            areItemsRemovable
            onDeleteKey={onDeleteKey}
            onClickEmptyCell={onClickEmptyCell}
            style={{
              width: `${width || 2000}px`,
              height: `${height || 1300}px`,
            }}
            tooltipMsg="click = table, cmd+click = analytics"
          >
            {components?.map(component => {
              if (component?.type === "title") {
                return (
                  <TitleComponentContainer key={component?.id}>
                    <TitleBar>
                      <TitleText>
                        <GradientSpan>{pipelineConfig?.name}</GradientSpan>{" "}
                        records
                      </TitleText>
                    </TitleBar>
                  </TitleComponentContainer>
                );
              }

              if (component?.type === "search-bar") {
                return (
                  <TitleComponentContainer key={component?.id}>
                    <StyledInput
                      value={query}
                      onChange={e => setQuery(e.target.value)}
                      onKeyDown={onKeyDown}
                      placeholder="Search records..."
                    />
                  </TitleComponentContainer>
                );
              }

              if (component?.type === "picker") {
                return (
                  <ComponentTypePicker
                    id={component?.id}
                    key={component?.id}
                    onPressApply={newType =>
                      onChangeComponentType(component?.id, newType)
                    }
                  />
                );
              }

              if (component?.type === "analytics") {
                return (
                  <AggregationComponent
                    pipelineConfig={pipelineConfig}
                    aggComponent={component}
                    key={component?.id}
                    onSaveComponent={newFields =>
                      updateComponent(slug, component?.id, newFields)
                    }
                    onClickDuplicate={() => {
                      onClickDuplicate(component);
                      // hightlight save button for saving reminder
                      setIsHighlightSave(true);
                    }}
                    onClickDelete={() => {
                      onDeleteKey(component.id);
                      setIsHighlightSave(true);
                    }}
                  />
                );
              }

              return (
                <RecordsTableComponent
                  isEditingLayout={isEditingLayout}
                  config={component?.config}
                  onNewConfig={newConfig => {
                    updateComponent(slug, component?.id, { config: newConfig });
                  }}
                  id={component.id}
                  key={component.id}
                />
              );
            })}
          </GridDraggable>
        </GridContainer>
        {bottomRightButtons}

        <Slideout
          isOpen={isChatOpen}
          style={{ display: pageConfig?.disableSideChat ? "none" : "block" }}
        >
          <FixedButton onClick={() => setIsChatOpen(prev => !prev)}>
            <ChatIcon />
          </FixedButton>
          {isChatOpen && (
            <ChatViewNew
              genContext="free-page"
              isSmallInput
              pipelineConfigId={pipelineConfigId}
              pageConfig={pageConfig}
            />
          )}
        </Slideout>
      </Container>
    </LayoutComponent>
  );
};

export default AppPageConfigurable;
