import { FileCopy, Replay, ThumbDown, ThumbUp } from "@material-ui/icons";
import { cloneDeep, isEmpty, last, range } from "lodash";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import hljs from "highlight.js";
import "highlight.js/styles/vs2015.css";
import { Marked } from "marked";
import { markedHighlight } from "marked-highlight";

import {
  apiPostRawResponse,
  postAndStreamResponse,
} from "api/api-http-methods";
import { getLoggedInUserName } from "api/services/authenticationService";
import { getUserProfileByUserName } from "api/services/projectService";
import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import AddUrlToSourceButton from "components/AddUrlToSourcesModal";
import ChainOfThoughtSteps from "components/ChainOfThoughtSteps";
import ChatTablePlotModal from "components/ChatTablePlotModal";
import { AnchorsIcon, ArrowUpIcon, PersonBlankIcon } from "components/IconsNew";
import InputWithBottomSuggestionsAndSources, {
  BG_INPUT,
} from "components/InputWithBottomSuggestionsAndSources";
import ReferenceModalSpanWithContext, {
  getMetaId,
} from "components/ReferenceModalSpanWithContext";
import SourcesCards from "components/SourcesCards";
import TextAreaExpanding from "components/TextAreaExpanding";
import {
  BoltzhubLogoInner,
  CrossIcon,
  InternetIcon,
  PdfIcon,
  PencilIcon,
  RoundTickIcon,
} from "components/ui/Icons";
import TooltipNew from "components/ui/TooltipNew";
import { GreyText } from "pages/chat";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useRef } from "react";
import { sleep } from "utils/common";
import {
  addStyleToBlocks,
  getSelectionFromBlocks,
  removeSelectionStyle,
} from "utils/word-utils";
import { Gap } from "./Layout";

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: grid;
  padding-top: 0px;
  height: 100%;
  grid-template-rows: 1fr auto auto;
  transition: width 0.2s;
`;

const InputContainer = styled.div`
  position: relative;
  max-width: 800px;
  width: 100%;
`;

const FadeRect = styled.div`
  position: absolute;
  width: 100%;
  height: 40px;
  /* background: linear-gradient(transparent, white); */
  z-index: 0;
  pointer-events: none;
  bottom: calc(20px + 71px);
`;

const ProfilePicContainer = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.furthest};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.primary};
`;

/*
{
    "columnIndex": 0,
    "columns": null,
    "isQuery": false,
    "isTableCell": true,
    "numberOfColumns": 2,
    "numberOfRows": 4,
    "queryId": "672a41e2950400177f6bec2d",
    "records": null,
    "referenceId": "d79ddfb0-89f9-4f97-9b85-b1922d23a494",
    "regenActions": null,
    "rowIndex": 3,
    "sourceCodes": null,
    "styles": [
        {
            "citations": null,
            "end": 3,
            "metas": [
                {
                    "blocksReferenceId": "4d82c247-3aab-48e0-b1c5-d0400ba8ffbf",
                    "fileId": "",
                    "fileType": "IN_DOC",
                    "llmPromptReplies": null,
                    "referenceUrls": null,
                    "references": null
                }
            ],
            "start": 2,
            "value": " "
        }
    ],
    "tableId": "4930036d-fa57-4c63-bff9-e46fdf6e980b",
    "text": " Man Group (Q4 2024 Credit Outlook)  "
}
*/
const CrossContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 18px;
  cursor: pointer;
  z-index: 2;
`;

const Blocks = styled.div`
  margin-top: 50px;
  padding-bottom: 50px;
  min-width: 280px;
  width: 100%;
  max-width: 800px;
`;

const BlocksScroller = styled.div`
  width: 100%;
  overflow-y: auto;

  display: grid;
  justify-items: center;
`;

const TwoColumns = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 10px;
  margin: 0;

  animation: none;
  border-radius: 2px;
  @keyframes pulse {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: #0191ff22;
    }
    100% {
      background-color: transparent;
    }
  }
`;

const RightContent = styled.div`
  overflow: auto;
  grid-column: 2;
  line-height: 1.5;
`;

const BlockContent = styled.div`
  border-radius: 4px;
  padding: 4px;

  white-space: pre-wrap;
  code {
    border-radius: 4px;
    font-family: monospace;
  }
`;

const Actions = styled.div`
  min-height: 24px;
  margin-bottom: 16px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 8px;
  margin-top: 4px;

  @media (max-width: 1000px) {
    opacity: 0;
    pointer-events: none;
  }
`;

const StyledRoundTickIcon = styled(RoundTickIcon)`
  cursor: pointer;
  margin-left: 2px;
  fill: #00c85355;
  :hover {
    fill: #0191ff;
  }
`;

const GreyRect = styled.div`
  height: 14px;
  align-self: center;
  border-radius: 4px;

  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 30%,
      rgba(255, 255, 255, 0) 80%
    ),
    #eaeaea;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: -20 0;
  animation: shine 1s infinite;

  @keyframes shine {
    to {
      background-position: 100% 0, /* move highlight to right */ 0 0;
    }
  }
`;

const Td = styled.td`
  padding: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
  max-width: 250px;
`;

const Table = styled.table`
  width: max-content;
`;

const TableContainer = styled.div`
  position: relative;
  padding-left: 0px;
  width: 100%;
  overflow: auto;
  margin: 12px 0;
`;

const StyledInput = styled(TextAreaExpanding)`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-radius: 0;
  outline: none;
  background: transparent;
  border: 1px solid transparent;
  width: 100%;
  height: max-content;
  max-height: 200px;
  resize: none;
  line-height: 1.4;
  border: 1px solid #c7c7c7;
  :disabled {
    color: black;
  }
`;

const SendButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 10px;
  background-color: #497ff3;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: #3f6cc7;
  }
  :disabled {
    background-color: #c2c2c2;
    cursor: default;
  }
`;

const StyledBubbleSendButton = styled(SendButton)`
  transition: opacity 0.2s;
  fill: #848484;
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    fill: #3e3e3e;
  }
`;

const WhiteRect = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: white;
`;

const StyledBottomSendButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 10px;
  background-color: #497ff3;
  border: none;
  fill: #848484;
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 0;

  :hover {
    fill: #3e3e3e;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  height: 14px;
  cursor: pointer;
  fill: white;
`;

const EditButtons = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 4px;
  margin-bottom: 2px;
  justify-self: end;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const StyledFileCopy = styled(FileCopy)`
  opacity: 0.5;
  cursor: pointer;
  width: 16px !important;
  height: 16px !important;
  :hover {
    opacity: 1;
  }
`;

const StyledArrowUpIcon = styled(ArrowUpIcon)`
  background-color: #ff9561;
  border-radius: 50%;
  cursor: pointer;
  padding: 1px;
  width: 12px;
  height: 12px;
  stroke: black !important;
  :hover {
    opacity: 0.6;
  }
`;

const StyledReplay = styled(Replay)`
  opacity: 0.5;
  cursor: pointer;
  width: 16px !important;
  height: 16px !important;
  :hover {
    opacity: 1;
  }
`;

const InputArea = styled.div`
  position: relative;
  padding-bottom: 0px;
  background-color: ${props => props.theme.color.furthest};
  padding: 0px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

const StyledSmallInput = styled.input`
  padding: 8px 16px;
  padding-right: 32px;
  border: none;
  width: 100%;
  outline: none;
  border: 1px solid ${props => props.theme.color.closer1_5};
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 4px;
  resize: none;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;
`;

const SendEmailContainer = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const ResetContainer = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
  opacity: 0;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

const TipIcons = styled.div`
  display: flex;
  gap: 4px;
  padding-top: 12px;
  svg {
    cursor: pointer;
    :hover {
      opacity: 0.5;
    }
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 14px;
  cursor: pointer;

  position: absolute;
  right: 20px;
  top: 10px;

  :hover {
    opacity: 0.5;
  }
`;

const TickboxLabel = styled.label`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: -22px;
  background-color: #eaeaea;
  border-radius: 4px;
  padding: 0px 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
`;

const SuggestionsContainer = styled.div`
  border: 1px solid grey;
  border: none;
  width: 100%;
  outline: none;
  border: 1px solid ${props => props.theme.color.closer1_5};
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 4px;
  resize: none;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;

  padding: 6px 0px;
`;

const Suggestion = styled.div`
  padding: 8px;
  :hover {
    background-color: lightgrey;
  }

  padding: 8px 16px;
  padding-right: 32px;
`;

const StyledLink = styled.a`
  margin: 0 2px;
`;

const Urls = styled.div`
  padding: 20px 0;
  display: grid;
  gap: 2px;
`;

const FlexRow = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-content: baseline;
`;

const UrlLabel = styled.div`
  font-family: monospace;
  font-weight: 600;
  font-size: 12px;
`;

const UrlItem = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr 24px;
  gap: 8px;
  row-gap: 2px;
  align-items: center;
  background-color: #eaeaea;
  padding: 4px;
  border-radius: 4px;
`;

const UrlLink = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAnchorsIcon = styled(AnchorsIcon)`
  cursor: pointer;
  margin: 4px 0px;
  fill: ${props => (props.isEnabled ? "#0191ff" : "gray")};
  :hover {
    fill: #0191ff;
  }
`;

const UrlDescription = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Items = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: 500;
`;

const FaviconImg = styled.img`
  height: 14px;
`;

const BlockLoadingState = () => (
  <div style={{ display: "grid", gap: "5px", marginBottom: 5 }}>
    <GreyRect />
    <GreyRect />
    <GreyRect style={{ width: "50%" }} />
  </div>
);

const BOT_PIC = (
  <ProfilePicContainer>
    <BoltzhubLogoInner style={{ fill: "#0191ff" }} height="12px" />
  </ProfilePicContainer>
);

const getProfilePic = (blocks, index, userProfile = {}) => {
  const block = blocks?.[index];
  const prevBlock = blocks?.[index - 1];

  let leftContent = <div />;
  if (!block?.isQuery && prevBlock?.isQuery) {
    const steps = prevBlock?.chainOfThought?.steps;
    if (!steps?.length) {
      leftContent = BOT_PIC;
    } else if (steps?.length === 1 && steps[0].type !== "think") {
      leftContent = BOT_PIC;
    }
  }

  if (block?.isQuery && !prevBlock?.isQuery) {
    leftContent = (
      <ProfilePicContainer>
        {userProfile?.image ? (
          <Img src={userProfile?.image} />
        ) : (
          <PersonBlankIcon
            style={{ marginTop: "0px", fill: "#b4b4b4" }}
            height="14px"
          />
        )}
      </ProfilePicContainer>
    );
  }

  return leftContent;
};

const getStyleWithMetaIds = style => {
  const newStyle = cloneDeep(style);
  if (!style?.metas) {
    return newStyle;
  }

  newStyle.metas = newStyle.metas.map(meta => {
    return { ...meta, value: style?.value, id: getMetaId(meta, style?.value) };
  });

  return newStyle;
};

export const scrollToBlocks = async (
  blockReferenceIds,
  setSearchParams = () => {}
) => {
  setSearchParams({ highlightReferenceIds: blockReferenceIds?.join(",") });

  // allow time for chain of thought step to expand
  await sleep(100);

  blockReferenceIds?.forEach(blockRefId => {
    const blockElements = document.querySelectorAll(
      `[data-ref-id="${blockRefId}"]`
    );

    blockElements?.forEach(blockElement => {
      blockElement.style.setProperty("animation", "1s pulse 2");
      setTimeout(() => {
        blockElement.style.removeProperty("animation");
      }, 2000);
    });
  });

  const firstBlockElement = document.querySelector(
    `[data-ref-id="${blockReferenceIds?.[0]}"]`
  );
  firstBlockElement.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};

const getUrlToLabelMap = block => {
  let allUrls = [];
  let allTitles = [];
  let allDescriptions = [];
  let allFaviconUrls = [];

  block?.styles?.forEach(style => {
    style?.metas?.forEach(meta => {
      if (meta?.referenceUrls) {
        allTitles = [...allTitles, ...(meta?.referenceUrlTitles || [])];
        allUrls = [...allUrls, ...(meta?.referenceUrls || [])];
        allDescriptions = [
          ...allDescriptions,
          ...(meta?.referenceUrlDescriptions || []),
        ];
        allFaviconUrls = [
          ...allFaviconUrls,
          ...(meta?.referenceUrlFavicons || []),
        ];
      }
    });
  });

  // link url and titles
  let urlToLabelMap = {};
  allUrls?.forEach((url, index) => {
    urlToLabelMap[url] = {
      title: allTitles?.[index],
      description: allDescriptions?.[index],
      faviconUrl: allFaviconUrls?.[index],
    };
  });

  // add labels counting up
  Object.keys(urlToLabelMap).forEach((url, index) => {
    urlToLabelMap[url] = {
      ...(urlToLabelMap?.[url] || {}),
      label: `[${index + 1}]`,
    };
  });

  return urlToLabelMap;
};

const getRetrievalList = block => {
  const allFileIds = [];
  const retrievalList = [];

  block?.searchIndexReferences?.forEach(ref => {
    if (ref?.fileId && !allFileIds.includes(ref?.fileId)) {
      allFileIds.push(ref?.fileId);
      retrievalList.push({
        label: `[${allFileIds.length}]`,
        fileId: ref.fileId,
        fileName: ref?.fileName,
        url: `${window.location.origin}/files/${ref.fileId}`,
      });
    }
  });

  return retrievalList;
};

const addMdStyling = text => {
  if (!text) {
    return text;
  }

  // bold
  let styledText = text.replace(/\*\*/g, (match, offset, string) => {
    let count = (string.slice(0, offset).match(/\*\*/g) || []).length;
    return count % 2 === 0 ? "<strong>" : "</strong>";
  });

  // titles excluding code blocks
  styledText = styledText
    .split(/(```[\s\S]*?```)/g)
    .map(segment => {
      if (/^```/.test(segment)) {
        // Skip processing for code blocks
        return segment;
      }
      return segment.replace(
        /^#+\s*(.+)$/gm,
        (match, titleContent) =>
          `<span style="font-size: 20px; font-weight: 600">${titleContent}</span>`
      );
    })
    .join("");

  // replace ```...``` with code block
  styledText = styledText?.replaceAll(/```([\s\S]*?)```/g, (match, code) => {
    const marked = new Marked(
      markedHighlight({
        emptyLangClass: "hljs",
        langPrefix: "hljs language-",
        highlight(code, lang, info) {
          const language = hljs.getLanguage(lang) ? lang : "plaintext";
          return hljs.highlight(code, { language }).value;
        },
      })
    );

    // TODO: replaceAll, because backticks (`) inside code blocks cause problems with inline code
    return marked.parse("```" + code?.replaceAll("`", "") + "```");
  });

  // inline code
  styledText = styledText.replace(
    /(^|[^`])`([^`]+)`([^`]|$)/g,
    (match, before, codeContent, after) =>
      `${before}<code>${codeContent}</code>${after}`
  );

  // tables are handled using the blocks data structure
  styledText = styledText.replaceAll("```markdown", "");
  styledText = styledText.replaceAll("`", "");

  // titles
  // styledText = styledText.replace(
  //   /^#+\s*(.+)$/gm,
  //   (match, titleContent) =>
  //     `<span style="font-size: 20px; font-weight: 600">${titleContent}</span>`
  // );
  // styledText = styledText.replace(
  //   /(^|\n)(?<!```.*)\s*#+\s*(.+)$/gm,
  //   (match, prefix, titleContent) =>
  //     `${prefix}<span style="font-size: 20px; font-weight: 600">${titleContent}</span>`
  // );

  return styledText;
};

const Tip = styled.div`
  background-color: white;
  padding: 10px;
  max-width: 400px;
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;

const DownButton = styled.button`
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  border-radius: 50%;
  padding: 4px;
  margin: 0;
  background-color: #ffffff;
  border: 1px solid #c7c7c7;
  cursor: pointer;

  :hover {
    background-color: #f7f7f7;
  }

  svg {
    stroke: black;
    height: 14px;
    width: 14px;
  }
`;

const getTipContent = (blocks, metas) => {
  let content = "";

  const chainOfThoughtBlocks = blocks
    ?.map(block => {
      const cotBlocks =
        block?.chainOfThought?.steps?.map(step => step?.ansBlocks)?.flat() ||
        [];
      return cotBlocks;
    })
    ?.flat();

  const allBlocks = [...blocks, ...chainOfThoughtBlocks];

  metas?.forEach(meta => {
    const targetBlock = allBlocks?.find(
      b => b?.referenceId === meta?.blocksReferenceId
    );
    const textSpan =
      targetBlock?.text?.slice(meta?.blockStart, meta?.blockEnd) || "";
    content += textSpan;
  });

  return content;
};

const ClickableThumbs = ({ direction = "up" }) => {
  const [isApproved, setIsApproved] = useState(null);

  const upStyle = {
    fill: isApproved === true ? "#0191FF" : "#848484",
  };
  const downStyle = {
    fill: isApproved === false ? "#fa8b85" : "#848484",
  };

  return (
    <TipIcons>
      <ThumbUp
        onClick={() => setIsApproved(true)}
        style={upStyle}
        fontSize="small"
      />
      <ThumbDown
        onClick={() => setIsApproved(false)}
        style={downStyle}
        fontSize="small"
      />
    </TipIcons>
  );
};

export const renderWithReferences = (
  block,
  setSearchParams = () => {},
  blocks = []
) => {
  let segments = [{ text: "" }];

  let i = 0;
  let isRef = false;
  let isInCodeBlock = false;
  while (i <= block?.text?.length) {
    if (block.text.slice(i + 1, i + 4) === "```") {
      isInCodeBlock = !isInCodeBlock;
    }

    const refSpan = block?.referenceSpans?.find(
      ([start, end]) => i >= start && i <= end
    );
    if (refSpan && !isRef) {
      isRef = true;
      let spanId = `${block?.referenceId}_${refSpan?.[0]}_${refSpan?.[1]}`;
      last(segments).text += `<span data-ref-id=${spanId}>`;
    }
    if (!refSpan && isRef) {
      isRef = false;
      last(segments).text += `</span>`;
    }

    const table = block?.tables?.find(
      table => i >= table?.start && i <= table?.end
    );
    if (table) {
      if (i === table?.start) {
        segments.push({ text: "", tableBlocks: table?.blocks });
      }
      if (i === table?.end) {
        segments.push({ text: "" });
      }
      i++;
      continue;
    }

    const styleEndingHere = block?.styles?.find(style => style?.end === i);
    if (styleEndingHere && !isInCodeBlock) {
      segments.push({ style: getStyleWithMetaIds(styleEndingHere) });
      segments.push({ text: "" });
    }

    last(segments).text += block?.text[i] || "";
    i++;
  }

  // add md styles
  segments = segments.map(segment => ({
    ...segment,
    text: addMdStyling(segment?.text),
  }));

  const urlToLabelMap = getUrlToLabelMap(block);

  return segments?.map((segment, index) => {
    const metas = segment?.style?.metas;
    const inDocMetas = metas?.filter(meta => meta?.fileType === "IN_DOC");
    const fileMetas = metas?.filter(meta => meta?.fileType !== "IN_DOC");

    let inDocMetasArrow = null;
    if (inDocMetas?.length) {
      const blockIds = inDocMetas?.map(meta => {
        const { blocksReferenceId, blockStart, blockEnd } = meta;
        return `${blocksReferenceId}_${blockStart}_${blockEnd}`;
      });

      const tooltipContent = getTipContent(blocks, inDocMetas);

      inDocMetasArrow = (
        <TooltipNew
          tipContent={
            <Tip
              dangerouslySetInnerHTML={{
                __html: addMdStyling(tooltipContent),
              }}
            />
          }
        >
          <StyledArrowUpIcon
            onClick={() => scrollToBlocks(blockIds, setSearchParams)}
          />
        </TooltipNew>
      );
    }

    if (metas?.[0]?.referenceUrls && metas?.length === 1) {
      return metas?.[0]?.referenceUrls?.map((url, index) => {
        let faviconUrl = metas?.[0]?.referenceUrlFavicons?.[index];
        let icon = <InternetIcon />;
        if (faviconUrl) {
          icon = <FaviconImg src={faviconUrl} />;
        }

        const title =
          metas?.[0]?.referenceUrlTitles?.[index] || "Internet Source";
        const description = metas?.[0]?.referenceUrlDescriptions?.[index];

        return (
          <TooltipNew
            tipContent={
              <Tip>
                <Items>
                  {icon} {title}
                </Items>
                <div>{description}</div>
                <ClickableThumbs />
              </Tip>
            }
          >
            <StyledLink href={url} target="_blank">
              {urlToLabelMap?.[url]?.label || "(link)"}
            </StyledLink>
          </TooltipNew>
        );
      });
    }

    let fileMetasTick = null;
    if (fileMetas?.length) {
      fileMetasTick = (
        <ReferenceModalSpanWithContext
          isTooltipDisabled
          key={index}
          blocks={[block]}
          clickedMetaId={fileMetas?.[0]?.id}
          metas={fileMetas}
        >
          <StyledRoundTickIcon height="12px" />
        </ReferenceModalSpanWithContext>
      );
    }

    if (fileMetasTick || inDocMetasArrow) {
      return (
        <>
          {inDocMetasArrow}
          {fileMetasTick}
        </>
      );
    }

    if (segment?.tableBlocks) {
      return getTableContent(segment?.tableBlocks, 0, setSearchParams);
    }

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: segment?.text,
        }}
      />
    );
  });
};

export const getTableContent = (blocks, index, setSearchParams = () => {}) => {
  const block = blocks?.[index];
  const numRows = block?.numberOfRows;
  const numCols = block?.numberOfColumns;
  const tableId = block?.tableId;

  return (
    <TableContainer>
      <Table>
        <tbody>
          {range(numRows).map(rowIndex => (
            <tr key={`${tableId}-row-${rowIndex}`}>
              {range(numCols).map(colIndex => {
                const cellBlock = blocks?.find(
                  b =>
                    b?.tableId === tableId &&
                    b?.rowIndex === rowIndex &&
                    b?.columnIndex === colIndex
                );

                return (
                  <Td
                    key={`${tableId}-row-${rowIndex}-col-${colIndex}`}
                    data-ref-id={`${cellBlock?.referenceId || ""}`}
                  >
                    {renderWithReferences(cellBlock, setSearchParams, blocks)}
                  </Td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TERMS_LINKS = (
  <GreyText style={{ padding: "4px 0" }}>
    The model can make mistakes. Check important info.&nbsp;
    <a href="https://boltzbit.com/flow/terms" target="_blank">
      Terms of Services
    </a>
    &nbsp;and&nbsp;
    <a href="https://boltzbit.com/flow/privacy-policy" target="_blank">
      Privacy Policy
    </a>
  </GreyText>
);

const SUGGESTION_TEXTS = ["Fill up this record", "Summarise this record"];

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com";
}
const CHAT_QUERIES_URL = "/bz-api/v1/ai/streamed-chat-queries";

function copyElementToClipboard(elementId) {
  window.getSelection().removeAllRanges();
  let range = document.createRange();
  const element = document.getElementById(elementId);
  range.selectNode(element);

  window.getSelection().addRange(range);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();
}

const getInitialDoc = text => {
  return {
    directoryPath: "/working-files",
    fileName: "",
    isChat: true,
    content: {
      sources: null,
      blocks: [
        {
          text,
          isQuery: true,
          userSources: [],
          styles: [
            {
              isSelection: true,
              start: text?.length,
              end: text?.length,
            },
          ],
        },
      ],
    },
  };
};

// Jinli: reset/clear memory
const resetMemory = async () => {
  const url = `${BASE_URL}/bz-api/v1/memories/clear`;
  const payload = {};
  const response = await apiPostRawResponse(url, {}, payload);
  console.log(response);
};

const ChatViewNew = ({
  wordDocId = "",
  isSmallInput = false,
  genContext = "word_query",
  sigmaRecord = null,
  workingDocs = [],
  pipelineConfigId = "",
  marginTopBlocks = 50,
  // todo: passing both id, and config, find better way
  pipelineConfig = {},
  // for custom app free page
  pageConfig = {},
  excelModel = {},
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [userInput, setUserInput] = useState("");
  const [sources, setSources] = useState(null);
  const [userSources, setUserSources] = useState([]);

  const [wordDoc, setWordDoc] = useState({});
  const [blocks, setBlocks] = useState([]);
  const [promptRecommendations, setPromptRecommendations] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  const [editQueryId, setEditQueryId] = useState(null);
  const [editQueryText, setEditQueryText] = useState("");

  const [shouldSearchInternet, setShouldSearchInternet] = useState(
    searchParams?.get("shouldSearchInternet") === "true"
  );
  const [shouldUseFiles, setShouldUseFiles] = useState(
    searchParams?.get("shouldUseFiles") === "true"
  );
  const [shouldThink, setShouldThink] = useState(
    searchParams?.get("shouldThink") === "true"
  );
  const [shouldUsePipeline, setShouldUsePipeline] = useState(
    pipelineConfigId ? true : false
  );
  const [userProfile, setUserProfile] = useState({});

  const inputRef = useRef();
  const suggestionsRef = useRef();
  const scrollerRef = useRef();
  const [isFocussed, setIsFocussed] = useState(false);
  const [isDownBtnVisible, setIsDownBtnVisible] = useState(false);

  useEffect(() => {
    doPopulateWordDoc();
    if (!wordDocId) {
      doTriggerInitialQuery();
    }
  }, [wordDocId]);

  useEffect(() => {
    doFetchCreatorAvatar();
  }, []);

  useEffect(() => {
    setUserSources(sources);
  }, [JSON.stringify(sources)]);

  const doFetchCreatorAvatar = async () => {
    const userName = getLoggedInUserName();
    const { data } = await getUserProfileByUserName(userName);
    setUserProfile(data);
  };

  useEffect(() => {
    if (isGenerating || !blocks?.length || !wordDocId) {
      return;
    }
    patchWordDoc(wordDocId, {}, { content: { blocks, sources } });
  }, [isGenerating]);

  const doPopulateWordDoc = async () => {
    if (!wordDocId) {
      return;
    }
    const { data, error } = await getWordDoc(wordDocId);
    if (error) {
      return;
    }
    setWordDoc(data);
    let newBlocks = data?.content?.blocks;
    setSources(data?.content?.sources?.length ? data?.content?.sources : null);
    const newUserSources =
      last(newBlocks?.filter(b => b?.isQuery))?.userSources || [];
    setUserSources(newUserSources || []);

    if (!last(newBlocks)?.isQuery) {
      setBlocks(newBlocks);
      return;
    }

    setBlocks([...newBlocks, { text: "", isLoading: true, isThrowAway: true }]);
    onPressEnterInCommandInput(newBlocks, newUserSources || []);
  };

  const onPressSend = () => {
    let payloadUserSources = userSources;
    if (!shouldUseFiles) {
      payloadUserSources = [];
    }

    const blocksWithoutSelection = removeSelectionStyle(blocks);
    const effectiveUserInput = shouldThink
      ? `${userInput} Think deeply.`
      : userInput;
    const payloadBlocks = [
      ...blocksWithoutSelection,
      {
        isQuery: true,
        text: effectiveUserInput,
        userSources: payloadUserSources,
        styles: [
          {
            isSelection: true,
            start: effectiveUserInput.length,
            end: effectiveUserInput.length,
          },
        ],
      },
    ];
    setBlocks([
      ...payloadBlocks,
      { text: "", isLoading: true, isThrowAway: true },
    ]);
    setUserInput("");
    onPressEnterInCommandInput(payloadBlocks);
  };

  const doTriggerInitialQuery = async () => {
    const text = searchParams?.get("query");
    if (!text) {
      return;
    }

    const initialDoc = getInitialDoc(text);

    setWordDoc(initialDoc);
    let newBlocks = initialDoc?.content?.blocks;
    setSources(
      initialDoc?.content?.sources?.length ? initialDoc?.content?.sources : null
    );
    const newUserSources =
      last(newBlocks?.filter(b => b?.isQuery))?.userSources || [];
    setUserSources(newUserSources || []);

    if (!last(newBlocks)?.isQuery) {
      setBlocks(newBlocks);
      return;
    }

    setBlocks([...newBlocks, { text: "", isLoading: true, isThrowAway: true }]);
    onPressEnterInCommandInput(newBlocks, newUserSources || []);
  };

  const stopGeneration = () => {
    abortController.abort();
    setAbortController(new AbortController());
    setIsGenerating(false);
    const newBlocks = cloneDeep(blocks);
    const updatedBlocks = newBlocks
      ?.filter(block => !block?.isThrowAway)
      .map(block => {
        block.isLoading = false;
        return block;
      });
    setBlocks(updatedBlocks);
  };

  const onPressEnterInCommandInput = async (
    payloadBlocks,
    payloadSources = []
  ) => {
    if (isGenerating) {
      return;
    }
    setIsGenerating(true);

    const { startBlockIndex: blockIndex, startLetterIndex: letterIndex } =
      getSelectionFromBlocks(payloadBlocks);

    let sourcesToSend = userSources;
    if (payloadSources?.length > 0) {
      sourcesToSend = payloadSources;
    }
    let newBlockGenContext = genContext;
    if (!shouldUseFiles) {
      sourcesToSend = [];
    } else if (!sourcesToSend?.length) {
      // Jinli: if shouldUseFiles is slected but no particular files are chosen, shall trigger doc_index routine
      newBlockGenContext = `${genContext}_doc_index`;
    }
    if (shouldUsePipeline) {
      sourcesToSend = [
        ...(sourcesToSend || []),
        {
          type: "PIPELINE",
          fileId: pipelineConfigId,
          fileName: pipelineConfig?.name,
        },
      ];
    }

    const payload = {
      cursor: { blockIndex, letterIndex },
      shouldSearchInternet,
      genContext: newBlockGenContext,
      blocks: payloadBlocks?.filter(block => !!block),
      sources: sourcesToSend,
      sigmaRecord,
      workingDocs,
      pageConfig,
      excelModel,
    };

    // Jinli: view chat page submit payload
    await postAndStreamResponse({
      url: `${BASE_URL}${CHAT_QUERIES_URL}`,
      reqBody: payload,
      abortController,
      onDataReceived: data => {
        if (!data?.blocks) {
          return;
        }
        setBlocks(data?.blocks || []);
        setPromptRecommendations(data?.promptRecommendations || []);
      },
    });

    setIsGenerating(false);
  };

  const onKeyDown = e => {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();

      let payloadUserSources = userSources;
      if (!shouldUseFiles) {
        payloadUserSources = [];
      }

      const blocksWithoutSelection = removeSelectionStyle(blocks);
      const effectiveUserInput = shouldThink
      ? `${userInput} Think deeply.`
      : userInput;
      const payloadBlocks = [
        ...blocksWithoutSelection,
        {
          isQuery: true,
          text: effectiveUserInput,
          userSources: payloadUserSources,
          styles: [
            {
              isSelection: true,
              start: userInput.length,
              end: userInput.length,
            },
          ],
        },
      ];
      setBlocks([
        ...payloadBlocks,
        { text: "", isLoading: true, isThrowAway: true },
      ]);
      setUserInput("");
      onPressEnterInCommandInput(payloadBlocks);

      return;
    }
  };

  const doRerunBlock = async (index, blocksToUse = [], wipeSteps = false) => {
    if (blocksToUse?.length === 0) {
      blocksToUse = blocks;
    }
    const blocksToUseCopy = cloneDeep(blocksToUse);

    const block = blocksToUseCopy?.[index];
    if (wipeSteps && block?.chainOfThought) {
      block.chainOfThought = null;
    }
    const blocksWithoutSelection = removeSelectionStyle(blocksToUseCopy);
    const blocksWithSelection = addStyleToBlocks({
      blocks: blocksWithoutSelection,
      startBlockIndex: index,
      startLetterIndex: block?.text?.length,
      endBlockIndex: index,
      endLetterIndex: block?.text?.length,
      styleFields: {
        isSelection: true,
      },
    });

    onPressEnterInCommandInput(blocksWithSelection, block?.userSources || []);

    let blocksWithLoading = cloneDeep(blocksToUse);
    blocksWithLoading[index + 1].isLoading = true;
    blocksWithLoading = blocksWithLoading.filter(
      oldBlock =>
        oldBlock?.isLoading ||
        oldBlock?.queryId !== block?.queryId ||
        oldBlock?.isQuery
    );
    setBlocks(blocksWithLoading);
  };

  const scrollToBottom = () => {
    const element = scrollerRef?.current;
    if (!element) {
      return;
    }
    element.scrollTop = element.scrollHeight;
    updateDownBtnVisibility();
  };

  const updateDownBtnVisibility = () => {
    const s = scrollerRef?.current;

    const scrollTop = s?.scrollTop;
    const diff = s?.scrollHeight - s?.offsetHeight;

    setIsDownBtnVisible(scrollTop < diff - 10);
  };

  const getActions = (blocks, index) => {
    const block = blocks?.[index];
    if (block?.isQuery) {
      const onClickSend = () => {
        const newBlocks = cloneDeep(blocks);
        newBlocks[index].text = editQueryText;
        setBlocks(newBlocks);
        setEditQueryText("");
        setEditQueryId(null);
        doRerunBlock(index, newBlocks, true);
      };

      let editButtons = (
        <EditButtons>
          {editQueryId !== block?.queryId && (
            <StyledBubbleSendButton
              onClick={() => {
                setEditQueryId(block?.queryId);
                setEditQueryText(block?.text);
              }}
              disabled={editQueryId === block?.queryId}
            >
              <StyledPencilIcon />
            </StyledBubbleSendButton>
          )}

          {editQueryId === block?.queryId && (
            <StyledBubbleSendButton
              onClick={() => {
                setEditQueryId(null);
                setEditQueryText("");
              }}
              disabled={editQueryId !== block?.queryId}
            >
              <CrossIcon style={{ fill: "white" }} />
            </StyledBubbleSendButton>
          )}

          <StyledBubbleSendButton
            onClick={onClickSend}
            disabled={editQueryId !== block?.queryId}
          >
            <ArrowUpIcon height="14px" />
          </StyledBubbleSendButton>
        </EditButtons>
      );

      return <Actions style={{ justifyContent: "end" }}>{editButtons}</Actions>;
    }

    const nextBlock = blocks?.[index + 1];

    const rerunQuery = () => {
      const queryBlockIndex = blocks
        ?.slice(0, index)
        ?.findLastIndex(qBlock => qBlock?.isQuery);
      doRerunBlock(queryBlockIndex, blocks, true);
    };

    if (nextBlock?.isQuery !== block?.isQuery) {
      return (
        <Actions>
          <TooltipNew tipText="Re-generate">
            <StyledReplay onClick={rerunQuery} />
          </TooltipNew>
          {/* <TooltipNew tipText="Copy"> */}
          <StyledFileCopy
            onClick={() => copyElementToClipboard(block?.queryId)}
          />
          {/* </TooltipNew> */}
        </Actions>
      );
    }

    return null;
  };

  let suggestionTexts = [];
  if (genContext === "record_page") {
    suggestionTexts = SUGGESTION_TEXTS;
  }

  const inputRect = inputRef?.current?.getBoundingClientRect();
  const popoverStyle = {
    display:
      isFocussed && !isGenerating && suggestionTexts?.length ? "block" : "none",
    position: "fixed",
    top: inputRect?.top,
    transform: "translateY(-100%)",
    width: 280,
    zIndex: 2,
  };

  let inputElement = (
    <InputContainer style={{ justifySelf: "center" }}>
      <DownButton
        onClick={scrollToBottom}
        style={{
          display: isDownBtnVisible ? "flex" : "none",
          left: "50%",
          top: -28,
          transform: "translateX(-50%)",
        }}
      >
        <ArrowUpIcon style={{ transform: "rotate(180deg)" }} />
      </DownButton>
      {isGenerating && (
        <StyledBubbleSendButton
          style={{
            position: "absolute",
            right: 16,
            top: 10,
            zIndex: 2,
          }}
          onClick={stopGeneration}
        >
          <WhiteRect />
        </StyledBubbleSendButton>
      )}
      {!isGenerating && (
        <StyledBottomSendButton
          style={{
            position: "absolute",
            right: 16,
            top: 10,
            zIndex: 2,
          }}
          onClick={onPressSend}
        >
          <ArrowUpIcon height="14px" />
        </StyledBottomSendButton>
      )}
      <InputWithBottomSuggestionsAndSources
        blocks={blocks}
        id="input"
        placeholder={"Ask a question"}
        value={userInput}
        sources={sources}
        setSources={setSources}
        onChange={e => setUserInput(e.target.value)}
        onClickSuggestion={text => setUserInput(text)}
        onKeyDown={onKeyDown}
        userSources={userSources}
        setUserSources={setUserSources}
        externalSuggestions={promptRecommendations}
        shouldSearchInternet={shouldSearchInternet}
        setShouldSearchInternet={setShouldSearchInternet}
        shouldUseFiles={shouldUseFiles}
        setShouldUseFiles={setShouldUseFiles}
        shouldUsePipeline={shouldUsePipeline}
        setShouldUsePipeline={setShouldUsePipeline}
        shouldThink={shouldThink}
        setShouldThink={setShouldThink}
        canUseDb={pipelineConfigId}
      />
      {TERMS_LINKS}
    </InputContainer>
  );
  if (isSmallInput) {
    inputElement = (
      <InputArea>
        {isGenerating && (
          <StyledBubbleSendButton
            style={{
              position: "absolute",
              right: 16,
              top: 6,
              zIndex: 2,
            }}
            onClick={stopGeneration}
          >
            <WhiteRect
              onClick={() => {
                abortController.abort();
                setAbortController(new AbortController());
                setIsGenerating(false);
                const newBlocks = cloneDeep(blocks);
                const updatedBlocks = newBlocks
                  ?.filter(block => !block?.isThrowAway)
                  .map(block => {
                    block.isLoading = false;
                    return block;
                  });
                setBlocks(updatedBlocks);
              }}
            />
          </StyledBubbleSendButton>
        )}
        <StyledSmallInput
          ref={inputRef}
          disabled={isGenerating}
          value={userInput}
          onChange={e => setUserInput(e.target.value)}
          onFocus={() => {
            setIsFocussed(true);
          }}
          onBlur={() => setIsFocussed(false)}
          placeholder="Ask a question"
          onKeyDown={onKeyDown}
        />
        <TickboxLabel>
          Search web?
          <input
            checked={shouldSearchInternet}
            onClick={() => setShouldSearchInternet(!shouldSearchInternet)}
            type="checkbox"
          />
        </TickboxLabel>
        <SuggestionsContainer ref={suggestionsRef} style={popoverStyle}>
          <TickboxLabel
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShouldSearchInternet(!shouldSearchInternet);
            }}
            style={{
              position: "absolute",
              width: "max-content",
              top: -28,
              right: 4,
            }}
          >
            Search web?
            <input
              style={{ pointerEvents: "none" }}
              checked={shouldSearchInternet}
              onClick={() => setShouldSearchInternet(!shouldSearchInternet)}
              type="checkbox"
            />
          </TickboxLabel>
          {suggestionTexts?.map(text => (
            <Suggestion
              onMouseDown={e => {
                e.preventDefault();
                setUserInput(text);
              }}
            >
              {text}
            </Suggestion>
          ))}
        </SuggestionsContainer>
      </InputArea>
    );
  }

  return (
    <Container isSmallInput={isSmallInput}>
      <ResetContainer
        style={{ position: "fixed", bottom: 40, right: 50 }}
        onClick={resetMemory}
      >
        Reset
      </ResetContainer>
      {/* <SendEmailContainer style={{ position: "fixed", bottom: 40, right: 50 }}>
        <SendEmailModal isSummariseChat wordDoc={wordDoc} />
      </SendEmailContainer> */}
      <BlocksScroller ref={scrollerRef} onWheel={updateDownBtnVisibility}>
        <Blocks style={{ marginTop: marginTopBlocks }}>
          {blocks?.map((block, index) => {
            if (block?.isQuery) {
              let queryContent = block?.text;
              if (editQueryId === block?.queryId) {
                queryContent = (
                  <StyledInput
                    style={{ backgroundColor: BG_INPUT }}
                    value={editQueryText}
                    onChange={e => setEditQueryText(e.target.value)}
                  />
                );
              }

              let cotBubble = null;
              if (
                !blocks?.[index + 1]?.isLoading &&
                block?.chainOfThought?.steps
              ) {
                const onPressReRunStep = newSteps => {
                  const newBlocks = cloneDeep(blocks);
                  newBlocks[index].chainOfThought.steps = newSteps;
                  setBlocks(newBlocks);
                  doRerunBlock(index, newBlocks);
                };
                cotBubble = (
                  <TwoColumns
                    key={`${index}-cot`}
                    data-ref-id={block?.referenceId}
                  >
                    {BOT_PIC}
                    <RightContent>
                      <ChainOfThoughtSteps
                        question={block?.text}
                        steps={block?.chainOfThought?.steps}
                        onPressReRunStep={onPressReRunStep}
                        blocks={blocks}
                      />
                    </RightContent>
                  </TwoColumns>
                );
              }

              return (
                <>
                  <TwoColumns key={index} data-ref-id={block?.referenceId}>
                    {getProfilePic(blocks, index, userProfile)}
                    <RightContent>
                      <SourcesCards
                        sourceOptions={block?.userSources}
                        userSources={block?.userSources}
                        isEditingDisabled
                        isInitiallyOpen
                        expandedHeight="auto"
                      />
                      <BlockContent style={{ backgroundColor: BG_INPUT }}>
                        {queryContent}
                      </BlockContent>
                      {getActions(blocks, index)}
                    </RightContent>
                  </TwoColumns>
                  {cotBubble}
                </>
              );
            }

            if (block?.records) {
              if (block?.isLoading) {
                return (
                  <TwoColumns key={index}>
                    {getProfilePic(blocks, index)}
                    <RightContent>
                      <BlockContent>
                        <BlockLoadingState />
                      </BlockContent>
                    </RightContent>
                  </TwoColumns>
                );
              }

              return (
                <ChatTablePlotModal
                  block={block}
                  blocks={blocks}
                  isGenerating={isGenerating}
                />
              );
            }

            let blockContent = renderWithReferences(
              block,
              setSearchParams,
              blocks
            );
            if (block?.isLoading) {
              blockContent = <BlockLoadingState />;
            }
            let dataRefId = block?.referenceId || "";

            let urlsContent = null;
            const urlToLabelMap = getUrlToLabelMap(block);
            if (!isEmpty(urlToLabelMap) && !block?.isLoading) {
              const entries = Object.entries(urlToLabelMap);
              urlsContent = (
                <Urls>
                  {entries.map(([url, urlFields]) => {
                    let faviconUrl = urlFields?.faviconUrl;
                    let icon = <InternetIcon style={{ height: 14 }} />;
                    if (faviconUrl) {
                      icon = <FaviconImg src={faviconUrl} />;
                    }

                    return (
                      <UrlItem>
                        <UrlLabel>{urlFields?.label}</UrlLabel>
                        <UrlLink target="_blank" href={url}>
                          {icon}
                          {urlFields?.title}
                        </UrlLink>
                        <AddUrlToSourceButton
                          url={url}
                          onUrlReady={newSource =>
                            setSources([...(sources || []), newSource])
                          }
                        />
                        <UrlDescription style={{ gridColumn: 2 }}>
                          {urlFields?.description}
                        </UrlDescription>
                      </UrlItem>
                    );
                  })}
                </Urls>
              );
            }

            // Jinli: build retrieved PDF Content;
            let retrievalContent = null;
            const retrievalList = getRetrievalList(block);
            if (
              !isEmpty(retrievalList) &&
              !block?.isLoading &&
              block?.genContext?.includes("doc_index")
            ) {
              // get control message
              let controlMessage = blocks?.[
                index - 1
              ]?.chainOfThought?.steps?.[0]?.query?.match(
                /^I searched.*?retrieved results./
              )?.[0];
              // render the retrievalContent
              retrievalContent = (
                <>
                  <Gap />
                  <hr />
                  <p
                    style={{ fontWeight: "500" }}
                  >{`${controlMessage} The relevant documents are:`}</p>
                  <Urls>
                    {retrievalList.map(element => {
                      const foundSource = (sources || []).find(
                        s => s.fileId === element.fileId
                      );
                      return (
                        <UrlItem>
                          <UrlLabel>{element?.label}</UrlLabel>
                          <UrlLink target="_blank" href={element?.url}>
                            <PdfIcon style={{ fill: "#ed1c24" }} />
                            {element?.fileName}
                          </UrlLink>
                          <TooltipNew
                            tipText={
                              foundSource
                                ? "Remove this source"
                                : "Add as source for following question"
                            }
                          >
                            <StyledAnchorsIcon
                              isEnabled={foundSource}
                              height="20px"
                              onClick={() => {
                                if (foundSource) {
                                  setSources(
                                    (sources || []).filter(
                                      s => s.fileId !== element.fileId
                                    )
                                  );
                                } else {
                                  // define source shape
                                  const newSource = {
                                    fileId: element.fileId,
                                    fileName: element.fileName,
                                    type: "FILE",
                                  };
                                  setSources([...(sources || []), newSource]);
                                }
                                setShouldUseFiles(true);
                              }}
                            />
                          </TooltipNew>
                        </UrlItem>
                      );
                    })}
                  </Urls>
                </>
              );
            }

            return (
              <TwoColumns key={index} data-ref-id={dataRefId}>
                {getProfilePic(blocks, index)}
                <RightContent>
                  <BlockContent id={block?.queryId}>
                    {blockContent}
                  </BlockContent>
                  {urlsContent}
                  {retrievalContent}
                  {getActions(blocks, index)}
                </RightContent>
              </TwoColumns>
            );
          })}
        </Blocks>
      </BlocksScroller>
      {!isSmallInput && <FadeRect />}
      {inputElement}
    </Container>
  );
};

export default ChatViewNew;
