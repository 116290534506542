import { Visibility } from "@material-ui/icons";
import {
  getAnnotations,
  getFileById,
  patchAnnotations,
  patchFileById,
  triggerDownloadOfFile,
} from "api/backend/filesEndpoints";
import { getPipelineConfig } from "api/backend/fileSystemEndpoints";
import FileTagFetcher from "components/FileTagFetcher";
import { PageResult } from "components/FileViewModal";
import InputWithState from "components/InputWithState";
import NavHeader from "components/NavHeader";
import OutputItemTable from "components/OutputItemTable";
import TextWithLabels from "components/TextWithLabels";
import ButtonWord from "components/ui/ButtonWord";
import { DownloadIcon, FilesIcon, PdfIcon } from "components/ui/Icons";
import { range } from "lodash";
import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";

export const AREA_TYPE_TO_COLOR = {
  "-": "#aec7e8",
  text: "#0191ff",
  table: "#9650ff",
  infograph: "#ff7f0e",
  image: "#df5d00",
  title: "#009933",
  sidebar: "#00eeff",
  note: "#bcbd22",
  list: "#5c3129",
};

const Container = styled.div`
  background-color: white;
  height: 100%;
  border-radius: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  background: linear-gradient(180deg, #f3f5f7 0%, #f2f2f2 100%);
`;

const OuterContainer = styled.div`
  padding: 20px;
  background-color: #131718;
  height: 100vh;
`;

const TopBar = styled.div`
  border-bottom: 1px solid #ccc;
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  display: grid;
  grid-template-columns: repeat(4, auto) 1fr auto;
  justify-content: start;
  align-items: center;
  gap: 12px;
  padding: 0px 20px;
`;

const StyledNavHeader = styled(NavHeader)`
  position: relative;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
`;

const PagesContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-self: center;
  display: grid;
  justify-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  overflow: hidden;
`;

const SlideOut = styled.div`
  width: ${props => (props.isVisible ? "35vw" : "0px")};
  transition: width 0.1s;
  border-left: 1px solid #ccc;
  height: 100%;
  overflow: auto;
`;

const OutputContainer = styled.div`
  white-space: pre-wrap;
  line-height: 1.5;
  overflow: auto;
  height: 100%;

  background-color: white;
  border-bottom-right-radius: 20px;
  display: grid;
  grid-template-rows: auto auto 1fr;
`;

const OutputType = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 10px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OutputContent = styled.div`
  padding: 10px;
  white-space: pre-wrap;
`;

const OutputTitle = styled.div`
  font-weight: 500;
  padding: 0 10px;
  padding-top: 10px;
`;

const EditableFileName = styled(InputWithState)`
  font-weight: bold;
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 600;
`;

const FixedButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px;
  z-index: 1;
  width: 100%;
  svg {
    height: 18px;
    padding: 0;
    opacity: 0.6;
    cursor: pointer;
    :hover {
      opacity: 1;
    }
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  cursor: pointer;
  fill: black;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const ZoomBtn = styled(ButtonWord)`
  padding: 0;
  width: 14px;
  height: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFilesIcon = styled(FilesIcon)`
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
  :active {
    fill: ${props => props.theme.color.primary};
  }
`;

const StyledInput = styled.input`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 4px;
  outline: none;
  padding: 4px;
  font-family: "Montserrat", sans-serif;
  width: 300px;
`;

const LABEL_GROUPS = [
  {
    category: "General Entity Labels",
    options: [
      {
        value: "ORG",
        label: "Organizations",
        description: "e.g., banks, companies, institutions",
      },
      {
        value: "PER",
        label: "Persons",
        description: "e.g., executives, board members",
      },
      {
        value: "LOC",
        label: "Locations",
        description: "e.g., headquarters, regions",
      },
      {
        value: "DATE",
        label: "Dates",
        description: "e.g., transaction dates, financial periods",
      },
      {
        value: "MONEY",
        label: "Monetary values",
        description: "e.g., $1,000, €500",
      },
      {
        value: "PERCENT",
        label: "Percentages",
        description: "e.g., 10%, 0.5%",
      },
      {
        value: "TIME",
        label: "Time durations or specific times",
        description: "e.g., 'Q1 2024', '12 months'",
      },
      {
        value: "QUANTITY",
        label: "Quantities",
        description: "e.g., '100 shares', '2 tons'",
      },
      {
        value: "LAW",
        label: "Legal references",
        description: "e.g., financial regulations, acts",
      },
    ],
  },
  {
    category: "Financial-Specific Entity Labels",
    options: [
      {
        value: "TICKER",
        label: "Stock symbols or ticker codes",
        description: "e.g., AAPL, TSLA",
      },
      {
        value: "INSTRUMENT",
        label: "Financial instruments",
        description: "e.g., bonds, stocks, ETFs",
      },
      {
        value: "ASSET",
        label: "Assets",
        description: "e.g., properties, equipment, intangible assets",
      },
      {
        value: "LIABILITY",
        label: "Liabilities",
        description: "e.g., debts, obligations",
      },
      {
        value: "EQUITY",
        label: "Equity-related terms",
        description: "e.g., shareholders' equity",
      },
      {
        value: "FIN_TERM",
        label: "Financial terms or jargon",
        description: "e.g., EBITDA, P/E ratio",
      },
      {
        value: "INDUSTRY",
        label: "Industry or sector classifications",
        description: "e.g., 'Tech', 'Pharma'",
      },
      {
        value: "ACCOUNT",
        label: "Account types or specific accounts",
        description: "e.g., 'Accounts Receivable', 'General Ledger'",
      },
      {
        value: "RATE",
        label: "Interest rates, tax rates",
        description: "e.g., 'Fed Rate', '5% APR'",
      },
      {
        value: "CURRENCY",
        label: "Currencies",
        description: "e.g., USD, EUR, GBP",
      },
      {
        value: "EVENT",
        label: "Financial events",
        description: "e.g., 'earnings call', 'merger', 'IPO'",
      },
      {
        value: "RATING",
        label: "Credit or bond ratings",
        description: "e.g., 'AAA', 'Baa2'",
      },
      {
        value: "TRANSACTION",
        label: "Transaction types or details",
        description: "e.g., 'dividend payment', 'acquisition'",
      },
      {
        value: "MARKET_INDEX",
        label: "Market indices",
        description: "e.g., 'Dow Jones', 'S&P 500'",
      },
      {
        value: "REGULATOR",
        label: "Regulatory bodies",
        description: "e.g., 'SEC', 'FINRA'",
      },
      {
        value: "TAX",
        label: "Tax-specific entities or terms",
        description: "e.g., 'income tax', 'tax code'",
      },
      {
        value: "PRODUCT",
        label: "Financial products",
        description: "e.g., 'mutual fund', 'pension plan'",
      },
      {
        value: "CONTRACT",
        label: "Contracts or agreements",
        description: "e.g., 'lease agreement', 'swap contract'",
      },
      {
        value: "RISKS",
        label: "Risks or risk terms",
        description: "e.g., 'credit risk', 'liquidity risk'",
      },
    ],
  },
  {
    category: "Context-Specific or Customizable Labels",
    options: [
      {
        value: "POLICY",
        label: "Policies or guidelines",
        description: "e.g., 'investment policy', 'risk management policy'",
      },
      {
        value: "PROJECT",
        label: "Projects or initiatives",
        description: "e.g., 'Project X', 'R&D expenditure'",
      },
      {
        value: "BENCHMARK",
        label: "Benchmarks",
        description: "e.g., 'LIBOR', 'S&P benchmark'",
      },
      {
        value: "DEPARTMENT",
        label: "Internal departments",
        description: "e.g., 'Treasury', 'Audit'",
      },
    ],
  },
];

const OutputItem = ({ outputItem, labelGroups }) => {
  const [spans, setSpans] = useState([]);
  const [isPatchingSpans, setIsPatchingSpans] = useState(false);

  useEffect(() => {
    if (outputItem?.id !== undefined) {
      // Jinli: load spans from either the page-result/output, or endpoint
      // setSpans(outputItem?.annotations || []);
      doPopulateSpans();
    }
  }, [outputItem?.id]);

  const onPressSave = async () => {
    setIsPatchingSpans(true);
    const cleanedSpans = spans.filter(span => span?.label !== "TBD");

    const { fileId, pageNumber, id } = outputItem || {};
    await patchAnnotations(fileId, pageNumber, id, cleanedSpans);

    setIsPatchingSpans(false);
  };

  const doPopulateSpans = async () => {
    const { fileId, pageNumber, id } = outputItem || {};
    const { data, error } = await getAnnotations(fileId, pageNumber, id);
    if (error) {
      setSpans([]);
      return;
    }
    setSpans(data || []);
  };

  let outputContent = (
    <OutputContent>
      <TextWithLabels
        disabled={isPatchingSpans}
        labelGroups={labelGroups}
        text={outputItem?.content}
        spans={spans}
        onChangeSpans={newSpans => setSpans(newSpans)}
        onPressSave={onPressSave}
      />
    </OutputContent>
  );

  if (outputItem?.areaType === "table") {
    const { tableColumns, automaticLineItemNameMap, tablePreview, tableName } =
      outputItem;

    outputContent = (
      <OutputItemTable
        tableName={tableName}
        tableColumns={tableColumns}
        tablePreview={tablePreview}
        automaticLineItemNameMap={automaticLineItemNameMap}
        spans={spans}
        onChangeSpans={newSpans => setSpans(newSpans)}
        labelGroups={labelGroups}
        onPressSave={onPressSave}
        disabled={isPatchingSpans}
      />
    );
  }

  let outputAction = (
    <StyledFilesIcon
      onClick={() => navigator.clipboard.writeText(outputItem?.content)}
    />
  );
  if (outputItem?.areaType === "table") {
    outputAction = null;
  }

  return (
    <OutputContainer>
      <OutputType
        style={{
          backgroundColor: `${AREA_TYPE_TO_COLOR[outputItem?.areaType]}22`,
        }}
      >
        {outputItem?.areaType?.toUpperCase()}

        {outputAction}
      </OutputType>
      {outputItem?.title ? (
        <OutputTitle>{outputItem?.title}</OutputTitle>
      ) : (
        <div />
      )}
      {outputContent}
    </OutputContainer>
  );
};

const OcrPage = () => {
  const { fileId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [scrollTop, setScrollTop] = useState(0);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [selectedOutputItem, setSelectedOutputItem] = useState(null);
  const [labelGroups, setLabelGroups] = useState(LABEL_GROUPS);

  const [file, setFile] = useState(null);

  const isInspecting = searchParams.get("isInspecting") === "true";

  useEffect(() => {
    const doPopulateFile = async () => {
      const { data } = await getFileById(fileId);
      setFile(data);
    };

    const doPopulateExtractionLabels = async () => {
      const pipelineConfigId = searchParams.get("pipelineConfigId");
      if (!pipelineConfigId) {
        return;
      }
      const { data: pipelineConfig } = await getPipelineConfig(
        pipelineConfigId
      );
      const appLabelGroups = [];
      pipelineConfig?.sourceTables?.forEach((table, i) => {
        const configGroups = Object.groupBy(
          table?.columns || [],
          labelConfig => labelConfig.displayGroup
        );
        const parsed = Object.entries(configGroups)?.map(
          ([groupName, configs]) => {
            const labelGroupName = `${pipelineConfig?.name} / ${
              table?.displayName || `Table ${i + 1}`
            } / ${groupName}`;
            return {
              category: labelGroupName,
              options: configs?.map(labelConfig => {
                return {
                  label: labelConfig.displayName,
                  value: labelConfig.displayName,
                  description: labelConfig.description,
                };
              }),
            };
          }
        );
        appLabelGroups.push(...parsed);
      });

      if (!appLabelGroups) {
        return;
      }
      setLabelGroups([
        ...appLabelGroups,
        // ...LABEL_GROUPS'
      ]);
    };

    doPopulateFile();
    doPopulateExtractionLabels();
  }, [fileId, searchParams]);

  const doPatchFile = async body => {
    const { data } = await patchFileById(fileId, body);
    setFile(data);
  };

  const toggleBoxesVisible = () => {
    if (isInspecting) {
      searchParams.delete("isInspecting");
    } else {
      searchParams.set("isInspecting", true);
    }
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <OuterContainer>
      <Container>
        <TopBar>
          <Link to="/">
            <PdfIcon
              height="22px"
              style={{
                fill: "#ed1c24",
              }}
            />
          </Link>
          <EditableFileName
            initialValue={file?.fileName}
            onApplyValue={newFileName => doPatchFile({ fileName: newFileName })}
          />
          <StyledDownloadIcon
            onClick={() => triggerDownloadOfFile(fileId, { fileType: "FILE" })}
            style={{ height: "14px", width: "14px" }}
          />
          <FileTagFetcher fileId={fileId} />
          <div />
          <StyledNavHeader />
        </TopBar>
        <TwoColumns>
          <FixedButtonsContainer>
            <ZoomBtn
              onClick={() => setZoomFactor(prev => Math.max(prev - 0.5, 0.5))}
            >
              -
            </ZoomBtn>
            <ZoomBtn
              onClick={() => setZoomFactor(prev => Math.min(prev + 0.5, 10))}
            >
              +
            </ZoomBtn>
            <Visibility
              onClick={toggleBoxesVisible}
              style={{
                fill: isInspecting ? "#0191ff" : "#000",
              }}
            />
            <StyledInput placeholder="Search document" />
          </FixedButtonsContainer>
          <PagesContainer onScroll={e => setScrollTop(e?.target?.scrollTop)}>
            {range(0, file?.numberOfPages).map(pageNumber => (
              <PageResult
                zoomFactor={zoomFactor}
                isAlwaysVisible={pageNumber === 0}
                fileId={fileId}
                key={`${fileId}-${pageNumber}`}
                pageNumber={pageNumber}
                scrollTop={scrollTop}
                areBoxesVisible
                onClickOutputItem={outputItem =>
                  setSelectedOutputItem(outputItem)
                }
              />
            ))}
          </PagesContainer>
          <SlideOut isVisible={!!selectedOutputItem}>
            <OutputItem
              key={selectedOutputItem?.id}
              outputItem={selectedOutputItem}
              labelGroups={labelGroups}
            />
          </SlideOut>
        </TwoColumns>
      </Container>
    </OuterContainer>
  );
};

export default OcrPage;
