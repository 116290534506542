import {
  getLoggedInUserName,
  updateUser,
} from "api/services/authenticationService";
import { updateUserProfile } from "api/services/projectService";
import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { BoltzhubLogoInner, CrossIcon } from "components/ui/Icons";
import useLoginFromUrlParams from "hooks/useLoginFromUrlParams";
import { COLOR2 } from "pages/login-v2";
import { TERMS_CONTENT_SIGNUP } from "pages/signup-new";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px;

  @media (max-width: 1240px) {
    padding: 0;
  }
`;

const ContentCard = styled.div`
  background-color: #232627;
  border-radius: 20px;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-content: center;

  @media (max-width: 1240px) {
    border-radius: 0;
  }
`;

const CloseIcon = styled(CrossIcon)`
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 8px;
  border-radius: 50%;
  background-color: white;
  height: 30px;
  cursor: pointer;

  opacity: 0.8;
  :hover {
    opacity: 1;
  }
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
`;

const StyledBoltzbitLogo = styled(BoltzhubLogoInner)`
  fill: white;
  height: 40px;
  fill: url(#SvgGradientBlueToGreen);
  align-self: start;
  position: absolute;
  transform: translateX(-115%);
`;

const TitleMsg = styled.div`
  font-size: 42px;
  font-weight: 500;
  text-align: center;
`;

const Msg = styled.div`
  font-size: 12px;
  text-align: center;
  opacity: 0.6;
  line-height: 1.5;
  a {
    color: ${props => props.theme.color.primary};
  }
`;

const Input = styled.input`
  width: 100%;

  @media (min-width: 1240px) {
    width: 500px;
  }

  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 4px 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid transparent;
  background-color: #141718;
  margin-bottom: 16px;
  :focus {
    border: 1px solid ${COLOR2};
  }
  font-size: 14px;
  font-weight: 500;

  height: 52px;
  padding: 14px 16px 14px 16px;
  gap: 12px;
  border-radius: 12px;
  color-scheme: dark;

  ::placeholder {
    color: #80878baa;
  }
`;

const ErrorMsg = styled.div`
  color: #ff000094;
  height: 24px;
  font-size: 14px;
  display: flex;
  align-items: start;
  text-align: center;
`;

const LeftContainer = styled.div`
  padding: 30px 20px;
  padding-right: 40px;
`;

const LeftTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 56px;
  letter-spacing: -0.02em;
  margin-bottom: 10px;

  background: linear-gradient(88.86deg, #ffffff 60.62%, #6f6f6f 105.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Label = styled.div`
  padding-bottom: 8px;
  opacity: 0.5;
`;

const LeftSubTitle = styled.div`
  font-size: 24px;
  color: #e8ecef80;
  line-height: 1.4;
  margin-bottom: 40px;
`;

const Img = styled.img`
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FIELDS = [
  {
    name: "name",
    type: "text",
    label: "Your name",
    placeholder: "Name (required)",
  },
  {
    name: "organisation",
    type: "text",
    label: "Organisation",
    placeholder: "Company (optional)",
  },
  {
    name: "bio",
    type: "text",
    label: "Job Role",
    placeholder: "Job Title (optional)",
  },
  // {
  //   name: "DOB",
  //   type: "date",
  //   label: "Date of birth",
  // },
];

const SUCCESS_MSG = "Thank you. You now have access to more features.";

export const SKIP_NAME = "Anonymous";

// ABOUT_YOU, PASSWORD, THANKS
const LoginMyAccountPage = () => {
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");
  const [payload, setPayload] = useState({ name: "" });
  const [isSending, setIsSending] = useState(false);
  const [modalState, setModalState] = useState("ABOUT_YOU");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  useLoginFromUrlParams();

  const doSend = async payloadToUse => {
    // if (!payloadToUse?.name) {
    //   setErrorMsg("Please enter your name.");
    //   return;
    // }
    if (!password?.length || password?.length < 12) {
      setErrorMsg("Password must be at least 12 characters.");
      return;
    }
    if (password !== repeatPassword) {
      setErrorMsg("Passwords do not match.");
      return;
    }

    setErrorMsg("");
    setIsSending(true);

    const userName = getLoggedInUserName();
    const { error: passwordErr } = await updateUser(userName, {
      id: userName,
      password,
    });
    if (passwordErr) {
      setIsSending(false);
      setErrorMsg(
        passwordErr?.message || "There was an error. Please try again."
      );
      return;
    }

    const { error } = await updateUserProfile(
      {},
      {
        name: payloadToUse?.name || SKIP_NAME,
        organisation: payloadToUse?.organisation,
        bio: payloadToUse?.bio,
      }
    );
    setIsSending(false);
    if (error) {
      setErrorMsg(error?.message || "There was an error. Please try again.");
      return;
    }

    // setModalState("THANKS");
    navigate("/chat");
  };

  let modalContent = null;
  if (modalState === "ABOUT_YOU") {
    modalContent = (
      <>
        <Gap height="10px" />
        <TitleMsg>Tell us about you</TitleMsg>
        <Gap height="40px" />
        {FIELDS?.map(field => (
          <>
            {/* <Label>{field?.label}</Label> */}
            <Input
              type={field?.type || "text"}
              key={field?.name}
              value={payload[field.name]}
              onChange={e =>
                setPayload({ ...payload, [field.name]: e.target.value })
              }
              placeholder={field.placeholder}
            />
          </>
        ))}
        <ErrorMsg>{errorMsg}</ErrorMsg>
        <Gap height="40px" />
        <ButtonWord
          style={{
            height: 52,
            fontSize: 16,
            justifySelf: "center",
            opacity: isSending ? 0.5 : 1,
          }}
          isPrimary
          onClick={() => {
            if (!payload?.name) {
              setErrorMsg("Please enter your name.");
              return;
            }
            setErrorMsg("");
            setModalState("PASSWORD");
          }}
        >
          Continue
        </ButtonWord>
        {TERMS_CONTENT_SIGNUP}
      </>
    );
  }

  if (modalState === "PASSWORD") {
    modalContent = (
      <>
        <Gap height="10px" />
        <TitleMsg>Create a password</TitleMsg>
        <Gap height="40px" />
        {/* <Label>Password (at least 12 characters)</Label> */}
        <Input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="Password (12 characters minimum)"
        />
        <Input
          type="password"
          value={repeatPassword}
          onChange={e => setRepeatPassword(e.target.value)}
          placeholder="Confirm Password"
        />

        <ErrorMsg>{errorMsg}</ErrorMsg>
        {/* <Msg style={{ paddingBottom: 40 }}>
          By pressing "Continue" you agree to our{" "}
          <a href="https://boltzbit.com/flow/terms" target="_blank">
            Terms of Service
          </a>
          .
        </Msg> */}
        <FlexRow>
          <ButtonWord
            style={{ height: 52, fontSize: 16, opacity: isSending ? 0.5 : 1 }}
            isPrimary
            onClick={() => doSend(payload)}
          >
            Continue
          </ButtonWord>
          <ButtonWord
            style={{
              height: 52,
              fontSize: 16,
              opacity: isSending ? 0.5 : 1,
              backgroundColor: "transparent",
              color: "white",
              // width: "max-content",
              // justifySelf: "center",
            }}
            onClick={() => setModalState("ABOUT_YOU")}
          >
            Back
          </ButtonWord>
        </FlexRow>
        {TERMS_CONTENT_SIGNUP}
      </>
    );
  }

  if (modalState === "THANKS") {
    modalContent = (
      <>
        <Gap height="10px" />
        <Msg style={{ fontSize: 16, maxWidth: 500 }}>{SUCCESS_MSG}</Msg>
        <Gap height="30px" />
        <ButtonWord
          style={{ height: 52, fontSize: 16 }}
          isPrimary
          onClick={() => navigate("/chat")}
        >
          Continue
        </ButtonWord>
      </>
    );
  }

  return (
    <Container>
      <ContentCard>
        <Title style={{ justifySelf: "center", marginBottom: "60px" }}>
          <StyledBoltzbitLogo />
          Boltzbit
        </Title>
        {modalContent}
      </ContentCard>
    </Container>
  );
};

export default LoginMyAccountPage;
