import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const TooltipToShowOnHover = styled.div`
  background-color: ${props => props.theme.color.furthest};
  color: ${props => props.theme.color.closest};
  box-shadow: ${props => props.theme.shadow};

  border: 1px solid #ccc;
  font-size: 14px;
  user-select: none;
`;

const Span = styled.span``;

const SpanWithHoverTooltip = ({
  style,
  children,
  tooltip = null,
  isTooltipDisabled,
}) => {
  const [isMouseOverSpan, setIsMouseOverSpan] = useState(false);
  const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const spanRef = useRef(null);

  useEffect(() => {
    if (isMouseOverSpan) {
      setIsTooltipVisible(true);
    }

    if (!isMouseOverSpan && !isMouseOverTooltip) {
      setIsTooltipVisible(false);
    }
  }, [isMouseOverSpan, isMouseOverTooltip]);

  const { x, y } = spanRef?.current?.getBoundingClientRect() || {};

  return (
    <Span
      ref={spanRef}
      style={style}
      onMouseEnter={() => setIsMouseOverSpan(true)}
      onMouseLeave={() => setIsMouseOverSpan(false)}
    >
      {children}
      {!isTooltipDisabled && (
        <TooltipToShowOnHover
          style={{
            position: "fixed",
            left: x,
            top: y + 14,

            zIndex: isTooltipVisible ? 1000 : 0,
            pointerEvents: isTooltipVisible ? "all" : "none",
            display: isTooltipVisible ? "grid" : "none",
          }}
          onMouseEnter={() => setIsMouseOverTooltip(true)}
          onMouseLeave={() => setIsMouseOverTooltip(false)}
        >
          {tooltip}
        </TooltipToShowOnHover>
      )}
    </Span>
  );
};

export default SpanWithHoverTooltip;
