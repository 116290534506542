import { Save } from "@material-ui/icons";
import { postDownloadExcelFileOfTable } from "api/services/projectService";
import AnnoCell from "components/AnnoCell";
import { DownloadIcon } from "components/ui/Icons";
import Tooltip from "components/ui/Tooltip";
import { range } from "lodash";
import { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { parseJson } from "utils/common";
import SpanWithHoverTooltip from "./SpanWithHoverTooltip";
import { getSegments } from "./TextWithLabels";

const Container = styled.div`
  display: grid;
  padding: 10px;
  grid-template-rows: auto auto auto auto 1fr;
  align-content: start;
  height: 100%;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const ToolbarContainer = styled.div`
  padding: 0 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  height: 48px;
  z-index: 90;

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.numCols}, auto);
  align-content: start;
  overflow: auto;
  grid-auto-flow: dense;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: start;
  min-height: 400px;
`;

const TableName = styled.div`
  padding-left: ${props => (props.hasPadding ? "20px" : "0px")};
  padding-top: ${props => (props.hasPadding ? "20px" : "0px")};
  font-weight: 600;

  display: flex;
  gap: 4px;
`;

const StyledAnnoCell = styled(AnnoCell)`
  font-weight: ${props => (props.isBold ? 600 : "normal")};
  outline: ${props =>
    props.isSelected ? `1.5px solid ${props.theme.color.primary}` : "none"};
`;

const AreaTypeContainer = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledSelect = styled.select`
  border: 2px solid ${props => props.theme.color.closer1};
  font-family: "Montserrat";
  outline: none;
  cursor: pointer;
  margin-right: 5px;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const IconButton = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }
`;

const BottomLeftAlignedTooltip = styled(Tooltip)`
  transform: translateX(-15px) translateY(10%);
  color: ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.closest};
  padding: 2px 5px;
`;

const HeaderSelectorCell = styled.div`
  border: 1px solid ${props => props.theme.color.closer1};
  min-height: 20px;
  min-width: 20px;
  background-color: ${props => props.theme.color.closer0};
  opacity: 0;
  ${props => props.gridColumn && `grid-column: ${props.gridColumn};`}
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const getHumanLineItemMapFromEntities = (entities = []) => {
  const humanLineItemMap = {};
  entities?.forEach(entity => {
    const entityValue = parseJson(entity?.value);
    const entityAnnotation = parseJson(entityValue?.annotation);

    humanLineItemMap[entityAnnotation?.rowColStr] = {
      entityId: entity?.id,
      labelProperties: entityAnnotation?.labelProperties,
    };
  });

  return humanLineItemMap;
};

const getRowColStrs = (rowColStr, numRows, numCols) => {
  const [row, col] = rowColStr.split(",");
  if (!row && !col) {
    return [];
  }
  if (!row) {
    return range(numRows).map(r => `${r},${col}`);
  }
  if (!col) {
    return range(numCols).map(c => `${row},${c}`);
  }
  return [rowColStr];
};

const StyledDownloadIcon = styled(DownloadIcon)`
  fill: black;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const TextContainer = styled.div`
  position: relative;
  white-space: pre-wrap;
`;

const SpansContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  width: 100%;
  white-space: pre-wrap;
`;

const Select = styled.select`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: 1px solid #ccc;
`;

const Input = styled.input`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: 1px solid #ccc;
  ::placeholder {
    opacity: 0.6;
  }
`;

const renderSegments = textSegments => {
  return textSegments.map((segment, i) => {
    if (segment?.label) {
      return (
        <SpanWithHoverTooltip
          key={`${segment?.label}-${i}-${segment?.text}`}
          tooltip={segment?.tooltip}
          style={segment?.style}
        >
          {segment?.text}
        </SpanWithHoverTooltip>
      );
    }
    return segment?.text;
  });
};

const OutputItemTable = ({
  tableName = "",
  tableColumns = ["A", "B"],
  tablePreview = [{ A: "", B: "" }],
  automaticLineItemNameMap = { "0,0": "" },
  spans = [],
  onChangeSpans = newSpans => {},
  labelGroups = [],
  onPressSave = () => {},
  disabled = false,
}) => {
  const [searchParams] = useSearchParams();

  const [selectedCellRowColStr, setSelectedCellRowColStr] = useState("");
  const [selectedCellValue, setSelectedCellValue] = useState("");
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(
    searchParams?.get("label")
  );

  const onLabelChange = e => {
    let newSpans = spans.map(span => {
      if (span?.label === "TBD") {
        return { ...span, label: e.target.value };
      }
      return span;
    });
    onChangeSpans(newSpans);
    setSelectedLabel("");
  };

  const removeSpan = (start, end, rowIndex, colIndex) => {
    const newSpans = spans.filter(
      span =>
        span.start !== start ||
        span.end !== end ||
        span.rowIndex !== rowIndex ||
        span.colIndex !== colIndex
    );
    onChangeSpans(newSpans);
  };

  const onMouseUp = (text, rowIndex, colIndex) => {
    setIsMouseDown(false);
    const sel = window.getSelection();
    const start = Math.min(sel?.anchorOffset, sel?.focusOffset);
    const end = Math.max(sel?.anchorOffset, sel?.focusOffset);
    const newSpan = {
      start: start,
      end: end - 1,
      rowIndex: rowIndex,
      colIndex: colIndex,
      label: "TBD",
      value: text?.slice(start, end),
    };

    let newSpans = spans?.filter(s => s?.label !== "TBD") || [];
    if (newSpan?.start !== newSpan?.end + 1) {
      newSpans.push(newSpan);
    }
    onChangeSpans(newSpans);
  };

  const doDownloadTable = async () => {
    const bodyTablePreview = tablePreview?.map(row => {
      const newRow = {};
      Object.entries(row).forEach(([colName, value]) => {
        newRow[colName] = {
          RawValue: value,
          Value: value,
        };
      });

      return newRow;
    });

    const body = {
      tableColumns: tableColumns,
      tablePreview: bodyTablePreview,
    };

    await postDownloadExcelFileOfTable(body);
  };

  return (
    <Container isDisabled={disabled}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          marginBottom: 8,
        }}
      >
        <Input
          placeholder="Write label"
          value={selectedLabel}
          onChange={e => setSelectedLabel(e.target.value)}
          onBlur={onLabelChange}
        />
        <Select
          value={selectedLabel}
          disabled={!window?.getSelection()?.toString()}
          onChange={onLabelChange}
        >
          <option value="">-- or choose label --</option>
          {labelGroups?.map((group, i) => (
            <optgroup key={i} label={group?.category}>
              {group?.options?.map((option, j) => (
                <option key={j} value={option?.value}>
                  {option?.label}
                </option>
              ))}
            </optgroup>
          ))}
        </Select>
        <Save onClick={onPressSave} style={{ cursor: "pointer" }} />
      </div>
      <TableName hasPadding={!!tableName}>
        {tableName}
        <StyledDownloadIcon onClick={doDownloadTable} />
      </TableName>

      <Table numCols={tableColumns?.length + 1}>
        {range(0, tableColumns?.length + 1).map(colIndex => (
          <HeaderSelectorCell
            style={{ visibility: colIndex === 0 ? "hidden" : "visible" }}
            key={`col-header-${colIndex}`}
            onClick={() => {
              setSelectedCellRowColStr(`,${colIndex - 1}`);
              setSelectedCellValue(`all cells in column ${colIndex}`);
            }}
          />
        ))}
        {range(0, tablePreview?.length).map(rowIndex => (
          <HeaderSelectorCell
            gridColumn={1}
            key={`row-header-${rowIndex}`}
            onClick={() => {
              setSelectedCellRowColStr(`${rowIndex},`);
              setSelectedCellValue(`all cells in row ${rowIndex + 1}`);
            }}
          />
        ))}
        {tablePreview?.map((rowData, rowIndex) => (
          <Fragment key={rowIndex}>
            {tableColumns?.map((colName, colIndex) => {
              const segments = getSegments(
                rowData[colName],
                spans,
                removeSpan,
                rowIndex,
                colIndex
              );
              return (
                <StyledAnnoCell
                  onClick={() => {
                    setSelectedCellRowColStr(`${rowIndex},${colIndex}`);
                    setSelectedCellValue(rowData[colName]);
                  }}
                  onClickDelete={() => {}}
                  isSelected={
                    selectedCellRowColStr === `${rowIndex},${colIndex}` ||
                    selectedCellRowColStr === `,${colIndex}` ||
                    selectedCellRowColStr === `${rowIndex},`
                  }
                  isBold={rowData?._is_header}
                  key={`${rowIndex}-${colIndex}`}
                  annotations={[
                    automaticLineItemNameMap?.[`${rowIndex},${colIndex}`],
                  ]}
                >
                  {/* {rowData[colName]} */}
                  <TextContainer
                    onMouseDown={() => setIsMouseDown(true)}
                    onMouseUp={() =>
                      onMouseUp(rowData[colName], rowIndex, colIndex)
                    }
                  >
                    {rowData[colName]}
                    <SpansContainer
                      style={{ pointerEvents: isMouseDown ? "none" : "auto" }}
                    >
                      {renderSegments(segments)}
                    </SpansContainer>
                  </TextContainer>
                </StyledAnnoCell>
              );
            })}
          </Fragment>
        ))}
      </Table>
    </Container>
  );
};

export default OutputItemTable;
