import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPostRawResponse,
} from "api/api-http-methods";
import { zapiDelete, zapiGet, zapiPatch, zapiPost, zapiPut } from "api/zapi";

export const getActiveSubscription = async () => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/authentication-service/api/v1/account-subscriptions/active"
  );
  return { data, error };
};

export const deleteAccountSubscriptionsForceCancel = async id => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/authentication-service/api/v1/account-subscriptions/${id}/force-cancel`
  );
  return { data, error };
};

export const patchSubscription = async (id, params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/authentication-service/api/v1/account-subscriptions/${id}`,
    params,
    body
  );
  return { data, error };
};

export const patchSubscriptionCancel = async (
  id = "",
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/authentication-service/api/v1/account-subscriptions/${id}/cancel`,
    params,
    body
  );
  return { data, error };
};

export const postPaymentSession = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/authentication-service/api/v1/payment-sessions",
    params,
    body
  );
  return { data, error };
};

export const getUserProfileByUserName = async userName => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/authentication-service/api/v1/user-profiles/${userName}`
  );
  return { data, error };
};

export const getUserProfile = async () => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/authentication-service/api/v1/user-profile`
  );
  return { data, error };
};

export const updateUserProfile = async (params, profileData) => {
  const { data, error } = await zapiPut(
    `/solutions/ocr/authentication-service/api/v1/user-profile`,
    params,
    profileData
  );
  return { data, error };
};

export const postDownloadExcelFileOfTable = async body => {
  try {
    const res = await apiPostRawResponse(
      "/bz-api/v1/tables/download",
      {},
      body
    );
    const blob = await res.blob();
    const objectUrl = window.URL.createObjectURL(blob);

    const contentDispositionHeader = res.headers.get("Content-Disposition");
    const fileName = contentDispositionHeader?.split("filename=")?.[1];

    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = objectUrl;
    anchor.download = fileName || "table.xlsx";
    anchor.click();

    window.URL.revokeObjectURL(objectUrl);

    return { data: null, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const getCubesFindByKey = async (params = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/project-service/api/v1/cubes/find-by-key",
    params
  );
  return { data, error };
};

export const getPipelineConfigs = async (id = "", params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/project-service/api/v1/pipeline-configs/${id}`,
    params
  );
  return { data, error };
};

export const postAddCard = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/authentication-service/api/v1/cards`,
    params,
    body
  );
  return { data, error };
};

export const getCards = async (params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/authentication-service/api/v1/cards`,
    params
  );
  return { data, error };
};

export const deleteCard = async (params = {}, body = {}) => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/authentication-service/api/v1/cards`,
    params,
    body
  );
  return { data, error };
};

export const postMakeCardDefault = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/authentication-service/api/v1/cards/make-card-as-default`,
    params,
    body
  );
  return { data, error };
};

export const getBlogs = async (params = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/marketing/api/v1/blogs",
    params
  );
  return { data, error };
};

export const getBlog = async (id = "", params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/marketing/api/v1/blogs/${id}`,
    params
  );
  return { data, error };
};

export const postBlog = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/marketing/admin/api/v1/blogs",
    params,
    body
  );
  return { data, error };
};

export const patchBlog = async (id = "", params = {}, body = {}) => {
  const { data, error } = await zapiPatch(
    `/solutions/marketing/admin/api/v1/blogs/${id}`,
    params,
    body
  );
  return { data, error };
};

export const deleteBlog = async (id = "", params = {}) => {
  const { data, error } = await zapiDelete(
    `/solutions/marketing/admin/api/v1/blogs/${id}`,
    params
  );
  return { data, error };
};

export const getPageVisits = async (params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/marketing/api/v1/page-visits`,
    params
  );
  return { data, error };
};

// oauth start

export const getOauthLogin = async (provider = "google", params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/authentication-service/${provider}/login`,
    params
  );
  return { data, error };
};

export const getOauthCallback = async (provider = "google", params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/authentication-service/${provider}/oauth-callback`,
    params
  );
  return { data, error };
};

export const getOauthToken = async (provider = "google", params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/authentication-service/${provider}/token`,
    params
  );
  return { data, error };
};

export const deleteOauthToken = async (provider = "google", params = {}) => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/authentication-service/${provider}/token`,
    params
  );
  return { data, error };
};

// oauth end

export const getEmails = async () => {
  const { data, error } = await zapiGet("/solutions/ocr/api/v1/emails");
  return { data, error };
};

export const getEmail = async id => {
  const { data, error } = await zapiGet(`/solutions/ocr/api/v1/emails/${id}`);
  return { data, error };
};

export const postEmails = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/api/v1/emails",
    params,
    body
  );
  return { data, error };
};

export const getSequence = async (id = "", params = {}) => {
  const { data, error } = await apiGet(`/bz-api/v1/sequences/${id}`, params);
  return { data, error };
};

export const getSequences = async (params = {}) => {
  const { data, error } = await apiGet("/bz-api/v1/sequences", params);
  return { data, error };
};

export const postSequencesCreate = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/bz-api/v1/sequences/create",
    params,
    body
  );
  return { data, error };
};

export const patchSequenceUpdate = async (id = "", params = {}, body = {}) => {
  const { data, error } = await apiPatch(
    `/bz-api/v1/sequences/${id}/update`,
    params,
    body
  );
  return { data, error };
};

export const deleteSequence = async (id = "", params = {}) => {
  const { data, error } = await apiDelete(
    `/bz-api/v1/sequences/${id}/delete`,
    params
  );
  return { data, error };
};

export const postSequenceTrigger = async (id = "", params = {}, body = {}) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/sequences/${id}/trigger`,
    params,
    body
  );
  return { data, error };
};

export const getProviderLogin = async (provider = "google", params = {}) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/authentication-service/${provider}/login`,
    params
  );
  return { data, error };
};

export const getLoginOAuthCallback = async (
  provider = "google",
  params = {}
) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/authentication-service/${provider}/oauth-callback`,
    params
  );
  return { data, error };
};
