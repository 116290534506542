import { Save } from "@material-ui/icons";
import SpanWithHoverTooltip from "components/SpanWithHoverTooltip";
import { CrossIcon, FilesIcon } from "components/ui/Icons";
import { last } from "lodash";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getColorFromString } from "utils/common";

export const getSegments = (
  text,
  spansToUse,
  removeSpan = id => {},
  rowIndex = null,
  colIndex = null
) => {
  let outputSegments = [{ text: "", label: "" }];
  let i = 0;
  let filteredSpansToUse = spansToUse;
  if (rowIndex !== null && colIndex !== null) {
    filteredSpansToUse = spansToUse.filter(
      span => span.rowIndex === rowIndex && span.colIndex === colIndex
    );
  }
  while (i < text?.length) {
    let spanStart = filteredSpansToUse?.find(span => i === span.start);
    let spanEnd = filteredSpansToUse?.find(span => i === span.end);

    if (spanStart) {
      const { start, end, label } = spanStart || {};
      const spanText = text?.slice(start, end + 1);

      let tooltip = (
        <LabelTip>
          <FilesIcon onClick={() => navigator.clipboard.writeText(spanText)} />
          {label}
          <CrossIcon
            onClick={() => removeSpan(start, end, rowIndex, colIndex)}
          />
        </LabelTip>
      );
      if (label === "TBD") {
        tooltip = null;
      }

      outputSegments.push({
        text: "",
        label: spanStart?.label,
        style: {
          backgroundColor: `${getColorFromString(spanStart?.label)}44`,
        },
        tooltip,
      });
    }

    last(outputSegments).text += text[i];

    if (spanEnd) {
      outputSegments.push({ text: "", label: "" });
    }

    i++;
  }

  return outputSegments;
};

const renderSegments = textSegments => {
  return textSegments.map((segment, i) => {
    if (segment?.label) {
      return (
        <SpanWithHoverTooltip
          key={`${segment?.label}-${i}-${segment?.text}`}
          tooltip={segment?.tooltip}
          style={segment?.style}
        >
          {segment?.text}
        </SpanWithHoverTooltip>
      );
    }
    return segment?.text;
  });
};

const Container = styled.div`
  ${props => props.disabled && "pointer-events: none; opacity: 0.6;"}
`;

const TextContainer = styled.div`
  position: relative;
  white-space: pre-wrap;
`;

const Select = styled.select`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: 1px solid #ccc;
`;

const Input = styled.input`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: 1px solid #ccc;
  ::placeholder {
    opacity: 0.6;
  }
`;

const LabelTip = styled.div`
  padding: 2px 4px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 12px;
  svg {
    fill: white;
    height: 12px;
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }
`;

const SpansContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  width: 100%;
  white-space: pre-wrap;
`;

/*
span = { start, end, label }
*/
const TextWithLabels = ({
  spans = [],
  onChangeSpans = newSpans => {},
  text = "",
  labelGroups = [],
  onPressSave = () => {},
  disabled = false,
}) => {
  const [searchParams] = useSearchParams();

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(
    searchParams?.get("label")
  );

  const removeSpan = (start, end, rowIndex, colIndex) => {
    const newSpans = spans.filter(
      span => span.start !== start || span.end !== end
    );
    onChangeSpans(newSpans);
  };

  const onMouseUp = text => {
    setIsMouseDown(false);
    const sel = window.getSelection();
    const start = Math.min(sel?.anchorOffset, sel?.focusOffset);
    const end = Math.max(sel?.anchorOffset, sel?.focusOffset);
    const newSpan = {
      start: start,
      end: end - 1,
      label: "TBD",
      value: text?.slice(start, end),
    };

    let newSpans = spans?.filter(s => s?.label !== "TBD") || [];
    if (newSpan?.start !== newSpan?.end + 1) {
      newSpans.push(newSpan);
    }

    onChangeSpans(newSpans);
  };

  const onSelectLabelChange = e => {
    let newSpans = spans.map(span => {
      if (span?.label === "TBD") {
        return { ...span, label: e.target.value };
      }
      return span;
    });
    onChangeSpans(newSpans);
    setSelectedLabel("");
  };

  const segments = getSegments(text, spans, removeSpan);

  return (
    <Container disabled={disabled}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          marginBottom: 8,
        }}
      >
        <Input
          placeholder="Write label"
          value={selectedLabel}
          onChange={e => setSelectedLabel(e.target.value)}
          onBlur={onSelectLabelChange}
        />
        <Select
          value={selectedLabel}
          disabled={!window?.getSelection()?.toString()}
          onChange={onSelectLabelChange}
        >
          <option value="">-- or choose label --</option>
          {labelGroups?.map((group, i) => (
            <optgroup key={i} label={group?.category}>
              {group?.options?.map((option, j) => (
                <option key={j} value={option?.value}>
                  {option?.label}
                </option>
              ))}
            </optgroup>
          ))}
        </Select>
        <Save onClick={onPressSave} style={{ cursor: "pointer" }} />
      </div>
      <TextContainer
        onMouseDown={() => setIsMouseDown(true)}
        onMouseUp={() => onMouseUp(text)}
      >
        {text}
        <SpansContainer
          style={{ pointerEvents: isMouseDown ? "none" : "auto" }}
        >
          {renderSegments(segments)}
        </SpansContainer>
      </TextContainer>
    </Container>
  );
};

export default TextWithLabels;
