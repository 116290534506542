import { loader } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';

import { Editor } from "@monaco-editor/react";
import { apiGetRawResponse, apiPostRawResponse } from "api/api-http-methods";
import AreaChartParamsNew from "components/AreaChartParamsNew";
import BarChartParamsNew from "components/BarChartParamsNew";
import { LinkIcon } from "components/IconsNew";
import KeyFiguresParams from "components/KeyFiguresParams";
import { Gap } from "components/Layout";
import LayoutNew from "components/LayoutNew";
import ButtonWord from "components/ui/ButtonWord";
import { GearIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import NavWithTabsNew from "components/ui/NavWithTabsNew";
import "highlight.js/styles/vs2015.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

loader.config({ monaco });

// styles
const Container = styled.div`
  padding: 80px 80px 0px 80px;
  position: relative;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SwitchLinks = styled.div`
  background-color: #eaeaea;
  display: grid;
  grid-auto-flow: column;
  padding: 4px;
  border-radius: 14px;
  width: max-content;
`;

const SwitchLink = styled.div`
  background-color: ${props => (props.isActive ? "#2a2a2a" : "#eaeaea")};
  color: ${props => (props.isActive ? "#cecece" : "#434343")};
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  // :hover {
  //   color: #000000;
  // }
  svg {
    fill: #000000;
  }
`;

const ModalContent = styled.div`
  padding: 40px 40px 0px 40px;
  position: relative;
  width: 1000px;
  height: 600px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-content: start;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: start;
  align-content: baseline;
  gap: ${props => props?.gap || "40px"};
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;

  table {
    width: inherit;
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
  }
  table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  table tbody th {
    position: relative;
  }
  // table thead th:first-child {
  //   width: 1%;
  //   position: sticky;
  //   left: 0;
  //   z-index: 2;
  //   border-right: 1px solid ${props => props.theme.color.closer1};
  // }
  table tbody th:first-child {
    border-right: 1px solid ${props => props.theme.color.closer1};
  }
  table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;

  font-weight: 600;
  z-index: 1;
  color: ${props => {
    switch (props.columnType) {
      case "meta":
        return "red";
      case "input":
        return "blue";
      case "input-group":
        return "purple";
      case "output":
        return "green";
      default:
        return null;
    }
  }};

  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Tr = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const Td = styled.td`
  background-color: white;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const GroupCard = styled.div`
  background-color: #ffffff;
  padding: 0px;
  border-radius: 10px;
  height: 100%;
  align-content: start;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  border: 1px solid #f1f1f1;
`;

const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  background-color: ${props => props.color || "#888888"}DE;
  padding: 0px 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  white-space: nowrap;
  grid-column: span 2;
  height: 42px;
  display: grid;
  align-items: center;
`;

const GroupFields = styled.div`
  padding: 15px;
  align-content: start;
  align-items: start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  grid-column: span 2;
`;

const Field = styled.div`
  display: grid;
  gridcolumn: auto;
  width: 100%;
  gap: 5px;
`;

const FieldLabel = styled.div`
  padding-left: 1px;
  font-weight: 600;
  color: ${props => props.color || "#000000"};
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    height: 10px;
  }
`;

const FieldClickIcon = styled.div`
  cursor: pointer;
  :hover {
    svg {
      fill: #497ff3;
    }
  }
`;

const FieldInput = styled.input`
  background-color: transparent;
  padding: 0px;
  color: black;
  font-size: 14px;

  min-width: 0;
  outline: none;
  border: 1px solid white;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const FieldSelect = styled.select`
  background-color: transparent;
  padding: 0px;
  color: black;
  font-size: 14px;

  min-width: 0;
  outline: none;
  border: 1px solid white;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const FieldText = styled.div`
  align-self: center;
`;

const FiledInlineInput = styled.input`
  background-color: transparent;
  padding: 0px;

  color: black;
  font-size: 14px;

  max-width: 48px;
  outline: none;
  border: 1px solid white;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const HorizontalLine = styled.div`
  border-bottom: 1px solid #dddddd;
  height: 0px;
  margin-bottom: 0px;
  width: 100%;
  grid-column: span 2;
`;

// local data
const STATS = {
  overall: [
    {
      id: "total_data_points",
      label: "Total Data Points",
      value: 1000,
    },
    {
      id: "total_model_versions",
      label: "Total Model Versions",
      value: 3,
    },
    {
      id: "latest_accuracy",
      label: "Latest Accuracy (%)",
      value: 95,
    },
  ],
  data: [
    {
      id: "data_volume",
      title: "Data Volume",
      records: [
        {
          date: {
            value: "21 Feb",
          },
          "Data Volume": {
            value: 66,
          },
        },
        {
          date: {
            value: "22 Feb",
          },
          "Data Volume": {
            value: 10,
          },
        },
        {
          date: {
            value: "23 Feb",
          },
          "Data Volume": {
            value: 5,
          },
        },
        {
          date: {
            value: "24 Feb",
          },
          "Data Volume": {
            value: 47,
          },
        },
        {
          date: {
            value: "25 Feb",
          },
          "Data Volume": {
            value: 72,
          },
        },
      ],
      plots: {
        xColumnName: "date",
        yColumnNames: ["Data Volume"],
      },
    },
    {
      id: "label_model_calls",
      title: "Label Model Calls",
      records: [
        {
          date: {
            value: "21 Feb",
          },
          "# Calls": {
            value: 33,
          },
        },
        {
          date: {
            value: "22 Feb",
          },
          "# Calls": {
            value: 4,
          },
        },
        {
          date: {
            value: "23 Feb",
          },
          "# Calls": {
            value: 4,
          },
        },
        {
          date: {
            value: "24 Feb",
          },
          "# Calls": {
            value: 27,
          },
        },
        {
          date: {
            value: "25 Feb",
          },
          "# Calls": {
            value: 25,
          },
        },
      ],
      plots: {
        xColumnName: "date",
        yColumnNames: ["# Calls"],
      },
    },
  ],
  model: [
    {
      id: "trained_models",
      title: "Trained Models",
      records: [
        {
          date: {
            value: "21 Feb",
          },
          "Trained Models": {
            value: 1,
          },
        },
        {
          date: {
            value: "22 Feb",
          },
          "Trained Models": {
            value: 1,
          },
        },
        {
          date: {
            value: "23 Feb",
          },
          "Trained Models": {
            value: 0,
          },
        },
        {
          date: {
            value: "24 Feb",
          },
          "Trained Models": {
            value: 1,
          },
        },
        {
          date: {
            value: "25 Feb",
          },
          "Trained Models": {
            value: 3,
          },
        },
      ],
      plots: {
        xColumnName: "date",
        yColumnNames: ["Trained Models"],
      },
    },
    {
      id: "model_accuracy",
      title: "Model Accuracy",
      records: [
        {
          date: {
            value: "21 Feb",
          },
          Accuracy: {
            value: 0.4,
          },
        },
        {
          date: {
            value: "22 Feb",
          },
          Accuracy: {
            value: 0.6,
          },
        },
        {
          date: {
            value: "23 Feb",
          },
          Accuracy: {
            value: 0.6,
          },
        },
        {
          date: {
            value: "24 Feb",
          },
          Accuracy: {
            value: 0.8,
          },
        },
        {
          date: {
            value: "25 Feb",
          },
          Accuracy: {
            value: 0.9,
          },
        },
      ],
      plots: {
        xColumnName: "date",
        yColumnNames: ["Accuracy"],
      },
    },
  ],
  serve: [
    {
      id: "inference_calls",
      title: "Inference Calls",
      records: [
        {
          time: {
            value: "13:00",
          },
          "# Calls": {
            value: 11,
          },
        },
        {
          time: {
            value: "13:15",
          },
          "# Calls": {
            value: 13,
          },
        },
        {
          time: {
            value: "13:30",
          },
          "# Calls": {
            value: 10,
          },
        },
        {
          time: {
            value: "13:45",
          },
          "# Calls": {
            value: 17,
          },
        },
        {
          time: {
            value: "14:00",
          },
          "# Calls": {
            value: 14,
          },
        },
      ],
      plots: {
        xColumnName: "time",
        yColumnNames: ["# Calls"],
      },
    },
    {
      id: "learning_calls",
      title: "Learning Calls",
      records: [
        {
          time: {
            value: "13:00",
          },
          "# Calls": {
            value: 0,
          },
        },
        {
          time: {
            value: "13:15",
          },
          "# Calls": {
            value: 1,
          },
        },
        {
          time: {
            value: "13:30",
          },
          "# Calls": {
            value: 0,
          },
        },
        {
          time: {
            value: "13:45",
          },
          "# Calls": {
            value: 0,
          },
        },
        {
          time: {
            value: "14:00",
          },
          "# Calls": {
            value: 1,
          },
        },
      ],
      plots: {
        xColumnName: "time",
        yColumnNames: ["# Calls"],
      },
    },
  ],
};

// tabs
const PAGE_TABS = [
  {
    id: "home",
    tableDisplayName: "Home",
  },
  {
    id: "data",
    tableDisplayName: "Data",
  },
  {
    id: "model",
    tableDisplayName: "Model",
  },
  {
    id: "serve",
    tableDisplayName: "Serving",
  },
];

const SERVE_API_TABS = [
  {
    id: "infer",
    name: "Infer",
  },
  {
    id: "learn",
    name: "Learn",
  },
];

const ConfigModal = ({ children, envConfigStr, onClick = async () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [configStr, setConfigStr] = useState(envConfigStr);

  useEffect(() => {
    setConfigStr(envConfigStr);
  }, [envConfigStr]);

  return (
    <>
      <FieldClickIcon onClick={() => setIsOpen(true)}>
        {children}
      </FieldClickIcon>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <Title>Config</Title>
          <Gap />
          <ButtonWord
            style={{ margin: "10px 0px", padding: "10px 20px" }}
            isPrimary
            onClick={async () => {
              setIsLoading(true);
              await onClick(JSON.parse(configStr));
              setIsLoading(false);
            }}
          >
            Save
          </ButtonWord>
          <Editor
            height="40vh"
            theme="vs-dark"
            value={configStr}
            onChange={setConfigStr}
            language="json"
          />
        </ModalContent>
      </Modal>
    </>
  );
};

const CodeModal = ({
  children,
  codeStr,
  outputStr = "",
  onClickRun = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const monacoOptions = {
    lineNumbers: "off",
    minimap: { enabled: false },
    scrollbar: { vertical: "hidden", horizontal: "hidden" },
    readOnly: true,
    lineDecorationsWidth: 0,
  };

  return (
    <>
      <FieldClickIcon onClick={() => setIsOpen(true)}>
        {children}
      </FieldClickIcon>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>API Code Test</Title>
          <Gap />
          <ButtonWord
            style={{ margin: "10px 0px", padding: "10px 20px" }}
            isPrimary
            onClick={onClickRun}
          >
            Run
          </ButtonWord>
          <FlexRow>
            <Editor
              theme="vs-dark"
              height="40vh"
              value={codeStr}
              language="powershell"
              options={monacoOptions}
            />
            <Editor
              theme="vs-dark"
              height="40vh"
              value={outputStr}
              language="json"
              options={monacoOptions}
            />
          </FlexRow>
        </ModalContent>
      </Modal>
    </>
  );
};

const DataGroupModal = ({ children, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <FieldClickIcon onClick={() => setIsOpen(true)}>
        {children}
      </FieldClickIcon>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>{data.column.name}</Title>
          <Gap />
          <TableContainer>
            <table>
              <thead>
                <Tr>
                  {data.column.fields.map(column => (
                    <Th key={column.name} columnType={column.columnType}>
                      {column.name}
                    </Th>
                  ))}
                </Tr>
              </thead>
              <tbody>
                {data.value.map((dataPoint, i) => (
                  <Tr key={i}>
                    {data.column.fields.map(column => {
                      const value = dataPoint[column.name];
                      return <Td key={column.name}>{value}</Td>;
                    })}
                  </Tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

const HomeTab = ({ stats }) => {
  // build key figure records
  const [keyFigureRecords, setKeyFigureRecords] = useState([]);

  useEffect(() => {
    const newKeyFigureRecords = [
      {
        fields: Object.fromEntries(
          stats.overall.map(stat => {
            return [stat.label, { value: stat.value }];
          })
        ),
      },
    ];
    setKeyFigureRecords(newKeyFigureRecords);
  }, [stats]);

  return (
    <>
      <Gap />
      <KeyFiguresParams records={keyFigureRecords} />
      <Gap />
      <GridRow>
        {stats.data.map(stat => (
          <AreaChartParamsNew
            areParamsHidden
            title={stat.title}
            params={stat.plots}
            records={stat.records}
          />
        ))}
        {stats.model.map(stat => (
          <BarChartParamsNew
            areParamsHidden
            title={stat.title}
            params={stat.plots}
            records={stat.records}
          />
        ))}
        {stats.serve.map(stat => (
          <AreaChartParamsNew
            areParamsHidden
            title={stat.title}
            params={stat.plots}
            records={stat.records}
          />
        ))}
      </GridRow>
    </>
  );
};

const DataTab = ({ envConfig, stats, dataPreview }) => {
  const [columns, setColumns] = useState([]);
  const [dataUrl, setDataUrl] = useState("");
  const [dataOutputStr, setDataOutputStr] = useState("");
  const [baseModelUrl, setBaseModelUrl] = useState("");
  const [modelOutputStr, setModelOutputStr] = useState("");
  const [exampleInputStr, setExampleInputStr] = useState("{}");

  const dataCodeStr = `curl --location '${dataUrl}'\n`;
  const modelCodeStr = `curl --location '${baseModelUrl}' \\\n  --header 'Content-Type: application/json' \\\n  --data '${exampleInputStr}'\n`;

  const runDataURL = async () => {
    const body = {
      method: "GET",
      url: dataUrl,
    };
    const resp = await apiPostRawResponse("/bz-demo/test-url", {}, body);
    const respText = await resp.text();
    setDataOutputStr(respText);
  };

  const runBaseModelURL = async () => {
    const body = {
      method: "POST",
      url: baseModelUrl,
      json: JSON.parse(exampleInputStr),
    };
    const resp = await apiPostRawResponse("/bz-demo/test-url", {}, body);
    const respText = await resp.text();
    setModelOutputStr(respText);
  };

  useEffect(() => {
    const newColumns = [
      {
        name: "timestamp",
        description: "",
        dtype: "datetime",
        isSequence: false,
        columnType: "meta",
      },
      {
        name: "source",
        description: "",
        dtype: "text",
        isSequence: false,
        columnType: "meta",
      },
    ];
    envConfig.inputs.forEach(element => {
      newColumns.push({
        ...element,
        columnType: element.dtype === "group" ? "input-group" : "input",
      });
    });
    envConfig.outputs.forEach(element => {
      newColumns.push({
        ...element,
        columnType: "output",
      });
    });
    setColumns(newColumns);
    setDataUrl(envConfig.data.url);
    setBaseModelUrl(envConfig.baseModel.url);
    setExampleInputStr(
      JSON.stringify(envConfig.baseModel?.exampleInput || {}, null, 2)
    );
  }, [envConfig]);

  return (
    <>
      <Gap />
      <Title>Setting</Title>
      <Gap />
      <GroupCard key="settings">
        <GroupTitle color="white">Data/Model Integration</GroupTitle>
        <HorizontalLine />
        <GroupFields>
          <Field>
            <FieldLabel color="#0191ff">
              Data
              <CodeModal
                codeStr={dataCodeStr}
                outputStr={dataOutputStr}
                onClickRun={() => {
                  runDataURL();
                }}
              >
                <GearIcon />
              </CodeModal>
            </FieldLabel>
            <FieldInput
              value={dataUrl}
              onChange={e => setDataUrl(e.target.value)}
            />
          </Field>
          <Field>
            <FieldLabel color="#0191ff">
              Label model (distill target)
              <CodeModal
                codeStr={modelCodeStr}
                outputStr={modelOutputStr}
                onClickRun={() => {
                  runBaseModelURL();
                }}
              >
                <GearIcon />
              </CodeModal>
            </FieldLabel>
            <FieldInput
              value={baseModelUrl}
              onChange={e => setBaseModelUrl(e.target.value)}
            />
          </Field>
        </GroupFields>
      </GroupCard>
      <ButtonWord
        style={{ marginTop: "10px", padding: "4px 20px", justifySelf: "end" }}
        isPrimary
        onClick={() => {}}
      >
        Save
      </ButtonWord>
      <Gap />
      <Title>Data Statistics</Title>
      <Gap />
      <GridRow>
        {stats.data.map(stat => (
          <AreaChartParamsNew
            areParamsHidden
            title={stat.title}
            params={stat.plots}
            records={stat.records}
          />
        ))}
      </GridRow>
      <Gap />
      <Title>Latest Datapoints</Title>
      <Gap />
      <TableContainer>
        <table>
          <thead>
            <Tr>
              {columns.map(column => (
                <Th key={column.name} columnType={column.columnType}>
                  {column.name}
                </Th>
              ))}
            </Tr>
          </thead>
          <tbody>
            {dataPreview.map((dataPoint, i) => (
              <Tr key={i}>
                {columns.map(column => {
                  const value = dataPoint[column.name];
                  // get the cell color based on the source
                  let cellBgColor = "white";
                  switch (dataPoint.source) {
                    case "online:data":
                      cellBgColor = "PaleGoldenRod";
                      break;
                    case "online:model":
                      cellBgColor = "PaleTurquoise";
                      break;
                    default:
                      cellBgColor = "#ebebeb";
                  }

                  if (column.dtype === "group") {
                    return (
                      <Td
                        key={column.name}
                        style={{ backgroundColor: cellBgColor }}
                      >
                        <DataGroupModal data={{ column, value }}>
                          <LinkIcon style={{ height: "8px" }} />
                        </DataGroupModal>
                      </Td>
                    );
                  } else {
                    return (
                      <Td
                        key={column.name}
                        style={{ backgroundColor: cellBgColor }}
                      >
                        {value}
                      </Td>
                    );
                  }
                })}
              </Tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </>
  );
};

const ModelTab = ({ envConfig, stats, modelPreview }) => {
  const [columns] = useState([
    {
      name: "timestamp",
      description: "",
      dtype: "float",
      isSequence: false,
      columnType: "meta",
    },
    {
      name: "Model ID",
      description: "",
      dtype: "float",
      isSequence: false,
    },
    {
      name: "Model Performance",
      description: "",
      dtype: "int",
      isSequence: false,
    },
  ]);
  const [data, setData] = useState([]);
  const [modelConfig, setModelConfig] = useState({
    learningFreq: 100,
    enableRL: false,
  });
  const [dataUrl, setDataUrl] = useState("");
  const [dataOutputStr, setDataOutputStr] = useState("");
  const [evalModelUrl, setEvalModelUrl] = useState("");
  const [modelOutputStr, setModelOutputStr] = useState("");
  const [exampleInputStr, setExampleInputStr] = useState("{}");

  const dataCodeStr = `curl --location '${dataUrl}'\n`;
  const modelCodeStr = `curl --location '${evalModelUrl}' \\\n  --header 'Content-Type: application/json' \\\n  --data '${exampleInputStr}'\n`;

  const runEvalDataURL = async () => {
    const body = {
      method: "GET",
      url: dataUrl,
    };
    const resp = await apiPostRawResponse("/bz-demo/test-url", {}, body);
    const respText = await resp.text();
    setDataOutputStr(respText);
  };

  const runEvalModelURL = async () => {
    const body = {
      method: "POST",
      url: evalModelUrl,
      json: JSON.parse(exampleInputStr),
    };
    const resp = await apiPostRawResponse("/bz-demo/test-url", {}, body);
    const respText = await resp.text();
    setModelOutputStr(respText);
  };

  const updateLearningFreq = newLearningFreq => {
    const newModelConfig = {
      ...modelConfig,
      learningFreq: newLearningFreq,
    };
    setModelConfig(newModelConfig);
  };

  const updateEnableRL = newEnableRL => {
    const newModelConfig = {
      ...modelConfig,
      enableRL: newEnableRL,
    };
    setModelConfig(newModelConfig);
  };

  useEffect(() => {
    const newData = [];
    modelPreview.forEach(ckpt => {
      newData.push({
        timestamp: ckpt.createAt,
        "Model ID": ckpt.modelId,
        "Model Performance": `[Metric: ${ckpt.evaluationMetric}] ${ckpt.evaluationResult}`,
      });
    });
    setData(newData);
    setDataUrl(envConfig.evaluationData.url);
    setEvalModelUrl(envConfig.evaluationBaseModel.url);
    setExampleInputStr(
      JSON.stringify(envConfig.baseModel?.exampleInput || {}, null, 2)
    );
  }, [envConfig, modelPreview]);

  return (
    <>
      <Gap />
      <Title>Setting</Title>
      <Gap />
      <GroupCard key="training-settings">
        <GroupTitle color="white">Online learning configuration</GroupTitle>
        <HorizontalLine />
        <GroupFields>
          <Field>
            <FieldLabel color="#0191ff">Online learning frequency</FieldLabel>
            <FlexRow gap="10px">
              <FieldText>Update every</FieldText>
              <FiledInlineInput
                value={modelConfig.learningFreq}
                type="number"
                onChange={e => updateLearningFreq(e.target.value)}
              />
              <FieldText>datapoints.</FieldText>
            </FlexRow>
          </Field>
          <Field>
            <FieldLabel color="#0191ff">Enable RL</FieldLabel>
            <FieldSelect
              value={modelConfig.enableRL}
              onChange={e => updateEnableRL(e.target.value)}
            >
              <option value={false}>Disable</option>
              <option value={true}>Enable</option>
            </FieldSelect>
          </Field>
        </GroupFields>
      </GroupCard>
      <Gap />
      <GroupCard key="eval-settings">
        <GroupTitle color="white">Evaluation configuration</GroupTitle>
        <HorizontalLine />
        <GroupFields>
          <Field>
            <FieldLabel color="#0191ff">
              Eval data
              <CodeModal
                codeStr={dataCodeStr}
                outputStr={dataOutputStr}
                onClickRun={() => {
                  runEvalDataURL();
                }}
              >
                <GearIcon />
              </CodeModal>
            </FieldLabel>
            <FieldInput
              value={envConfig.evaluationData.url}
              onChange={e => {}}
            />
          </Field>
          <Field>
            <FieldLabel color="#0191ff">
              Eval base model
              <CodeModal
                codeStr={modelCodeStr}
                outputStr={modelOutputStr}
                onClickRun={() => {
                  runEvalModelURL();
                }}
              >
                <GearIcon />
              </CodeModal>
            </FieldLabel>
            <FieldInput
              value={envConfig.evaluationBaseModel.url}
              onChange={e => {}}
            />
          </Field>
        </GroupFields>
      </GroupCard>
      <ButtonWord
        style={{ marginTop: "10px", padding: "4px 20px", justifySelf: "end" }}
        isPrimary
        onClick={() => {}}
      >
        Save
      </ButtonWord>
      <Gap />
      <Title>Model Statistics</Title>
      <Gap />
      <GridRow>
        {stats.model.map(stat => (
          <BarChartParamsNew
            areParamsHidden
            title={stat.title}
            params={stat.plots}
            records={stat.records}
          />
        ))}
      </GridRow>
      <Gap />
      <Title>Latest Model Checkpoints</Title>
      <Gap />
      <TableContainer>
        <table>
          <thead>
            <Tr>
              {columns.map(column => (
                <Th key={column.name}>{column.name}</Th>
              ))}
            </Tr>
          </thead>
          <tbody>
            {data.map((dataPoint, i) => {
              // get the cell color based on timestamp
              let cellBgColor = "white";
              if (Date.now() - Date.parse(dataPoint.timestamp) < 86400000) {
                cellBgColor = "PaleGoldenRod";
              } else {
                cellBgColor = "#ebebeb";
              }
              return (
                <Tr key={i}>
                  {columns.map(column => {
                    const value = dataPoint[column.name];
                    return (
                      <Td
                        key={column.name}
                        style={{ backgroundColor: cellBgColor }}
                      >
                        {value}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </tbody>
        </table>
      </TableContainer>
    </>
  );
};

const ServeTab = ({ envConfig, stats }) => {
  const [selectedAPITabId, setSelectedAPITabId] = useState("infer");
  const [outputStr, setOutputStr] = useState("");
  const [inferCodeStr, setInferCodeStr] = useState("");
  const [learnCodeStr, setLearnCodeStr] = useState("");
  const monacoOptions = {
    lineNumbers: "off",
    minimap: { enabled: false },
    scrollbar: { vertical: "hidden", horizontal: "hidden" },
    readOnly: true,
    lineDecorationsWidth: 0,
  };

  useEffect(() => {
    const doLoadInferCodeStr = async () => {
      const baseUrl = "/bz-demo/script";
      const resp = await apiGetRawResponse(
        `${baseUrl}/${envConfig.envId}/infer`
      );
      const respText = await resp.text();
      setInferCodeStr(respText);
    };

    const doLoadLearnCodeStr = async () => {
      const baseUrl = "/bz-demo/script";
      const resp = await apiGetRawResponse(
        `${baseUrl}/${envConfig.envId}/learn`
      );
      const respText = await resp.text();
      const newLearnCodeStr = respText.replace(
        "{{value_model_url}}",
        envConfig.baseModel.url
      );
      setLearnCodeStr(newLearnCodeStr);
    };

    doLoadInferCodeStr();
    doLoadLearnCodeStr();
  }, [envConfig]);

  const runModel = async tabId => {
    const baseUrl = "/bz-demo/run";
    const resp = await apiPostRawResponse(
      `${baseUrl}/${envConfig.envId}/${tabId}`,
      {}
    );
    const respText = await resp.text();
    setOutputStr(respText);
  };

  return (
    <>
      <Gap />
      <Title>Traffic</Title>
      <Gap />
      <GridRow>
        {stats.serve.map(stat => (
          <AreaChartParamsNew
            areParamsHidden
            title={stat.title}
            params={stat.plots}
            records={stat.records}
          />
        ))}
      </GridRow>
      <Gap />
      <Title>Try</Title>
      <Gap />
      <FlexRow>
        <SwitchLinks>
          {SERVE_API_TABS.map(tab => (
            <SwitchLink
              key={tab.id}
              isActive={selectedAPITabId === tab.id}
              onClick={() => {
                setSelectedAPITabId(tab.id);
                setOutputStr("");
              }}
            >
              {tab.name}
            </SwitchLink>
          ))}
        </SwitchLinks>
        <ButtonWord
          style={{ padding: "10px 20px" }}
          isPrimary
          onClick={() => {
            runModel(selectedAPITabId);
          }}
        >
          Run
        </ButtonWord>
      </FlexRow>
      <Gap />
      <FlexRow gap="100px">
        <Editor
          theme="vs-dark"
          height="40vh"
          value={selectedAPITabId === "infer" ? inferCodeStr : learnCodeStr}
          language="python"
          options={monacoOptions}
        />
        <Editor
          theme="vs-dark"
          height="40vh"
          value={outputStr}
          language="json"
          options={monacoOptions}
        />
      </FlexRow>
    </>
  );
};

const EnterpriseModelAPIPage = () => {
  const params = useParams();
  const [selectedTabId, setSelectedTabId] = useState("home");
  const [envConfig, setEnvConfig] = useState({});
  const [stats, setStats] = useState(STATS);
  const [dataPreview, setDataPreview] = useState([]);
  const [modelPreview, setModelPreview] = useState([]);

  useEffect(() => {
    const doResetDemo = async () => {
      // ADHOC FOR DEMO! reset demo first
      const baseUrl = "/bz-demo/reset";
      await apiGetRawResponse(`${baseUrl}/${params.envId}`);
    };
    doResetDemo();
  }, [params.envId]);

  // load data
  useEffect(() => {
    // populate a specific task
    const doPopulateEnvConfig = async () => {
      const baseUrl = "/bz-demo/environments";
      const resp = await apiGetRawResponse(`${baseUrl}/${params.envId}`);
      const respJson = await resp.json();
      setEnvConfig(respJson);
    };

    // populate stats
    const doPopulateStats = async () => {
      const baseUrl = "/bz-demo/stats";
      const resp = await apiGetRawResponse(`${baseUrl}/${params.envId}`);
      const respJson = await resp.json();
      setStats(respJson);
    };

    // populate data
    const doPopulateDataPreview = async () => {
      const baseUrl = "/bz-demo/data_preview";
      const resp = await apiGetRawResponse(`${baseUrl}/${params.envId}`);
      const respJsonlines = await resp.text();
      const respJson = respJsonlines
        .split("\n")
        .filter(x => x)
        .map(JSON.parse);
      setDataPreview(respJson);
    };

    // populate model
    const doPopulateModelPreview = async () => {
      const baseUrl = "/bz-demo/model_preview";
      const resp = await apiGetRawResponse(`${baseUrl}/${params.envId}`);
      const respJsonlines = await resp.text();
      const respJson = respJsonlines
        .split("\n")
        .filter(x => x)
        .map(JSON.parse);
      setModelPreview(respJson);
    };

    doPopulateEnvConfig();
    doPopulateStats();
    doPopulateDataPreview();
    doPopulateModelPreview();
  }, [params.envId, selectedTabId]);

  const doUpdateConfig = async config => {
    const baseUrl = "/bz-demo/environments";
    const resp = await apiPostRawResponse(
      `${baseUrl}/${params.envId}`,
      {},
      config
    );
    const respJson = await resp.json();
    setEnvConfig(respJson);
  };

  return (
    <LayoutNew>
      <Container>
        <Title>
          {envConfig?.description
            ? `${envConfig?.description}`
            : "Big Data Reasoning"}
        </Title>
        <Gap />
        <FlexRow>
          <p>Environment ID: {params.envId}</p>
          <ConfigModal
            envConfigStr={JSON.stringify(envConfig, null, 2)}
            onClick={doUpdateConfig}
          >
            <GearIcon />
          </ConfigModal>
        </FlexRow>
        <Gap />
        <TabsContainer>
          <NavWithTabsNew
            tabs={PAGE_TABS}
            onSelectTab={tabId => {
              setSelectedTabId(tabId);
            }}
            selectedId={selectedTabId}
          ></NavWithTabsNew>
        </TabsContainer>
        <Gap />
        {selectedTabId === "home" ? <HomeTab stats={stats} /> : null}
        {selectedTabId === "data" ? (
          <DataTab
            envConfig={envConfig}
            stats={stats}
            dataPreview={dataPreview}
          />
        ) : null}
        {selectedTabId === "model" ? (
          <ModelTab
            envConfig={envConfig}
            stats={stats}
            modelPreview={modelPreview}
          />
        ) : null}
        {selectedTabId === "serve" ? (
          <ServeTab envConfig={envConfig} stats={stats} />
        ) : null}
        <Gap />
      </Container>
    </LayoutNew>
  );
};

export default EnterpriseModelAPIPage;
