import { addTimeOptions } from "components/BarChartParamsNew";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getColorFromString } from "utils/common";

// import HC_exporting from "highcharts/modules/exporting";
// HC_exporting(Highcharts);

const WrapContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 4px 0;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
`;

const StyledSelect = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
  /* width: 200px; */
`;

const Name = styled.div`
  white-space: nowrap;
  font-weight: 500;
`;

const NameAndInput = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin-bottom: 8px;
`;

export const ANIMATION_MS = 1800;

export const ParamsEditor = ({
  params,
  onChangeParams,
  tableColumns,
  isEditing,
}) => {
  const { xColumnName, yColumnNames } = params;

  if (isEditing) {
    return (
      <div>
        <NameAndInput>
          <Name>X axis:</Name>
          <StyledSelect
            value={xColumnName}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                xColumnName: e.target.value,
              });
            }}
          >
            <option>-- Choose X Axis --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </StyledSelect>
        </NameAndInput>
        <WrapContainer>
          {tableColumns
            ?.filter(col => col?.name !== xColumnName)
            ?.map(column => {
              const checked = yColumnNames?.includes(column?.name) || false;

              const onChange = e => {
                const newParams = cloneDeep(params || {});
                let newYColumnNames = [];

                if (e.target.checked) {
                  newYColumnNames = [
                    ...(newParams.yColumnNames || []),
                    column?.name,
                  ];
                }
                if (!e.target.checked) {
                  newYColumnNames = newParams.yColumnNames?.filter(
                    col => col !== column?.name
                  );
                }

                // throw away column names from previous plots
                newYColumnNames = newYColumnNames?.filter(yColumnName =>
                  tableColumns?.find(col => col?.name === yColumnName)
                );

                newParams.yColumnNames = newYColumnNames;
                onChangeParams(newParams);
              };

              return (
                <Label
                  key={column?.name}
                  style={{
                    backgroundColor: `${getColorFromString(
                      `c${column?.name}`
                    )}55`,
                  }}
                >
                  <input
                    // TODO https://stackoverflow.com/questions/43476729/react-checkbox-does-not-update
                    key={Math.random()}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                  />
                  <span>{column?.name}</span>
                </Label>
              );
            })}
        </WrapContainer>
      </div>
    );
  }

  return (
    <WrapContainer>
      {yColumnNames?.map(columnName => {
        return (
          <Label
            key={columnName}
            style={{
              backgroundColor: `${getColorFromString(`c${columnName}`)}55`,
            }}
          >
            <span>{columnName}</span>
          </Label>
        );
      })}
    </WrapContainer>
  );
};

const OPTIONS = {
  chart: {
    type: "area",
    // height: 220,
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  // subtitle: {
  //   text:
  //     'Source: <a target="_blank" ' +
  //     'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
  //   align: "left",
  // },
  xAxis: {
    // categories: ["USA", "China", "Brazil", "EU", "Argentina", "India"],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    // title: {
    //   text: "1000 metric tons (MT)",
    // },
  },
  tooltip: {
    // valueSuffix: " (1000 MT)",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  // series: [
  //   {
  //     name: "Corn",
  //     data: [387749, 280000, 129000, 64300, 54000, 34300],
  //   },
  //   {
  //     name: "Wheat",
  //     data: [45321, 140000, 10000, 140500, 19500, 113500],
  //   },
  // ],
};

const Container = styled.div``;

const AreaChartParamsNew = ({
  params = {},
  isEditing = false,
  onChangeParams = newParams => {},
  tableColumns = [],
  records = [],
  height = 220,
  title = "",
  areParamsHidden = false,
}) => {
  const [options, setOptions] = useState(OPTIONS);

  useEffect(() => {
    const categories = records?.map(
      record => record?.[params?.xColumnName]?.value
    );
    const series = params?.yColumnNames?.map(yColumnName => {
      return {
        name: yColumnName,
        data: records?.map(record => record?.[yColumnName]?.value),
      };
    });

    let newOptions = cloneDeep(options);
    newOptions.title = {
      text: title,
    };
    if (params?.xColumnName === "_time") {
      newOptions = addTimeOptions(newOptions, params, records);
    } else {
      newOptions.xAxis.categories = categories;
      newOptions.series = series;
    }

    newOptions.chart.height = 220;

    setOptions(newOptions);
  }, [JSON.stringify(params), JSON.stringify(records), height]);

  // console.log({ options });

  return (
    <Container>
      {!areParamsHidden && (
        <ParamsEditor
          params={params}
          onChangeParams={onChangeParams}
          tableColumns={tableColumns}
          isEditing={isEditing}
        />
      )}
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  );
};

export default AreaChartParamsNew;
